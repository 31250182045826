/*
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-05-11 16:23:50
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-18 17:30:27
 * @FilePath: \my-project\src\api\logoin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 引用封装的 request
import request from '@/js/request'

//文章详情
export const articlesgetDetail = (data) => {
    return request({
        url: "api/cms/articles/getDetail",
        method: "POST",
        data, 
    });
};

export const articlesread = (data) => {
    return request({
        url: "api/cms/articles/read",
        method: "POST",
        data, 
    });
};

export const articlesgetRecommend = (data) => {
    return request({
        url: "api/cms/articles/getRecommend",
        method: "POST",
        data, 
    });
};