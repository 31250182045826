/*
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-05-11 15:55:21
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-22 17:01:41
 * @FilePath: \my-project\src\js\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import router from "@/router/index.js";
import { Message } from 'element-ui';

// 默认超时设置
axios.defaults.timeout = 15000;

// 相对路径设置、
//开发环境
// axios.defaults.baseURL = "http://192.168.3.3:8080/dev";
//生产环境
axios.defaults.baseURL = "https://www.fofweekly.com";
//http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // 获取token
    const token = localStorage.getItem("cc_token");
    // 设置参数格式
    if (!config.headers["Content-Type"]) {
      config.headers = {
        "Content-Type": "application/json",
        // application/x-www-form-urlencoded
      };
    }


    if (config.url === '/api/upload/file') {
      config.headers = {
        "Content-Type": 'multipart/form-data',
        // application/x-www-form-urlencoded
      };
    }
    // 添加token到headers
    if (token) {
      config.headers.Authorization = 'Bearer' + token;
    }
    // 鉴权参数设置
    if (config.method === "get") {
      //get请求下 参数在params中，其他请求在data中
      config.params = config.params || {};
      let json = JSON.parse(JSON.stringify(config.params));
      //一些参数处理
    } else {
      config.data = config.data || {};
      //一些参数处理
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  (response) => {
    //一些统一code的返回处理
    console.log(response, '返回值')
    console.log(response.config.url, '白名单')
    let wilist = ['/api/user/info', '/api/meeting/sign_up/getInfo']
    let wfurl = response.config.url
    if (wilist.indexOf(wfurl) != -1) {
      return response;
    } else {
      if (response.data.code !== 10000) {
        Message({
          message: response.data.message,
          iconClass: "true",
          center: "true",
        });
      }
      return response;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios