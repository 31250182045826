<template>
  <div>
    <el-container>
      <el-header>
        <HeadBar></HeadBar>
      </el-header>
      <el-container width="200px">
        <AppMain></AppMain>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { HeadBar, AppMain } from "./components";
export default {
  components: {
    HeadBar,
    AppMain,
  },
  created() {
    
  },
};
</script>

<style lang="scss" scoped>
.el-header {
  height: 180px !important;
  padding: 0 0px;
}
.conta {
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .el-header {
    height: 68px !important;
    padding: 0 0px;
  }
}
</style>