<template>
  <div class="summit">
    <!-- <topnar nar="峰会"></topnar> -->
    <div class="swiper">
      <swiperr></swiperr>
    </div>
    <div class="state_type">
      <div
        class="type_item"
        @click="cesta(0)"
        :class="sta_type == 0 ? 'state_active' : ''"
      >
        全部
      </div>
      <div
        class="type_item"
        @click="cesta(1)"
        :class="sta_type == 1 ? 'state_active' : ''"
      >
        进行中
      </div>
      <div
        class="type_item"
        @click="cesta(2)"
        :class="sta_type == 2 ? 'state_active' : ''"
      >
        已结束
      </div>
    </div>
    <div class="metting_pad">
      <div class="meeting_list">
        <div
          class="meeting_item"
          @click="godeilt(item)"
          v-for="(item, index) in listdata"
          :key="index"
        >
          <img class="meitem_left" :src="item.cover" alt="" />
          <div class="left_back" @click.stop="goguide(item)">
            参会指南
            <img
              class="righticon"
              src="../../assets/icon/jaint_x@2x.png"
              alt=""
            />
          </div>
          <div class="meitem_right">
            <div class="right_title">
              {{ item.name }}
            </div>
            <div class="right_bot">
              <div class="right_tips">
                <div class="right_address">
                  <img
                    class="iconimg"
                    src="../../assets/icon/weiz_hs_tt@2x.png"
                    alt=""
                  />
                  {{ item.address }}
                </div>
                <div class="right_time">
                  <img
                    class="iconimg"
                    src="../../assets/icon/shij_sa_dj@2x.png"
                    alt=""
                  />{{ item.start_time_text }}
                </div>
              </div>
              <div
                class="right_but"
                v-if="item.meeting_status_text == '进行中'"
              >
                {{ item.meeting_status_text }}
              </div>
              <div
                class="right_but gameover"
                v-if="item.meeting_status_text == '已结束'"
              >
                {{ item.meeting_status_text }}
              </div>
              <div
                class="right_but gamenum"
                v-if="
                  item.meeting_status_text != '进行中' &&
                  item.meeting_status_text != '已结束'
                "
              >
                {{ item.meeting_status_text }}
              </div>
            </div>
          </div>
        </div>
        <div class="nolsitdata" v-if="moreshow == 3">
          <img
            class="nolsitdata_img"
            src="../../assets/img/nomore.png"
            alt=""
          />
          <div class="nolsitdata_text">暂无内容</div>
        </div>
      </div>
    </div>
    <el-pagination
      class="morye"
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :total="total_articles"
      :page-size="10"
    >
    </el-pagination>
    <div class="more">
      <div class="more_cen" @click="updatalist()" v-if="moreshow == 1">
        <div class="cen_text">加载更多…</div>
        <div class="cenicon">
          <img class="img" src="../../assets/icon/xiah_djt1.png" alt="" />
        </div>
      </div>
      <div class="more_text" v-if="moreshow == 2">已经到底了</div>
    </div>
    <upward></upward>
    <buton></buton>
  </div>
</template>
<script>
import { meetingmeeting } from "../../api/meeting";
export default {
  data() {
    return {
      total_articles: 0,
      current_page: 1,
      headval: 0,
      sta_type: 0,
      listdata: [],
      page: 1,
      moreshow: 1,
    };
  },
  created() {
    window.scrollTo({
      top: 0,
      // behavior: 'smooth',
    });
    const link = location.href;
    this.$wxShare.updateWxShareConfig({
      title: "峰会",
      desc: " ",
      link: link,
    });
    this.getlist();
  },
  watch: {
    // 监听路由变化，随时获取新的列表信息
    $route: "fetchData",
  },
  methods: {
    getlist() {
      meetingmeeting({
        is_index: "0",
        meeting_status: this.sta_type,
        page: this.page,
        limit: 10,
      }).then((res) => {
        if (this.page == 1) {
          this.listdata = res.data.data.data;
        } else {
          this.listdata = this.listdata.concat(res.data.data.data);
        }
        if (res.data.data.current_page >= res.data.data.last_page) {
          this.moreshow = 2;
        }
        if (this.listdata.length == 0) {
          this.moreshow = 3;
        }
        this.total_articles = res.data.data.total;
      });
    },
    fetchData() {
      console.log(this.$route.query, "0-0-------------------------");
      this.page = +this.$route.query.page || 1;
      this.getpclist(this.page);
    },
    getpclist() {
      meetingmeeting({
        is_index: "0",
        meeting_status: this.sta_type,
        page: this.page,
        limit: 10,
      }).then((res) => {
        this.listdata = res.data.data.data;
        this.total_articles = res.data.data.total;
      });
    },
    cesta(val) {
      this.page = 1;
      this.sta_type = val;
      this.getlist();
    },
    // handleCurrentChange(e) {
    //   this.page = e;
    //   this.getpclist();
    // },
    updatalist() {
      this.page++;
      this.getlist();
    },
    goguide(item) {
      this.$router.push({ path: "guide", query: { id: item.id } });
    },
    godeilt(item) {
      let currentPath = window.location.pathname;
      if (currentPath != "/pc/summitdeilt") {
        // this.$router.push("/summitdeilt");
        this.$router.push({ path: "summitdeilt", query: { id: item.id } });
      }
    },
    gopurchase(item) {
      console.log(item);
      // this.$router.push("/purchase");
      this.$router.push({
        path: "purchase",
        query: { id: item.id, title: item.name },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.summit {
  width: 100%;
  position: relative;
  min-height: 100vh;
  .morye {
    margin-bottom: 20px;
    margin-top: 50px;
  }
  .more {
    display: none;
  }
  /deep/.el-pager {
    .active {
      background: #c32227 !important;
    }
    .number:hover {
      color: #c32227 !important;
    }
  }
  .metting_pad {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 31px;
  }
  .nolsitdata {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .nolsitdata_img {
      width: 158px;
      height: 92px;
    }
    .nolsitdata_text {
      font-size: 14px;
      margin-top: 23px;
      color: #999999;
    }
  }
  .meeting_list {
    display: flex;
    width: 1200px;
    flex-wrap: wrap;
    .meeting_item:nth-child(2n) {
      margin-right: 0px;
    }
    .meeting_item {
      display: flex;
      align-items: center;
      width: 580px;
      margin-right: 38px;
      margin-bottom: 25px;
      position: relative;
      cursor: pointer;
      .meitem_left {
        width: 240px;
        height: 116px;
      }
      .left_back {
        width: 76px;
        height: 24px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 2px 0px 2px 0px;
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .righticon {
          width: 6px;
          height: 10px;
          margin-left: 5px;
        }
      }
      .meitem_right {
        width: 326px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 15px;

        .right_title {
          font-size: 16px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          cursor: pointer;
        }
        .right_bot {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .right_tips {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            .right_address {
              // margin-right: 20px;
              margin-bottom: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              .iconimg {
                width: 12px;
                height: 14px;
                margin-right: 8px;
              }
            }
            .right_time {
              .iconimg {
                width: 14px;
                height: 14px;
                margin-right: 8px;
              }
            }
          }
          .right_but {
            width: 90px;
            height: 32px;
            border-radius: 4px;
            border: 1px solid #c32227;
            border-radius: 4px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #c4262b;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .gamenum {
            border: 1px solid #d2d2d2;
            color: #333333;
          }
          .gameover {
            background: #e1e1e1;
            border: 1px solid #e1e1e1;
            color: #333333;
          }
        }
      }
    }
  }

  .state_type {
    display: flex;
    justify-content: center;
    align-items: center;
    .state_active {
      background: #c32227 !important;
      color: #fff !important;
    }
    .type_item {
      cursor: pointer;
      width: 100px;
      height: 32px;
      background: #f5f6f9;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      margin: 0 6px;
    }
  }
  .swiper {
    height: 550px !important;
    // overflow: hidden;
    // margin: 0px 0px 43px;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
@media screen and (max-width: 1000px) {
  .morye {
    display: none;
  }
  .more {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 35px 0;
    display: flex !important;
    margin: 40px 0;
    .more_text {
      font-size: 50px !important;
    }
    .more_cen {
      .cen_text {
        font-size: 50px !important;
      }
      .cenicon {
        .img {
          width: 59px !important;
          height: 40px !important;
        }
      }
    }
  }
  // /deep/.el-carousel--horizontal {
  //   overflow-x: visible !important;
  //   height: 90% !important;
  //   .el-carousel__indicators--outside {
  //     margin-top: 28px !important;
  //   }
  // }
  // /deep/.el-carousel__container {
  //   height: 100% !important;
  //   .el-carousel__item--card {
  //     width: 100% !important;
  //     height: 100% !important;
  //     background: #fff;
  //     margin-left: 170px;

  //   }
  //   .el-carousel__item {
  //     width: 70% !important;
  //     height: 100% !important;
  //     background-color: #d3dce6;
  //   }
  //   .el-carousel__item--card.is-active {
  //     z-index: 2;
  //     position: absolute;
  //     left: -48% !important;
  //   }
  // }
  .state_type {
    display: flex;
    justify-content: center;
    align-items: center;
    .type_item {
      width: 375px !important;
      height: 120px !important;
      background: #f5f6f9;
      border-radius: 20px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 45px !important;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      margin: 40px 20px !important;
    }
  }
  .nolsitdata {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .nolsitdata_img {
      width: 458px !important;
      height: 272px !important;
    }
    .nolsitdata_text {
      font-size: 50px !important;
      margin-top: 23px;
      color: #999999;
    }
  }
  .meeting_list {
    width: 100% !important;
    padding: 0 30px;
    margin-top: 60px;
    flex-wrap: wrap;
    .meeting_item:nth-child(2n) {
      margin-right: 40px !important;
    }
    .meeting_item {
      display: flex;
      align-items: center;
      width: 100% !important;
      margin-right: 40px;
      margin-bottom: 25px;
      margin-top: 20px;
      .meitem_left {
        width: 500px !important;
        height: 250px !important;
      }
      .left_back {
        width: 200px !important;
        height: 100px !important;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 2px 0px 2px 0px;
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 36px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 30px !important;
        .righticon {
          width: 18px !important;
          height: 30px !important;
          margin-left: 15px !important;
        }
      }
      .meitem_right {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 40px !important;

        .right_title {
          font-size: 54px !important;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
        }
        .right_bot {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .right_tips {
            display: flex;
            justify-content: center;
            align-items: flex-start !important;
            font-size: 42px !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            flex-direction: column;
            .right_address {
              margin-right: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              .iconimg {
                width: 24px !important;
                height: 28px !important;
                margin-right: 8px;
              }
            }
            .right_time {
              .iconimg {
                width: 28px !important;
                height: 28px !important;
                margin-right: 8px;
              }
            }
          }
          .right_but {
            width: 300px !important;
            height: 90px !important;
            border-radius: 15px !important;
            font-size: 38px !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>