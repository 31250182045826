<template>
  <div class="guide">
    <topnar nar="7"></topnar>
    <div class="guide_wid">
      <div class="guide_title">
        <div class="guide_left">姆基金条款</div>
        <div class="guide_right" @click="goback">
          <img
            class="gobackimg"
            src="../../assets/icon/fanh_k_ba@2x.png"
            alt=""
          />
          回到峰会
        </div>
      </div>
      <div class="guide_cent">
        <!-- <div class="cent_title">参会须知</div>
          <div class="cent_text">
            参会须知内容，参会须知内容，参会须知内容，参会须知内容，参会须知内容，
            参会须知内容，参会须知内容，参会须知内容，
            参会须知内容，参会须知内容，参会须知内容，参会须知内容，参会须知内容，参会须知内容，
          </div> -->
        <div v-html="ritcent"></div>
      </div>
    </div>
    <upward></upward>
    <buton></buton>
  </div>
</template>
    <script>
import { articlesgetDetail } from "../../api/index.js";
export default {
  data() {
    return {
      id: "",
      ritcent: "",
    };
  },
  mounted() {
    //   this.id = this.$route.query.id;
    this.init();
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    init() {
      articlesgetDetail({
        category_id: "16",
      }).then((res) => {
        console.log(res);
        this.ritcent = res.data.data.content;
        this.ritcent = this.ritcent
          .replace(/(<img style="(.*?)")/gi, "<img")
          .replace(/(width="(.*?)")|(height="(.*?)")/gi, "")
          .replace(
            /\<img/gi,
            '<img style="max-width:100%!important;height:auto !important;" '
          );
      });
    },
  },
};
</script>
    
    <style scoped lang="scss">
.guide {
  width: 100%;
  position: relative;
  min-height: 100vh;
  .guide_wid {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .guide_title {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 42px;
    .guide_left {
      font-size: 20px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
    }
    .guide_right {
      font-size: 12px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
      .gobackimg {
        width: 16px;
        height: 12px;
      }
    }
  }
  .guide_cent {
    width: 1200px;
    height: auto;
    background: #f9f9f9;
    border-radius: 0px 0px 4px 4px;
    margin-top: 21px;
    padding: 22px 23px;
    box-sizing: border-box;
    text-align: left;
    font-size: 14px;
    .cent_title {
      font-size: 16px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #c32227;
      text-align: left;
    }
    .cent_text {
      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      text-align: left;
      margin-top: 7px;
    }
  }
}
@media screen and (max-width: 1000px) {
  .guide_wid {
    width: 100% !important;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .guide_title {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 42px;
    .guide_left {
      font-size: 75px !important;
    }
    .guide_right {
      font-size: 48px !important;
      cursor: pointer;
      .gobackimg {
        width: 64px !important;
        height: 48px !important;
      }
    }
  }
  .guide_cent {
    width: 100% !important;
    height: auto;
    background: #f9f9f9;
    border-radius: 0px 0px 4px 4px;
    margin-top: 21px;
    padding: 22px 23px;
    box-sizing: border-box;
    font-size: 40px !important ;
    .cent_title {
      font-size: 40px !important;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #c32227;
      text-align: left;
    }
    .cent_text {
      font-size: 30px !important;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      text-align: left;
      margin-top: 7px;
    }
  }
}
</style>