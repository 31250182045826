// 引用封装的 request
import request from '@/js/request'

//峰会列表
export const invoicesubmit = (data) => {
    return request({
        url: "/api/orders/invoice/submit",
        method: "POST",
        data, 
    });
};