<template>
  <div class="summit_Report">
    <topnar nar="7"></topnar>
    <el-dialog
      :visible.sync="dialogVisible"
      class="pop"
      width="300px"
      :before-close="handleClose"
    >
      <div id="qrcode" ref="qrCodeDiv"></div>
      <p class="scanning_text">请用微信扫码支付</p>
      <p class="scanning_re" @click="Refresh">刷新二维码</p>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <div class="Report_cent">
      <div class="Report_title" v-if="Package_type">购买峰会套票</div>
      <div class="Report_title" v-if="payment_type">支付</div>
      <div class="purchase_list" v-if="Package_type">
        <div
          class="purchase_item"
          :class="puactive == index ? 'purchase_active' : ''"
          @click="celistindex(item, index)"
          v-for="(item, index) in packagedata"
          :key="index"
        >
          <div class="item_title">
            <div class="title_left">{{ item.name }}</div>
            <div class="title_right">
              <img
                class="rimg"
                src="../../assets/img/zhifu_che_wa@2x.png"
                alt=""
                v-if="puactive == index"
              />
            </div>
          </div>
          <div class="item_money">￥{{ item.price }}</div>
          <div class="item_introduction">
            {{ item.detail }}
          </div>
        </div>
      </div>
      <div class="purchase_but" v-if="Package_type">
        <div class="purchase_cent" @click="yepurchase">立即购买</div>
      </div>
      <div class="order_lsit" v-if="payment_type">
        <div class="order_li">
          <div class="order_head">
            <div class="head_left">
              <div class="head_num">订单编号</div>
              <div class="head_package">套票</div>
            </div>
            <div class="head_right">
              <div class="head_amount">金额</div>
            </div>
          </div>
          <div class="order_item">
            <div class="item_pad">
              <div class="head_left">
                <div class="head_num">{{ oderlistdata.orders_sn }}</div>
                <div class="head_package">{{ oderlistdata.orders_name }}</div>
              </div>
              <div class="head_right">
                <div class="head_amount amount_num">
                  ￥{{ oderlistdata.orders_price }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="oder_meet">
          应付
          <p class="meet_num">￥{{ oderlistdata.orders_price }}</p>
        </div>
        <div class="oder_type">
          <div class="type_title">支付方式</div>
          <div class="type_list">
            <div class="type_item" @click="sedropindex(0)">
              <p class="drop" :class="dropindex == 0 ? 'active_drop' : ''"></p>
              <div class="type_text">
                <img
                  class="zfb_img"
                  src="../../assets/icon/zhif_B_zf@2x.png"
                  alt=""
                />
                支付宝支付
              </div>
            </div>
            <div class="type_item" @click="sedropindex(1)">
              <p class="drop" :class="dropindex == 1 ? 'active_drop' : ''"></p>
              <div class="type_text">
                <img
                  class="wx_img"
                  src="../../assets/icon/weix_zhd_f@2x.png"
                  alt=""
                />
                微信支付
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="payment_die"></div>
      <div class="payment_subimt">
        <div class="payment_num">
          实付金额：
          <p class="num">￥{{ oderlistdata.orders_price }}</p>
        </div>
        <div class="submit" @click="gosubnum()">支付</div>
      </div>
      <div class="back_img" v-if="wxtype"></div>
      <div class="wx_pop" v-if="wxtype">
        <img
          class="right_icon"
          @click="cliwxtype"
          src="../../assets/icon/guan_x.png"
          alt=""
        />
        <div class="opo_title">
          <img
            class="wx_img"
            src="../../assets/icon/weix_zhd_f@2x.png"
            alt=""
          />
          微信支付
        </div>
        <img class="qrimg" src="../../assets/img/erwm_mm.png" alt="" />
      </div>
      <!-- <div class="payment" v-if="payment_type">
        <div class="payment_head">
          <div class="payment_title">订单支付</div>
          <div class="payment_money">￥888.00</div>
          <div class="payment_num">订单编号：S3424324324</div>
          <div class="payment_ticket">购买套票：峰会套票</div>
        </div>
        <div class="payment_cent">
          <div class="payment_left">
            <div class="left_title">
              <img
                class="payment_wx"
                src="../../assets/icon/weix_zhd_f@2x.png"
                alt=""
              />
              微信支付
            </div>
            <img
              class="paymentimg"
              src="../../assets/icon/erwim_d_dax@2x.png"
              alt=""
              @click="cesuccess"
            />
          </div>
          <div class="xian"></div>
          <div class="payment_right">
            <div class="right_title">
              <img
                class="payment_zfb"
                src="../../assets/icon/zhif_B_zf@2x.png"
                alt=""
              />
              支付宝支付
            </div>
            <img
              class="paymentimg"
              src="../../assets/icon/erwim_d_dax@2x.png"
              alt=""
            />
          </div>
        </div>
      </div> -->
    </div>
    <upward v-if="!payment_type"></upward>
    <buton v-if="!payment_type"></buton>
  </div>
</template>
  
  <script>
import { meetingticket, meetingdetail } from "../../api/meeting";
import { orderssubmit, ordersdetail, paymentpay } from "../../api/orders";
import QRCode from "qrcodejs2";
import router from "@/router";
export default {
  data() {
    return {
      reason: 0,
      textarea: "",
      checkList: [],
      payment_type: false,
      success_type: false,
      Package_type: true,
      puactive: 0,
      dropindex: 0,
      wxtype: false,
      id: "",
      packagedata: {},
      orderdata: {
        meeting_name: "",
        meeting_id: "",
        sign_up_id: "",
        client: "",
        meeting_ticket_id: "",
        price: "",
      },
      detailsData: {},
      phonetype: "",
      oderlistdata: {},
      dialogVisible: false,
      qecodetext: "",
      oderid: "",
      timernum: 30,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.orderdata.meeting_id = this.$route.query.id;
    this.orderdata.meeting_name = this.$route.query.title;
    // console.log(this.$route.query.title,'099999999999')
    this.init();
    this.phonetype = this.IsPc();
    if (this.phonetype == false) {
      this.orderdata.client = "pc web";
    } else {
      this.orderdata.client = "移动web";
    }
    console.log(this.phonetype, "000000000000");
  },
  mounted() {
    this.$nextTick(function () {
      //这里写方法
      this.qrcode();
    });
  },
  watch: {
    dialogVisible: function () {
      //materielId为需要监听的data
      this.$nextTick(function () {
        if (this.dialogVisible) {
          //这里写方法
          this.qrcode();
        }
      });
    },
  },

  methods: {
    Refresh() {
      this.$refs.qrCodeDiv.innerHTML = "";
      this.qrcode();
    },
    handleClose(done) {
      done();
      this.$refs.qrCodeDiv.innerHTML = "";
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //     this.$refs.qrCodeDiv.innerHTML = "";
      //   })
      //   .catch((_) => {
      //     done();
      //     this.$refs.qrCodeDiv.innerHTML = "";
      //   });
    },
    qrcode() {
      // let long = document.documentElement.clientWidth*0.39;
      new QRCode("qrcode", {
        text: this.qecodetext, //需要转换为二维码的内容
        width: "200", //宽
        height: "200", //高
      });
    },
    init() {
      meetingticket({
        meeting_id: this.id,
      }).then((res) => {
        console.log(res, "套票");
        this.packagedata = res.data.data.list;
        console.log(this.packagedata[0]);
        if (this.packagedata[0]) {
          this.orderdata.meeting_ticket_id = this.packagedata[0].id;
          this.orderdata.price = this.packagedata[0].price;
        }
      });
      meetingdetail({
        meeting_id: this.id,
      }).then((res) => {
        // console.log(res.data.data.sign_up.id, "会议详情");
        this.detailsData = res.data.data;
        this.orderdata.sign_up_id = res.data.data.sign_up.id;
      });
    },
    IsPc() {
      let userAgent = navigator.userAgent,
        Agents = [
          "Android",
          "iPhone",
          "SymbianOS",
          "Windows Phone",
          "iPad",
          "iPod",
        ];
      console.log("userAgent:", userAgent);
      return Agents.some((i) => {
        return userAgent.includes(i);
      });
    },
    yepurchase() {
      this.Package_type = false;
      this.payment_type = true;
      orderssubmit(this.orderdata).then((res) => {
        console.log(res, "--------------");
        this.oderid = res.data.data.id;
        ordersdetail({
          orders_id: this.oderid,
        }).then((res) => {
          console.log(res.data.data, "订单详情");
          this.oderlistdata = res.data.data;
        });
      });
    },
    celistindex(item, val) {
      console.log(val, "val");
      this.puactive = val;
      this.orderdata.meeting_ticket_id = item.id;
      this.orderdata.price = item.price;
      // console.log(this.puactive, "this.puactive");
    },
    sedropindex(val) {
      this.dropindex = val;
    },
    gosubnum() {
      // this.wxtype = true;
      // this.$router.push({path:'pur_success'})
      let timer = setInterval(() => {
        // this.timernum--;
        if (this.timernum > 0) {
          ordersdetail({
            orders_id: this.oderid,
          }).then((res) => {
            console.log(res.data.data, "订单详情");
            if (res.data.data.payment != null) {
              clearInterval(timer);
              this.$router.push('pur_success')
            }
          });
        } else {
          console.log("验证码失效了");
          clearInterval(timer);
        }
      }, 10000);
      if (this.dropindex == 0) {
        paymentpay({
          pay_type: "alipay_web",
          orders_id: this.oderlistdata.id,
          extend_data: {
            openid: "",
            return_url: "http://192.168.1.134:8080/pur_success",
          },
        }).then((res) => {
          console.log(res);
          window.location.href = res.data.data;
        });
        tradePay("${tradeNO}");
      } else {
        paymentpay({
          pay_type: "wechat_native",
          orders_id: this.oderlistdata.id,
        }).then((res) => {
          console.log(res);
          if (res.data.code == 10000) {
            this.qecodetext = res.data.data;
            this.dialogVisible = true;
          }
        });
      }
    },
    cliwxtype() {
      this.wxtype = false;
    },
    cesuccess() {
      this.Package_type = false;
      this.payment_type = false;
      this.success_type = true;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.summit_Report {
  width: 100%;
  min-height: 100vh;
  position: relative;
  .scanning_text {
    /* prettier-ignore */
    font-size: 14pX;
    color: #333333;
  }
  .scanning_re {
    color: #3945e7;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
  }

  /deep/.el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .Report_cent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 110px;
    .success_form {
      width: 1200px;
      border-radius: 0px 0px 4px 4px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 98px;
      .sucicon {
        width: 51px;
        height: 51px;
      }
      .success_text {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin: 24px 0 17px;
      }
      .success_oneci {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 9px;
      }
      .success_towci {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 31px;
      }
      .success_tips {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .success_but {
        width: 200px;
        height: 32px;
        background: #c32227;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .payment_die {
    width: 100%;
    height: 120px;
  }
  .back_img {
    width: 100%;
    position: fixed;
    height: 100vh;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.3);
    z-index: 98;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .wx_pop {
    width: 400px;
    height: 400px;
    background: #ffffff;
    position: fixed;
    top: 50%;
    z-index: 99;
    margin-top: -200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: fade-in 0.2s ease-in-out;
    border-radius: 20px;
    .right_icon {
      width: 15px;
      height: 15px;
      right: 15px;
      top: 15px;
      position: absolute;
      cursor: pointer;
    }
    .opo_title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      margin-bottom: 30px;
      .wx_img {
        margin-right: 20px;
        width: 30px;
        height: 24px;
      }
    }
    .qrimg {
      width: 200px;
      height: 200px;
    }
  }
  .payment_subimt {
    width: 100%;
    height: 120px;
    background: #fbfbfb;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0px;

    .submit {
      width: 180px;
      height: 60px;
      background: #c32227;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 33px;
      margin-left: 57px;
      font-size: 20px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
    .payment_num {
      font-size: 16px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 20px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
      }
    }
  }
  .order_lsit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .oder_type {
      width: 1200px;
      height: 137px;
      background: #fbfbfb;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 23px 26px;
      box-sizing: border-box;
      .type_list {
        display: flex;
        justify-content: center;
        align-items: center;
        .type_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 235px;
          height: 58px;
          background: rgb(240, 240, 240);
          border-radius: 4px;
          font-size: 16px;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          padding: 0 16px;
          box-sizing: border-box;
          margin-right: 14px;
          cursor: pointer;
          .type_text {
            display: flex;
            justify-content: center;
            align-items: center;
            .wx_img {
              width: 30px;
              height: 24px;
              margin-right: 9px;
            }
            .zfb_img {
              width: 30px;
              height: 30px;
              margin-right: 9px;
            }
          }
          .drop {
            width: 20px;
            height: 20px;
            background: #aeaeae;
            border-radius: 50px;
            box-sizing: border-box;
          }
          .active_drop {
            background: #ffffff !important;
            border: 7px solid #c32227;
          }
        }
      }
      .type_title {
        font-size: 14px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 18px;
      }
    }
    .oder_meet {
      width: 1200px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 32px;
      font-size: 20px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      .meet_num {
        font-size: 20px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
        margin-left: 13px;
      }
    }
    .order_li {
      width: 1200px;
      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
    }
    .order_item:last-child .item_pad {
      border-bottom: none !important;
    }
    .order_item {
      width: 100%;
      background: #fbfbfb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 26px;
      box-sizing: border-box;
      .item_pad {
        height: 53px;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .head_left {
          display: flex;
          justify-content: center;
          align-items: center;
          .head_num {
            min-width: 180px;
            text-align: left !important;
          }
          .head_package {
            margin-left: 50px;
          }
        }
        .head_right {
          .amount_num {
            font-size: 14px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            color: #c32227;
          }
        }
      }
    }
    .order_head {
      width: 100%;
      background: #f0f0f0;
      height: 43px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 26px;
      box-sizing: border-box;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      .head_left {
        display: flex;
        justify-content: center;
        align-items: center;
        .head_num {
          min-width: 180px;
          text-align: left !important;
        }
        .head_package {
          margin-left: 50px;
        }
      }
    }
  }
  .payment {
    .payment_head {
      margin-top: 42px;
      .payment_title {
        font-size: 20px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #333333;
      }
      .payment_money {
        font-size: 30px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
        margin-top: 38px;
      }
      .payment_num {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-top: 41px;
      }
      .payment_ticket {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-top: 12px;
      }
    }
    .payment_cent {
      width: 708px;
      height: 292px;
      background: #f9f9f9;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 91px;
      box-sizing: border-box;
      margin-top: 37px;
      .xian {
        width: 1px;
        height: 200px;
        background: #e4e4e4;
      }
      .paymentimg {
        width: 156px;
        height: 156px;
      }
      .payment_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .left_title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          margin-bottom: 32px;
        }
        .payment_wx {
          width: 30px;
          height: 24px;
          margin-right: 13px;
        }
      }
      .payment_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .right_title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          margin-bottom: 32px;
          .payment_zfb {
            width: 30px;
            height: 30px;
            margin-right: 13px;
          }
        }
      }
    }
  }
  .purchase_but {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 46px;
    .purchase_cent {
      width: 200px;
      height: 32px;
      background: #c32227;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .purchase_list {
    .purchase_active {
      background: #c32227 !important;
      .item_title {
        .title_left {
          color: #ffffff !important;
        }
        .title_right {
          background: #ffffff !important;
        }
      }
      .item_money {
        color: #ffffff !important;
      }
      .item_introduction {
        color: #ffffff !important;
      }
    }
    .purchase_item {
      width: 1200px;
      height: 164px;
      background: rgb(251, 251, 251);
      border-radius: 4px;
      padding: 24px;
      box-sizing: border-box;
      margin-bottom: 30px;
      cursor: pointer;
      .item_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #ffffff;
        .title_left {
          font-size: 20px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
        }
        .title_right {
          width: 20px;
          height: 20px;
          background: #e4e4e4;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          .rimg {
            width: 12px;
            height: 12px;
          }
        }
      }
      .item_money {
        margin-top: 16px;
        width: 100%;
        font-size: 20px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
        text-align: left;
      }
      .item_introduction {
        margin-top: 16px;
        width: 100%;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        text-align: left;
      }
    }
  }
  .Report_title {
    width: 1200px;
    margin-top: 42px;
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 22px;
  }
}
@media screen and (max-width: 1000px) {
  .Report_title {
    width: 100% !important;
    padding: 0 30px !important;
    box-sizing: border-box;
    font-size: 60px !important;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 22px;
  }
  .payment {
    .payment_head {
      .payment_title {
        font-size: 60px !important;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #333333;
      }
      .payment_money {
        font-size: 60px !important;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
        margin-top: 38px;
      }
      .payment_num {
        font-size: 50px !important;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-top: 41px;
      }
      .payment_ticket {
        font-size: 50px !important;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-top: 12px;
      }
    }
    .payment_cent {
      width: 1200px !important;
      height: 800px !important;
      background: #f9f9f9;
      border-radius: 20px !important;
      display: flex;
      justify-content: space-between;
      padding: 0 91px;
      box-sizing: border-box;
      margin-top: 50px;
      .xian {
        width: 3px !important;
        height: 500px !important;
        background: #e4e4e4;
      }
      .paymentimg {
        width: 400px !important;
        height: 400px !important;
      }
      .payment_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .left_title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 60px !important;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          margin-bottom: 32px;
        }
        .payment_wx {
          width: 90px !important;
          height: 72px !important;
          margin-right: 13px;
        }
      }
      .payment_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .right_title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 60px !important;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          margin-bottom: 32px;
          .payment_zfb {
            width: 90px !important;
            height: 90px !important;
            margin-right: 13px;
          }
        }
      }
    }
  }
  .purchase_but {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 46px;
    .purchase_cent {
      width: 750px !important;
      height: 120px !important;
      background: #c32227;
      border-radius: 10px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 45px;
      font-size: 50px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .purchase_list {
    width: 100%;
    padding: 0 30px !important;
    box-sizing: border-box;
    .purchase_active {
      background: #c32227 !important;
      .item_title {
        .title_left {
          color: #ffffff !important;
        }
        .title_right {
          background: #ffffff !important;
        }
      }
      .item_money {
        color: #ffffff !important;
      }
      .item_introduction {
        color: #ffffff !important;
      }
    }
    .purchase_item {
      width: 100% !important;
      height: auto !important;
      background: rgb(251, 251, 251);
      border-radius: 20px !important;
      padding: 24px;
      box-sizing: border-box;
      margin-bottom: 30px;
      .item_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 40px !important;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #ffffff;
        .title_left {
          font-size: 50px !important;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
        }
        .title_right {
          width: 80px !important;
          height: 80px !important;
          background: #e4e4e4;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          .rimg {
            width: 40px !important;
            height: 40px !important;
          }
        }
      }
      .item_money {
        margin-top: 16px;
        width: 100%;
        font-size: 50px !important;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
        text-align: left;
      }
      .item_introduction {
        margin-top: 16px;
        width: 100%;
        font-size: 40px !important;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        text-align: left;
      }
    }
  }
  .payment_die {
    width: 100%;
    height: 200px !important;
  }
  .wx_pop {
    width: 800px !important;
    height: 800px !important;
    background: #ffffff;
    position: fixed;
    top: 50%;
    z-index: 99;
    margin-top: -400px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: fade-in 0.2s ease-in-out;
    border-radius: 20px;
    .right_icon {
      width: 45px !important;
      height: 45px !important;
      right: 25px !important;
      top: 25px !important;
      position: absolute;
      cursor: pointer;
    }
    .opo_title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 60px !important;
      margin-bottom: 50px !important;
      .wx_img {
        margin-right: 20px;
        width: 60px !important;
        height: 48px !important;
      }
    }
    .qrimg {
      width: 400px !important;
      height: 400px !important;
    }
  }
  .payment_subimt {
    width: 100%;
    height: 300px !important;
    background: #fbfbfb;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    position: absolute;
    padding: 0 80px;
    box-sizing: border-box;
    bottom: 0px;

    .submit {
      width: 700px !important;
      height: 180px !important;
      background: #c32227;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0 !important;
      margin-left: 57px;
      font-size: 60px !important;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
    }
    .payment_num {
      font-size: 40px !important;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 60px !important;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
      }
    }
  }
  .order_lsit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 30px;
    box-sizing: border-box;
    .oder_type {
      width: 100% !important;
      height: auto !important;
      background: #fbfbfb;
      border-radius: 4px;
      display: flex;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: column;
      padding: 140px 26px !important;
      box-sizing: border-box;
      .type_list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .type_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 1000px !important;
          height: 200px !important;
          background: #e6e6e6;
          border-radius: 20px !important;
          font-size: 50px !important;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          padding: 0 40px !important;
          box-sizing: border-box;
          margin-right: 30px !important;
          margin-bottom: 40px !important;
          .type_text {
            display: flex;
            justify-content: center;
            align-items: center;
            .wx_img {
              width: 90px !important;
              height: 72px !important;
              margin-right: 30px !important;
            }
            .zfb_img {
              width: 90px !important;
              height: 90px !important;
              margin-right: 30px !important;
            }
          }
          .drop {
            width: 50px !important;
            height: 50px !important;
            background: #aeaeae;
            border-radius: 50px;
            box-sizing: border-box;
          }
          .active_drop {
            background: #ffffff !important;
            border: 17px solid #c32227 !important;
          }
        }
      }
      .type_title {
        font-size: 60px !important;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 18px;
      }
    }
    .oder_meet {
      width: 100% !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 32px;
      font-size: 60px !important;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      .meet_num {
        font-size: 70px !important;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
        margin-left: 40px !important;
      }
    }
    .order_li {
      width: 100% !important;
      font-size: 42px !important;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
    }
    .order_item:last-child .item_pad {
      border-bottom: none !important;
    }
    .order_item {
      width: 100%;
      background: #fbfbfb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 26px;
      box-sizing: border-box;
      .item_pad {
        height: 153px !important;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .head_left {
          display: flex;
          justify-content: center;
          align-items: center;
          .head_num {
            min-width: 400px !important;
            text-align: left !important;
          }
          .head_package {
            margin-left: 50px;
          }
        }
        .head_right {
          .amount_num {
            font-size: 50px !important;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            color: #c32227;
          }
        }
      }
    }
    .order_head {
      width: 100%;
      background: #f0f0f0;
      height: 143px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 26px;
      box-sizing: border-box;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      .head_left {
        display: flex;
        justify-content: center;
        align-items: center;
        .head_num {
          min-width: 400px !important;
          text-align: left !important;
        }
        .head_package {
          margin-left: 50px;
        }
      }
    }
  }
  .Report_cent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 49px;
    width: 100% !important;
    .success_form {
      width: 100% !important;
      border-radius: 0px 0px 4px 4px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 198px !important;
      .sucicon {
        width: 180px !important;
        height: 180px !important;
      }
      .success_text {
        font-size: 80px !important;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin: 24px 0 17px;
      }
      .success_oneci {
        font-size: 60px !important;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 9px;
      }
      .success_towci {
        font-size: 60px !important;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 31px;
      }
      .success_tips {
        font-size: 50px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .success_but {
        width: 750px !important;
        height: 120px !important;
        background: #c32227;
        border-radius: 10px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 70px !important;
        font-size: 50px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>