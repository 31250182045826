// 引用封装的 request
import request from '@/js/request'

//我的订单
export const ordersmy = (data) => {
    return request({
        url: "/api/orders/my",
        method: "POST",
        data, 
    });
};

//提交订单
export const orderssubmit = (data) => {
    return request({
        url: "/api/orders/submit",
        method: "POST",
        data, 
    });
};

//订单详情
export const ordersdetail = (data) => {
    return request({
        url: "/api/orders/detail",
        method: "POST",
        data, 
    });
};

//发起支付接口
export const paymentpay = (data) => {
    return request({
        url: "/api/payment/pay",
        method: "POST",
        data, 
    });
};
