<template>
  <div class="home">
    <topnar nar="2"></topnar>
    <div class="seacrinp">
      <div class="rightinput">FOF观察</div>
      <div class="leftinpt">
        <input
          class="inp"
          v-model="inptext"
          @keydown="seceinpt"
          placeholder="请输入您想搜索的内容…"
          type="text"
        />
        <div class="secaerimg" @click="clikseceinpt">
          <img class="img"  src="../../assets/icon/sous_ssss.png" alt="" />
        </div>
      </div>
    </div>
    <div class="swiper">
      <el-carousel arrow="never" trigger="click" class="swiperlist">
        <el-carousel-item
          v-for="item in imgdata"
          :key="item.url"
          class="swiperitem"
        >
          <img class="img" :src="item.url" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="article_list">
      <div
        class="list_item"
        v-for="(item, index) in datalist"
        :key="index"
        @click="goinfo(item)"
      >
        <div class="item_left">
          <img class="img" :src="item.cover" alt="" />
        </div>
        <div class="item_right">
          <div class="item_title">{{ item.title }}</div>
          <div class="item_tearr">
            {{ item.description }}
          </div>
          <div class="item_time">
            <div class="time">{{ item.created_at }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="more pcbig">
      <el-pagination
        class="morye"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page.sync="current_page"
        :total="total_articles"
        :page-size="8"
      >
      </el-pagination>
    </div>
    <div class="morer pcmin">
      <div class="more_cen" @click="page_turning">
        <div class="cen_text px19">加载更多文章…</div>
        <div class="cenicon">
          <img class="img" src="../../assets/icon/xiah_djt1.png" alt="" />
        </div>
      </div>
    </div>
    <upward></upward>
    <buton></buton>
  </div>
</template>
    
    <script>
import { imgcarousel, articlesgetList } from "@/api/index";
export default {
  data() {
    return {
      inptext: "",
      datalist: "",
      page: 1,
      total_articles: 0,
      current_page: 1,
      imgdata: [],
    };
  },
  watch: {
    // 监听路由变化，随时获取新的列表信息
    $route: "fetchData",
  },
  methods: {
    seceinpt(e) {
      if (e.keyCode == 13) {
        this.$router.push({
          path: "search",
          query: { userId: "1", sease: this.inptext },
        });
      }
    },
    clikseceinpt(){
      this.$router.push({
          path: "search",
          query: { userId: "1", sease: this.inptext },
        });
    },
    goinfo(item) {
      this.$router.push({ path: "info", query: { id: item.id } });
    },
    page_turning() {
      this.page++;
      this.init();
    },
    getimgdata() {
      imgcarousel({
        id: "2",
      })
        .then((res) => {
          console.log(res);
          this.imgdata = res.data.data.images;
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
    },
    fetchData() {
      this.page = this.$route.query.page||1;
      // console.log(this.page)
      this.pcpage();
    },
    pcpage() {
      articlesgetList({
        category_id: "1",
        limit: "8",
        page: this.page,
      })
        .then((res) => {
          console.log(res);
          this.total_articles = res.data.data.total;
          this.datalist = res.data.data.data;
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
    },
    init() {
      articlesgetList({
        category_id: "1",
        limit: "8",
        page: this.page,
      })
        .then((res) => {
          console.log(res);
          this.total_articles = res.data.data.total;
          if (this.page == 1) {
            this.datalist = res.data.data.data;
          } else {
            this.datalist = this.datalist.concat(res.data.data.data);
          }
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
    },
  },
  mounted() {
    this.init();
    this.getimgdata();
  },
};
</script>
    <style lang="scss" scoped>
.home {
  width: 100%;
  position: relative;
}
/deep/.el-pager {
  .active {
    background: #c32227 !important;
  }
  .number:hover {
    color: #c32227 !important;
  }
}
.pcbig {
  display: block;
}
.pcmin {
  display: none;
}
/deep/.el-carousel__container {
  height: auto !important;
}

.more {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px 0;
}
.article_list {
  padding: 0 120px;
  box-sizing: border-box;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .list_item {
    width: 550px;
    cursor: pointer;
    display: flex;
    justify-content: start;
    padding-bottom: 20px;
    border-bottom: 1px solid #f6f6f6;
    .item_left {
      margin-right: 20px;
      .img {
        width: 200px;
        height: 113px;
      }
    }
    .item_right {
      .item_title {
        text-align: left;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 1;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 1; // 超出多少行
        -webkit-box-orient: vertical;
        /* prettier-ignore */
        margin-top: 10pX;
        /* prettier-ignore */
        font-size: 16pX;
      }
      .item_tearr {
        text-align: left;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        margin-top: 6px;
        margin-bottom: 10px;
        /* prettier-ignore */
        height: 45pX;
        /* prettier-ignore */
        line-height: 20pX;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 2; // 超出多少行
        -webkit-box-orient: vertical;
        /* prettier-ignore */
        font-size: 12pX;
      }
      .item_time {
        display: flex;
        justify-content: start;
        align-items: center;
        .time {
          /* prettier-ignore */
          font-size: 14pX;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
.seacrinp {
  padding: 0 120px;
  box-sizing: border-box;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  .leftinpt {
    width: 590px;
    height: 50px;
    background: #f6f6f6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    box-sizing: border-box;
    .inp {
      flex: 1;
      height: 50px;
      border: none;
      /* prettier-ignore */
      font-size: 16pX;
      background: #f6f6f6;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      outline: none;
    }
    .secaerimg {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        /* prettier-ignore */
        width: 21pX;
        /* prettier-ignore */
        height: 20pX;
        cursor: pointer;
      }
    }
  }
  .rightinput {
    /* prettier-ignore */
    font-size: 20pX;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #c32227;
  }
}
.swiper {
  width: 100%;
  height: 336px;
  padding: 0 120px;
  box-sizing: border-box;
  .swiperlist {
    width: 100%;
    height: 336px;
    .swiperitem {
      width: 100%;
      height: 336px;
    }
  }
  .img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 1280px) {
  .article_list {
    .list_item {
      padding: 40px 0 !important;
    }
  }
  .pcbig {
    display: none !important;
  }
  .pcmin {
    display: block !important;
  }
}
@media screen and (max-width: 1000px) {
  .morer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px 0;
    .more_cen {
      cursor: pointer;
      .cen_text {
        font-size: 50px !important;
      }
      .cenicon {
        .img {
          width: 59px;
          height: 50px;
        }
      }
    }
  }
  .article_list {
    flex-direction: column;
    align-items: start !important;
    padding: 0 50px !important;
    .list_item {
      width: 100% !important;
      margin-bottom: 30px;
      .item_left {
        margin-right: 20px;
        .img {
          width: 530px !important;
          height: 380px !important;
        }
      }
      .item_right {
        .item_title {
          font-size: 60px !important;
          height: 60px;
          margin-top: 20px !important;
        }
        .item_tearr {
          font-size: 48px !important;
          height: 150px !important;
          margin-top: 20px !important;
          margin-bottom: 0px !important;
        }
        .item_time {
          display: flex;
          justify-content: start;
          align-items: center;
          .time {
            font-size: 38px !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
  .pcbig {
    display: none !important;
  }
  .pcmin {
    display: block !important;
  }
  .seacrinp {
    padding: 0 50px !important;
    flex-direction: column;
    align-items: start !important;
    margin-top: 60px !important;
    .leftinpt {
      width: 100%;
      height: 200px;
      border-radius: 4px;
      padding-left: 20px;
      margin-top: 40px;
      .inp {
        flex: 1;
        height: 200px;
        font-size: 60px;
      }
      .secaerimg {
        width: 200px !important;
        height: 200px !important;
        .img {
          width: 60px;
          height: 60px;
          cursor: pointer;
        }
      }
    }
    .rightinput {
      font-size: 80px;
    }
  }
  .swiper {
    padding: 0 50px !important;
    margin-top: 60px !important;
    width: 100% !important;
    height: 600px !important;

    .swiperlist {
      width: 100% !important;
      height: 600px !important;

      .swiperitem {
        width: 100% !important;
        height: 600px !important;
        border-radius: 15px !important;
      }
    }

    .img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
    