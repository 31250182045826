<template>
  <div class="summitdeilt">
    <topnar nar="7"></topnar>
    <div class="head_img">
      <!-- <img  :src="detltdata.cover" alt="" /> -->
      <el-carousel height="150px">
        <el-carousel-item
          v-for="(item, index) in detltdata.carousel.pc_pics"
          :key="index"
        >
          <img
            class="image"
            @click="itemurl(item.url)"
            :src="item.pic"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="summitdeilt_tips">
      <div class="tips_left">
        <div class="tips_addres">
          <img
            class="iconimg"
            src="../../assets/icon/diang_ba_ds@2x.png"
            alt=""
          />
          {{ detltdata.address }}
        </div>
        <div class="tips_time">
          <img
            class="iconimg"
            src="../../assets/icon/riq_yes_base@2x.png"
            alt=""
          />
          {{ detltdata.start_time_text }}
        </div>
        <!-- <div class="tips_vip" v-if="setdata.guest_type == 3">
          <img
            class="iconimg"
            src="../../assets/icon/duole_y_jues@2x.png"
            alt=""
          />
          <div class="vips_text" v-if="setdata.guest_type == 3">SVIP</div>
        </div> -->
        <div class="left_back" @click="goguide">
          参会指南
          <img
            class="righticon"
            src="../../assets/icon/jaint_xred@2x.png"
            alt=""
          />
        </div>
        <div class="session" v-if="setdata.meeting_step">
          <div class="session_thr"></div>
          <div class="session_cent">
            <div class="session_title">参会环节</div>
            <div class="se_name">姓名:{{ setdata.name }}</div>
            <div
              class="session_li"
              v-for="(item, index) in setdata.meeting_step"
              :key="index"
            >
              <div class="li_left"></div>
              <div class="li_right">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right_box">
        <div class="back" @click="goback">
          <img
            class="iconimg"
            src="../../assets/icon/fanh_k_ba@2x.png"
            alt=""
          />
          返回
        </div>
        <div
          class="tips_right"
          v-if="detltdata.meeting_status_text == '进行中'"
        >
          {{ detltdata.meeting_status_text }}
        </div>
        <div
          class="tips_right gameover"
          v-if="detltdata.meeting_status_text == '已结束'"
        >
          {{ detltdata.meeting_status_text }}
        </div>
        <div
          class="tips_right gamenum"
          v-if="
            detltdata.meeting_status_text != '进行中' &&
            detltdata.meeting_status_text != '已结束'
          "
        >
          {{ detltdata.meeting_status_text }}
        </div>
      </div>
    </div>
    <block v-if="detltdata.meeting_status_text != '已结束'">
      <div class="examine through" v-if="setdata.sign_status == 2">
        <div class="examine_title">审核通过</div>
        <div class="examine_tips">审核通过！欢迎参加会议</div>
      </div>
      <div class="examine review" v-if="setdata.sign_status == 0 && name">
        <div class="examine_title gosubmit" @click="goreport(1)">立即报名</div>
        <div class="examine_title goinfo" @click="goreport(2)">
          查看报名信息
        </div>
      </div>
      <div
        class="examine review nomor"
        v-if="setdata.sign_status == 0 && !name"
      >
        <div class="backlist" @click="goback()">往期回顾</div>
        <div class="listtitle" @click="goreport(1)">立即报名</div>
      </div>
      <div class="examine Fail" v-if="setdata.sign_status == -1">
        <div class="examine_title">审核未通过</div>
        <div class="examine_tips">抱歉，您的审核未通过,无法参加会议</div>
      </div>
      <div class="tick" v-if="setdata.sign_status == 1">
        <div class="examine_title" @click="gopurchase(item)">
          去购票
          <img class="icon_img" src="../../assets/icon/jaint_x@2x.png" alt="" />
        </div>
        <div class="examine_tips">需购买门票后可参加该会议</div>
      </div>
    </block>
    <!-- <div class="rtxext"></div> -->
    <div class="module_title">
      <div class="bule"></div>
      大会介绍
    </div>
    <div class="witimg">
      <div v-html="detltdata.pc_detail"></div>
    </div>

    <div class="distinguished" :style="'background:#FFF;'">
      <div class="module_title" v-if="detltdata.guest.length != 0">
        <div class="bule"></div>
        嘉宾介绍
      </div>
      <!-- <div class="distinguished_title">重磅嘉宾</div> -->
      <div class="distinguished_listpad">
        <div class="distinguished_list">
          <div
            class="distinguished_item"
            v-for="(item, index) in detltdata.guest"
            :key="index"
          >
            <img class="itemimg" :src="item.pic" alt="" />
            <div class="distinguished_name">{{ item.name }}</div>
            <div class="distinguished_identity">{{ item.detail }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="advertisement">
      <el-carousel
        :interval="5000"
        :indicator-position="imgdata > 1 ? '' : 'none'"
        arrow="always"
      >
        <el-carousel-item
          v-for="(item, index) in detltdata.ad_pics"
          :key="index"
        >
          <img class="img" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <upward></upward>
    <buton></buton>
  </div>
</template>

<script>
import { meetingdetail, sign_upgetdetail } from "../../api/meeting.js";
export default {
  data() {
    return {
      detltdata: {},
      id: "",
      name: "",
      let: "",
      institution: "",
      setdata: {
        sign_status: 0,
      },
      oderid: "",
    };
  },
  watch: {
    // 监听路由变化，随时获取新的列表信息
    $route: "fetchData",
  },
  mounted() {
    window.scrollTo({
      top: 0,
      // behavior: 'smooth',
    });
  },
  created(val) {
    console.log(this.$route.query, "-----------");
    this.id = this.$route.query.id;
    if (this.$route.query.name) {
      this.name = this.$route.query.name;
      this.oderid = this.$route.query.oderid;
    }
    this.init();
    // console.log(val)
  },
  methods: {
    gopurchase(item) {
      this.$router.push({
        path: "purchase",
        query: { id: this.detltdata.id, title: this.detltdata.name },
      });
    },
    goback() {
      // window.location.href = this.detltdata.mp_url;
      window.open(this.detltdata.mp_url, "_blank");
    },
    init() {
      meetingdetail({
        meeting_id: this.id,
      }).then((res) => {
        this.detltdata = res.data.data;
        const link = location.href;
        this.$wxShare.updateWxShareConfig({
          title: this.detltdata.name,
          desc: " ",
          link: link,
        });
        this.detltdata.pc_detail = this.detltdata.pc_detail.replace(
          /\&nbsp;/gi,
          "<br/>"
        );
        this.detltdata.pc_detail = this.detltdata.pc_detail.replace(
          /(<section style="(.*?)")/gi,
          "<section"
        );
        this.detltdata.pc_detail = this.detltdata.pc_detail.replace(
          /(tabindex="(.*?)")/gi,
          "tabindex"
        );
        this.detltdata.pc_detail = this.detltdata.pc_detail
          .replace(/(<img style="(.*?)")/gi, "<img")
          .replace(/(width="(.*?)")|(height="(.*?)")/gi, "")
          .replace(
            /\<img/gi,
            '<img style="max-width:100%!important;height:auto !important;" '
          );
        if (!this.name) {
          if (
            res.data.data.sign_up != null &&
            res.data.data.sign_up.meeting_step.length != 0
          ) {
            if (res.data.data.sign_up.meeting_step_status == 1) {
              this.setdata.meeting_step = res.data.data.sign_up.meeting_step;
            }
          }
        }
        if (this.detltdata.carousel == null) {
          this.detltdata.carousel = "";
        }
        console.log(this.detltdata, "会议详情");
      });
      if (this.name) {
        sign_upgetdetail({
          sign_up_id: this.oderid,
        }).then((res) => {
          // if (res.data.data.meeting_step_status == 1) {
          this.setdata = res.data.data;
          // }
          console.log(
            this.setdata,
            "000000000000000000",
            res.data.data.meeting_step.length != 0
          );
          if (res.data.data.meeting_step.length == 0) {
            this.setdata.meeting_step = "";
          }
        });
      }
    },
    goguide(item) {
      this.$router.push({ path: "guide", query: { id: this.detltdata.id } });
    },
    itemurl(val) {
      window.location.href = val;
    },
    goreport(val) {
      // this.$router.push("/summit_Report");
      if (val == 1) {
        this.$router.push({
          path: "summit_Report",
          query: {
            id: this.detltdata.id,
            type: val,
          },
        });
      } else {
        this.$router.push({
          path: "summit_Report",
          query: {
            id: this.detltdata.id,
            name: this.name,
            oderid: this.oderid,
            type: val,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-carousel__container {
  height: 100% !important;
}
/deep/.el-carousel {
  height: 100% !important;
}
.summitdeilt {
  width: 100%;
  position: relative;
  min-height: 100vh;
  padding-bottom: 50px;
  .head_img {
    width: 100%;
    height: 430px;
    .image {
      width: 100%;
      height: 100%;
    }
  }
  .advertisement {
    width: 100%;
    height: 300px;
    padding: 0 120px;
    box-sizing: border-box;
    .img {
      width: 100%;
      height: 300px;
    }
  }
  .distinguished {
    background: rgb(91, 6, 3);
    padding-top: 40px;
    .distinguished_title {
      font-size: 40px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
    }
    .distinguished_listpad {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .distinguished_list {
      width: 1200px;
      margin-top: 30px;
      padding-bottom: 30px;
      display: flex;
      flex-wrap: wrap;
      .distinguished_item:nth-child(5n) {
        margin-right: 0px;
      }
      .distinguished_item {
        width: 160px;
        margin-right: 100px;
        margin-bottom: 50px;
        .distinguished_name {
          font-size: 20px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333;
        }
        .distinguished_identity {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333;
          margin-top: 5px;
        }
        .itemimg {
          width: 160px;
          height: 200px;
          border-radius: 10px;
        }
      }
    }
  }
  .module_title {
    text-align: left;
    padding: 0 120px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-weight: bolder;
    font-size: 18px;
    .bule {
      width: 4px;
      height: 16px;
      background: #c32227;
      border-radius: 3px;
      margin-right: 16px;
    }
  }
  .witimg {
    width: 100%;
    font-size: 14px;
    padding: 0 120px;
    box-sizing: border-box;
    .img {
      width: 100%;
      height: 100%;
    }
  }
  .nomor {
    justify-content: space-between !important;
  }
  .review {
    height: 50px;
    cursor: pointer;
    flex-direction: row !important;
    .gosubmit {
      background: #9d1a1f;
    }
    .goinfo {
      background: #c32227;
    }
    .listtitle {
      width: 65%;
      height: 100%;
      background: #9d1a1f;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 14px;
      border-radius: 5px;
    }
    .backlist {
      width: 30%;
      height: 100%;
      background: rgb(102, 102, 102);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 14px;
      border-radius: 5px;
    }
    .examine_title {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      width: 200px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 6px;
    }
    .examine_tips {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      margin-top: 10px;
      color: #ffffff;
      width: 200px;
      height: 50px;
      background: #9d1a1f;
    }
  }
  .tick {
    width: 500px;
    height: 70px;
    background: #c32227;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    border-radius: 4px;
    position: fixed;
    left: 50%;
    margin-left: -250px;
    bottom: 50px;
    z-index: 99;
    padding: 0 26px;
    box-sizing: border-box;
    cursor: pointer;
    .examine_title {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon_img {
        width: 9px;
        height: 16px;
        margin-left: 13px;
      }
    }
    .examine_tips {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .Fail {
    height: 70px;
    background: #ffffff;
    .examine_title {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
    }
    .examine_tips {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      margin-top: 10px;
      color: #999999;
    }
  }
  .through {
    height: 70px;
    background: #c32227;
    .examine_title {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
    }
    .examine_tips {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      margin-top: 10px;
      color: #ffffff;
    }
  }
  .examine {
    width: 370px;
    border-radius: 4px;
    position: fixed;
    left: 50%;
    margin-left: -170px;
    bottom: 50px;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .examine_title {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
    }
    .examine_tips {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      margin-top: 10px;
    }
  }
  .summitdeilt_tips {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 120px;
    box-sizing: border-box;
    color: #333333;
    border-bottom: 1px solid #f6f6f6;
    .gamenum {
      border: 1px solid #d2d2d2;
      color: #333333;
    }
    .gameover {
      background: #e1e1e1;
      border: 1px solid #e1e1e1;
      color: #333333;
    }
    .right_box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      white-space: nowrap;
    }
    .back {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
      margin-right: 20px;
      display: flex;
      align-items: center;

      .iconimg {
        width: 16px;
        height: 12px;
        margin-right: 5px;
      }
    }
    .tips_right {
      padding: 0 20px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      font-size: 20px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #c4262b;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .tips_left:hover .session {
      display: block !important;
    }
    .tips_left {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      position: relative;
      white-space: nowrap;
      flex-wrap: wrap;
      .session {
        width: 358px;
        height: 151px;
        position: absolute;
        bottom: -170px;
        left: 0px;
        display: none;
        z-index: 99;

        .session_thr {
          width: 0;
          height: 0;
          border-top: 15px solid transparent;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 12px solid #9d1a1f;
          margin-left: 20px;
        }

        .session_cent {
          width: 358px;
          height: 131px;
          background: #9d1a1f;
          padding: 10px 20px;
          box-sizing: border-box;
          border-radius: 10px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          .se_name {
            color: #fff;
            margin-top: 16px;
          }
          .session_li {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 16px;
            .li_left {
              width: 8px;
              height: 8px;
              background: #fff;
              border-radius: 50px;
              margin-right: 8px;
            }
            .li_right {
              font-size: 14px;
              font-family: SourceHanSansCN-Bold, SourceHanSansCN;
              font-weight: bold;
              color: #fff;
            }
          }
        }
        .session_title {
          font-size: 16px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #fff;
        }
      }
      .tips_addres {
        margin-right: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;
        .iconimg {
          width: 12px;
          height: 14px;
          margin-right: 8px;
        }
      }
      .tips_vip {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;

        .iconimg {
          width: 13px;
          height: 16px;
          margin-right: 8px;
        }
      }
      .tips_time {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 13px;
        color: #333333;
        .iconimg {
          width: 14px;
          height: 14px;
          margin-right: 8px;
        }
      }
      .left_back {
        width: 76px;
        height: 24px;
        background: #ffffff;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 14px;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #c32227;
        cursor: pointer;
        .righticon {
          width: 6px;
          height: 10px;
          margin-left: 5px;
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .summitdeilt {
    padding-bottom: 20px !important;
  }
  .examine {
    width: 1200px !important;
    height: 200px !important;
    border-radius: 4px;
    position: fixed;
    left: 50%;
    margin-left: -600px !important;
    bottom: 120px !important;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .examine_title {
      font-size: 60px !important;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
    }
    .examine_tips {
      font-size: 40px !important;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      margin-top: 10px;
    }
  }
  .module_title {
    text-align: left;
    padding: 0 120px;
    display: flex;
    align-content: center;
    margin-top: 60px !important;
    font-size: 50px !important;
    font-weight: bolder;
    .bule {
      width: 16px !important;
      height: 64px !important;
      background: #c32227;
      border-radius: 6px !important;
      margin-right: 16px !important;
    }
  }
  .review {
    height: 50px;
    cursor: pointer;
    flex-direction: row !important;
    .gosubmit {
      width: 50% !important;
      height: 200px !important;
      background: #9d1a1f;
    }
    .goinfo {
      width: 50% !important;
      height: 200px !important;
      background: #c32227;
    }
    .listtitle {
      width: 100%;
      height: 100%;
      background: #9d1a1f;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 60px !important;
      border-radius: 20px !important;
    }
    .backlist {
      width: 30%;
      height: 100%;
      background: rgb(102, 102, 102);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 60px !important;
      border-radius: 20px !important;
    }
    .examine_title {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      width: 200px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 6px;
    }
    .examine_tips {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      margin-top: 10px;
      color: #ffffff;
      width: 200px;
      height: 50px;
      background: #9d1a1f;
    }
  }
  .tick {
    width: 1200px !important;
    height: 200px !important;
    background: #c32227;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    border-radius: 4px;
    position: fixed;
    left: 50%;
    margin-left: -600px !important;
    bottom: 50px;
    z-index: 99;
    padding: 0 26px;
    box-sizing: border-box;
    cursor: pointer;
    .examine_title {
      font-size: 60px !important;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon_img {
        width: 27px !important;
        height: 48px !important;
        margin-left: 13px;
      }
    }
    .examine_tips {
      font-size: 50px !important;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .summitdeilt_tips {
    width: 100%;
    height: 200px !important;
    .tips_right {
      width: 200px !important;
      height: 90px !important;
      border-radius: 20px !important;
      font-size: 40px !important;
    }
    .back {
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #fff;
      cursor: pointer;
      font-size: 40px !important;
      .iconimg {
        width: 64px !important;
        height: 48px !important;
      }
    }
    .tips_left {
      font-size: 50px !important;
      .session {
        // width: 658px !important;
        // height: 1px;
        position: absolute;
        bottom: -210px !important;
        left: 0px;
        display: none;

        .session_thr {
          width: 0;
          height: 0;
          border-top: 30px solid transparent !important;
          border-right: 20px solid transparent !important;
          border-left: 20px solid transparent !important;
          border-bottom: 24px solid #9d1a1f !important;
          margin-left: 40px !important;
        }
        .se_name {
          color: #333333;
          margin-top: 16px;
        }
        .session_cent {
          width: 700px !important;
          height: 400px !important;
          background: #9d1a1f;
          padding: 10px 20px;
          border-radius: 15px !important;
          .session_li {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 16px;
            .li_left {
              width: 18px !important;
              height: 18px !important;
              margin-right: 18px !important;
            }
            .li_right {
              font-size: 40px !important;
              font-family: SourceHanSansCN-Bold, SourceHanSansCN;
              font-weight: bold;
            }
          }
        }
        .session_title {
          font-size: 50px !important;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #c32227;
        }
      }
      .tips_vip {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconimg {
          width: 36px !important;
          height: 42px !important;
          margin-right: 8px;
        }
      }
      .tips_addres {
        margin-right: 30px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        .iconimg {
          width: 36px !important;
          height: 42px !important;
          margin-right: 20px !important;
        }
      }
      .tips_time {
        display: flex;
        justify-content: center;
        align-items: center;
        .iconimg {
          width: 40px !important;
          height: 40px !important;
          margin-right: 20px !important;
        }
      }
      .left_back {
        width: 170px !important;
        height: 60px !important;
        background: #ffffff;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 14px;
        font-size: 40px !important;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #c32227;
        cursor: pointer;
        .righticon {
          width: 18px !important;
          height: 30px !important;
          margin-left: 15px !important;
        }
      }
    }
  }
}
</style>