/*
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-07-19 18:11:49
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-07-24 16:32:41
 * @FilePath: \my-project\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index/index.vue'
import info from "../views/info/info.vue";
import search from "../views/search/search.vue";
import fofobserve from "../views/fofobserve/fofobserve.vue";
import findLp from "../views/findLp/index.vue";
import observation from "../views/observation/index.vue";
import firstLine from "../views/firstLine/index.vue";
import report from "../views/report/index.vue";
import LIST from "../views/LIST/index.vue";
import generaLarticles from "../views/generaLarticles/index.vue";
import admin from "../views/admin/admin.vue";
import summit from '../views/summit/summit.vue'
import summitdeilt from '../views/summitdeilt/summitdeilt.vue'
import summit_Report from '../views/summit_Report/summit_Report.vue'
import purchase from '../views/purchase/purchase.vue'
import guide from '../views/guide/guide.vue'
import pur_success from '../views/pur_success/pur_success.vue'
import fundTerms from '../views/fundTerms/fundTerms.vue'
import layout from '../views/layout/layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: layout,
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: HomeView
      },
      {
        path: '/generaLarticles',
        name: 'generaLarticles',
        component: generaLarticles
      },
      {
        path: '/summit',
        name: 'summit',
        component: summit
      },
    ],
    // beforeEnter: (to, from, next) => {
    //   next({
    //     path: '/home'
    //   })
    // },
  },
  {
    path: '/info',
    name: 'info',
    component: info
  },
  {
    path: '/search',
    name: 'search',
    component: search
  },
  {
    path: '/fofobserve',
    name: 'fofobserve',
    component: fofobserve
  },
  {
    path: '/findLp',
    name: 'findLp',
    component: findLp
  },
  {
    path: '/observation',
    name: 'observation',
    component: observation
  },
  {
    path: '/firstLine',
    name: 'firstLine',
    component: firstLine
  },
  {
    path: '/report',
    name: 'report',
    component: report
  },
  {
    path: '/LIST',
    name: 'LIST',
    component: LIST
  },
  {
    path: '/admin',
    name: 'admin',
    component: admin,
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("cc_token"))
      // ...
      if (!localStorage.getItem("cc_token")) {
        next({
          path: '/'
        })
      } else {
        next()
      }
    }
  },
  {
    path: '/summitdeilt',
    name: 'summitdeilt',
    component: summitdeilt
  },
  {
    path: '/summit_Report',
    name: 'summit_Report',
    component: summit_Report
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: purchase
  },
  {
    path: '/guide',
    name: 'guide',
    component: guide
  },
  {
    path: '/pur_success',
    name: 'pur_success',
    component: pur_success
  },
  {
    path: '/fundTerms',
    name: 'fundTerms',
    component: fundTerms
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/pc',
  routes
})

export default router
