<template>
  <div class="summit_Report">
    <topnar :logshow="loginshow" nar="7"></topnar>
    <div class="Report_cent">
      <div class="Report_title">
        <div class="title">会议报名</div>
        <div class="back" @click="goback">
          <img
            class="iconimg"
            src="../../assets/icon/fanh_k_ba@2x.png"
            alt=""
          />
          返回
        </div>
      </div>
      <div class="Report_head">{{ dedatabo.name }}</div>
      <div class="Report_form" v-if="!success_type">
        <div class="form_name">
          <div class="form_title">姓名</div>
          <input
            class="inp"
            v-model="subminData.name"
            @blur="goinfo"
            placeholder="请填写"
            type="text"
          />
        </div>
        <div class="form_phone">
          <div class="form_left">
            <div class="form_title">手机号</div>
            <input
              class="inp"
              @blur="goinfo"
              v-model="subminData.tel"
              placeholder="请填写"
              type="text"
            />
          </div>
          <div class="form_right">
            <div class="form_title">验证码</div>
            <input
              class="inp"
              v-model="subminData.code"
              placeholder="请填写"
              type="text"
            />
            <div class="code" @click="getcode">
              {{ secondre != 0 ? secondre : "获取验证码" }}
            </div>
          </div>
        </div>
        <div class="form_phone">
          <div class="form_left">
            <div class="form_institution">
              <div class="form_title">机构简称</div>
              <input
                class="inp"
                @blur="goinfo"
                v-model="subminData.institution"
                placeholder="请填写"
                type="text"
              />
            </div>
          </div>
          <div class="form_right">
            <div class="form_position">
              <div class="form_title">职位</div>
              <input
                class="inp"
                v-model="subminData.position"
                placeholder="请填写"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="form_brief">
          <div class="form_title">机构简介</div>
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="subminData.institution_info"
            maxlength="100"
            show-word-limit
            resize="none"
            class="area"
          >
          </el-input>
        </div>
        <div class="form_types">
          <div class="form_title">
            机构类型
            <p class="type_tips">可多选</p>
          </div>
          <el-checkbox-group class="types_list" v-model="checkList">
            <el-checkbox
              v-for="(item, index) in dedata"
              :key="index"
              :label="item"
            ></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="form_cards">
          <div class="form_title">名片</div>
          <div class="upimg">
            <img
              class="img"
              v-if="!subminData.name_card"
              src="../../assets/icon/mip_d_ys@2x.png"
              alt=""
            />
            <div class="up_text" v-if="!subminData.name_card">点击上传名片</div>
            <img
              class="card_img"
              v-if="subminData.name_card"
              :src="subminData.name_card"
              alt=""
            />
            <form action="" enctype="multipart/form-data">
              <input
                type="file"
                multiple="multiple"
                accept="image/*"
                capture="camera"
                class="i_none"
                id="i0"
                ref="upimg"
                @change="getImg"
              />
            </form>
          </div>
        </div>
        <div class="form_institution">
          <div class="form_title">推荐人</div>
          <input
            class="inp"
            v-model="subminData.recommender"
            placeholder="请填写"
            type="text"
          />
        </div>
        <div class="subutton" @click="gopurchase">
          <div class="but">提交报名</div>
        </div>
      </div>
      <div class="success_form" v-if="success_type">
        <img
          class="sucicon"
          src="../../assets/img/zhifu_che_wa@2x.png"
          alt=""
        />
        <div class="success_text">报名成功</div>
        <div class="success_tips">等待工作人员审核报名信息后，会以短信方式</div>
        <div class="success_but" @click="goback">回到峰会</div>
      </div>
    </div>
    <upward></upward>
    <buton></buton>
  </div>
</template>

<script>
import { gotcode } from "@/api/logoin";
import {
  meetingsubmit,
  sign_upgetInfo,
  sign_upgetdetail,
} from "../../api/meeting.js";
import { uploadfile } from "../../api/index";
import { meetingdetail } from "../../api/meeting.js";
export default {
  data() {
    return {
      reason: 0,
      checkList: [],
      success_type: false,
      secondre: 0,
      id: "",
      name: "",
      oderid: "",
      subminData: {
        meeting_id: "",
        name: "",
        tel: "",
        code: "",
        sms_type: 2,
        institution: "",
        position: "",
        institution_types: "",
        institution_info: "",
        name_card: "",
        submit_type: "1",
        recommender: "",
        pr_pic: "",
      },
      dedata: [],
      getnamelettype: true,
      loginshow: false,
      dedatabo: {},
    };
  },
  mounted() {
    window.scrollTo({
      top: 0,
    });
    meetingdetail({
      meeting_id: this.subminData.meeting_id,
    }).then((res) => {
      console.log(res.data.data, "---------------------------");
      this.dedata = res.data.data.institution_types;
      this.dedatabo = res.data.data;
    });
    if (this.name) {
      sign_upgetdetail({
        sign_up_id: this.oderid,
      }).then((res) => {
        console.log(res);
        this.subminData = res.data.data;
        this.subminData.sms_type = 2;
        this.checkList = this.subminData.institution_types.split(",");
        // console.log(this.subminData.institution_types.split(','))
      });
    }
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    gopurchase() {
      this.subminData.institution_types = this.checkList.join(",");
      if (!this.subminData.name) {
        this.$message({
          message: "姓名不能为空",
          iconClass: "true",
          center: "true",
        });
        return;
      } else if (!this.subminData.tel) {
        this.$message({
          message: "电话不能为空",
          iconClass: "true",
          center: "true",
        });
        return;
      } else if (!this.subminData.code) {
        this.$message({
          message: "验证码不能为空",
          iconClass: "true",
          center: "true",
        });
        return;
      } else if (!this.subminData.institution) {
        this.$message({
          message: "机构简称不能为空",
          iconClass: "true",
          center: "true",
        });
        return;
      } else if (!this.subminData.position) {
        this.$message({
          message: "职位不能为空",
          iconClass: "true",
          center: "true",
        });
        return;
      } else if (!this.subminData.institution_types) {
        this.$message({
          message: "机构类型不能为空",
          iconClass: "true",
          center: "true",
        });
        return;
      } else if (!this.subminData.institution_info) {
        this.$message({
          message: "机构简介不能为空",
          iconClass: "true",
          center: "true",
        });
        return;
      } else if (!this.subminData.name_card) {
        this.$message({
          message: "名片不能为空",
          iconClass: "true",
          center: "true",
        });
        return;
      }
      meetingsubmit(this.subminData).then((res) => {
        console.log(res);
        if (res.data.code == 10000) {
          this.success_type = true;
        }
        // this.$router.push("/purchase");
      });
    },
    goinfo() {
      if (!this.subminData.id) {
        if (this.getnamelettype == true) {
          sign_upgetInfo({
            name: this.subminData.name,
            tel: this.subminData.tel,
            institution: this.subminData.institution,
          })
            .then((res) => {
              if (JSON.stringify(res.data) == "{}") {
              } else {
                this.subminData.position = res.data.data.position;
                this.subminData.name_card = res.data.data.name_card;
                this.subminData.institution_types =
                  res.data.data.institution_types.split(",");
                this.checkList = res.data.data.institution_types.split(",");
                console.log(
                  this.subminData.institution_types,
                  "this.subminData.institution_types"
                );
                this.subminData.institution_info =
                  res.data.data.institution_info;
                this.getnamelettype = false;
              }
            })
            .catch((err) => {
              // this.$message({
              //   message: "网络请求超时",
              //   iconClass: "true",
              //   center: "true",
              // });
              return;
            });
        }
      }
    },
    timeree() {
      this.secondre = 60;
      let _this = this;
      var timer = setInterval(function () {
        if (_this.secondre === 0) {
          clearInterval(timer);
        } else {
          _this.secondre--;
        }
      }, 1000);
    },
    // handleChange(file, fileLists) {
    //   console.log(file);
    //   console.log(fileLists);
    //   // 本地服务器路径
    //   console.log(URL.createObjectURL(file.raw));
    //   // 虚拟盘符
    //   // JavaScript无法获取本地文件路径
    //   // JavaScript不支持对本地资源的操作
    //   console.log(document.getElementsByClassName("el-upload__input")[0].value);
    // },
    getcode() {
      let myreg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (this.subminData.tel && myreg.test(this.subminData.tel)) {
        console.log("正确的电话号码");
        console.log(this.subminData.tel);
        sign_upgetInfo({
          tel: this.subminData.tel,
          meeting_id: this.subminData.meeting_id,
        }).then((res) => {
          if (res.data.code == 10000) {
            if (!this.subminData.id) {
              if (res.data.data.is_sign_up == false) {
                gotcode({
                  mobile_phone: this.subminData.tel,
                  sms_type: 2,
                })
                  .then((res) => {
                    console.log(res);
                    if (this.secondre == 0) {
                      this.timeree();
                    }
                  })
                  .catch((err) => {
                    this.$message({
                      message: "网络请求超时",
                      iconClass: "true",
                      center: "true",
                    });
                    return;
                  });
              } else {
                this.$message({
                  message: "请勿重复报名",
                  iconClass: "true",
                  center: "true",
                });
                return;
              }
            } else {
              gotcode({
                mobile_phone: this.subminData.tel,
                sms_type: 2,
              })
                .then((res) => {
                  console.log(res);
                  if (this.secondre == 0) {
                    this.timeree();
                  }
                })
                .catch((err) => {
                  this.$message({
                    message: "网络请求超时",
                    iconClass: "true",
                    center: "true",
                  });
                  return;
                });
            }
          } else if (res.data.code == 10005) {
            if (this.loginshow == false) {
              this.loginshow = true;
            } else {
              this.loginshow = false;
            }
          }
        });
      } else {
        this.$message({
          message: "请填写正确的手机号",
          iconClass: "true",
          center: "true",
        });
        return;
      }
    },
    getImg(e) {
      console.log(e, "-------------");
      if (e.target.files[0]) {
        let file = e.target.files[0];
        var formData = new FormData();
        formData.append("file", file);
        uploadfile(formData).then((res) => {
          this.subminData.name_card = res.data.data;
        });
      }
    },
  },
  created() {
    console.log(this.$route.query, "this.$route.query");
    this.id = this.$route.query.id;
    this.subminData.meeting_id = this.$route.query.id;
    if (this.$route.query.name) {
      this.name = this.$route.query.name;
      this.oderid = this.$route.query.oderid;
    }
  },
};
</script>

<style lang="scss" scoped>
.summit_Report {
  width: 100%;
  min-height: 100vh;
  position: relative;

  input:focus {
    outline: none;
    border: 1px solid #c32227 !important;
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  .myRedCheckBox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #c32227 !important;

    background-color: #c32227 !important;
  }

  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #c32227 !important;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: #c32227 !important;
  }
  /deep/.el-textarea__inner:focus {
    outline: none;
    border: 1px solid #c32227 !important;
  }

  .Report_cent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 49px;
    .Report_head {
      width: 1200px;
      height: 76px;
      background: #c32227;
      border-radius: 4px 4px 0px 0px;
      margin-top: 22px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 40px;
      box-sizing: border-box;
      color: #ffffff;
      font-size: 20px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
    }
    .success_form {
      width: 1200px;
      height: 352px;
      background: #f9f9f9;
      border-radius: 0px 0px 4px 4px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 34px 40px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      .sucicon {
        width: 51px;
        height: 51px;
      }
      .success_text {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin: 24px 0 17px;
      }
      .success_tips {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .success_but {
        width: 200px;
        height: 32px;
        background: #c32227;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .Report_form {
      width: 1200px;
      height: 650px;
      background: #f9f9f9;
      border-radius: 0px 0px 4px 4px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 34px 40px;
      box-sizing: border-box;
      .form_title {
        width: 56px;
        text-align: left;
        margin-right: 22px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        position: relative;
        .type_tips {
          position: absolute;
          bottom: -20px;
          left: 0px;
          font-size: 10px;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
        }
      }
      .inp {
        width: 200px;
        height: 32px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dadada;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
      }
      /deep/.el-textarea__inner {
        width: 100%;
        height: 100%;
      }
      .area {
        width: 1024px;
        height: 116px;
        background: #ffffff;
        border-radius: 4px;
        resize: none;
        box-sizing: border-box;
      }
      .form_name {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;
      }
      .upimg {
        width: 150px;
        height: 90px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dadada;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 12px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        position: relative;
        .card_img {
          width: 150px;
          height: 90px;
          position: absolute;
        }
        .i_none {
          width: 150px;
          height: 90px;
          position: absolute;
          top: 0px;
          left: 0px;
          opacity: 0;
        }
        .img {
          width: 34px;
          height: 27px;
          margin-bottom: 10px;
        }
      }
      .subutton {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 28px;
        .but {
          width: 200px;
          height: 32px;
          background: #c32227;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .form_cards {
        display: flex;
        justify-content: center;
        margin-bottom: 18px;
      }
      .form_types {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        .types_list {
          text-align: left;
          flex: 1;
        }
      }
      .form_brief {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
      }
      .form_institution {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;
      }
      .form_position {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;
      }
      .form_phone {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;
        .form_left {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .form_right {
          display: flex;
          align-items: center;
          margin-left: 50px;
          .code {
            font-size: 12px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #c32227;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .Report_title {
    width: 1200px;
    margin-top: 42px;
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .back {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      cursor: pointer;

      .iconimg {
        width: 16px;
        height: 12px;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .Report_cent {
    width: 100%;
    padding: 0 30px 100px !important;
    box-sizing: border-box;
    .Report_head {
      width: 100% !important;
      height: 150px !important;
      background: #c32227;
      border-radius: 10px 10px 0px 0px !important;
      margin-top: 40px !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 40px;
      box-sizing: border-box;
      color: #ffffff;
      font-size: 50px !important;
    }
    .success_form {
      width: 100% !important;
      height: 700px !important;
      background: #f9f9f9;
      border-radius: 0px 0px 4px 4px;
      padding: 34px 40px;
      .sucicon {
        width: 180px !important;
        height: 180px !important;
      }
      .success_text {
        font-size: 60px !important;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin: 24px 0 17px;
      }
      .success_tips {
        font-size: 42px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .success_but {
        width: 600px !important;
        height: 120px !important;
        background: #c32227;
        border-radius: 20px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
        font-size: 48px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .Report_form {
      width: 100% !important;
      height: auto !important;
      background: #f9f9f9;
      border-radius: 0px 0px 4px 4px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 50px 40px !important;
      box-sizing: border-box;
      .form_title {
        width: 240px !important;
        text-align: left;
        margin-right: 22px;
        font-size: 50px !important;
        .type_tips {
          position: absolute;
          bottom: -60px !important;
          left: 0px;
          font-size: 30px !important;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
        }
      }
      .inp {
        flex: 1;
        height: 150px !important;
        background: #ffffff;
        border-radius: 10px !important;
        border: 1px solid #dadada;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 50px !important;
        padding: 0 40px !important;
      }
      /deep/.el-textarea__inner {
        width: 100%;
        height: 100%;
      }
      .area {
        width: 800px !important;
        height: 300px !important;
        background: #ffffff;
        border-radius: 4px;
        resize: none;
        box-sizing: border-box;
      }
      .form_name {
        width: 100%;
        display: flex;
        justify-content: flex-start !important;
        align-items: center;
        margin-bottom: 40px !important;
      }
      .upimg {
        width: 500px !important;
        height: 300px !important;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dadada;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 40px !important;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        .card_img {
          width: 500px !important;
          height: 300px !important;
          position: absolute;
        }
        .i_none {
          width: 500px !important;
          height: 300px !important;
          position: absolute;
          top: 0px;
          left: 0px;
          opacity: 0;
        }
        .img {
          width: 102px !important;
          height: 81px !important;
          margin-bottom: 20px !important;
        }
      }
      .subutton {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 28px;
        .but {
          width: 600px !important;
          height: 120px !important;
          background: #c32227;
          border-radius: 20px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 48px !important;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .form_cards {
        display: flex;
        justify-content: center;
        margin-bottom: 40px !important;
      }
      .form_types {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px !important;
      }
      .form_brief {
        display: flex;
        justify-content: center;
        margin-bottom: 40px !important;
      }
      .form_institution {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px !important;
      }
      .form_position {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px !important;
      }
      .form_phone {
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px !important;
        .form_left {
          width: 100%;
          display: flex;
          justify-content: flex-start !important;
          align-items: center;
          margin-bottom: 40px !important;
        }
        .form_right {
          width: 100%;
          display: flex;
          align-items: center;
          margin-left: 0px !important;

          .code {
            font-size: 50px !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #c32227;
            margin-left: 30px !important;
          }
        }
      }
    }
  }
  .Report_title {
    width: 100% !important;
    margin-top: 80px !important;
    font-size: 80px !important;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .back {
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
        font-size: 40px !important;
        .iconimg {
          width: 64px !important;
          height: 48px !important;
        }
    }
  }
}
</style>