/*
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-05-11 16:23:50
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-16 17:52:34
 * @FilePath: \my-project\src\api\logoin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 引用封装的 request
import request from '@/js/request'

//获取验证码
export const articlesgetIndex = (data) => {
    return request({
        url: "api/cms/articles/getIndex",
        method: "POST",
        data, 
    });
};
