<!--
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-07-20 09:18:20
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-23 14:36:57
 * @FilePath: \my-project\src\views\index\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <!-- <topnar nar="首页"></topnar> -->
    <div class="toplsit">
      <div class="swiper">
        <el-carousel
          arrow="never"
          :indicator-position="imgdata.length > 1 ? '' : 'none'"
          trigger="click"
          class="swiperlist"
        >
          <el-carousel-item
            v-for="item in imgdata"
            :key="item.url"
            class="swiperitem"
          >
            <img class="img" @click="gourl(item)" :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="pcmin">
        <div class="seacrinp">
          <div class="leftinpt">
            <input
              class="inp"
              v-model="inptext"
              @keydown="seceinpt"
              placeholder="请输入您想搜索的内容…"
              type="search"
            />
            <div class="secaerimg" @click="clikseceinpt">
              <img class="img" src="../../assets/icon/sous_ssss.png" alt="" />
            </div>
          </div>
          <div class="rightinput" @click="openwx">
            <img
              class="increase pcbig"
              src="../../assets/icon/jair_t.png"
              alt=""
            />
            <p class="pcbig sixpcbig">现在加入我们，关注母基金新闻！</p>
            <p class="pcmin sixpcmin">加入我们</p>
          </div>
        </div>
      </div>
      <div class="right_lsit">
        <img class="iconimg" src="../../assets/./img/24_kx.png" alt="" />
        <div class="list" :class="tableData.length > 5 ? 'list' : ''">
          <el-tabs v-loading="loadingAdd">
            <el-tab-pane>
              <ul class="item">
                <vue-seamless-scroll
                  :data="tableData"
                  class="seamless-warp"
                  :class-option="optionSetting"
                >
                  <div
                    class="list_item"
                    @click="goinfo(item)"
                    v-for="item in tableData"
                    :key="item.sjbh"
                  >
                    <div class="itemtitle">
                      <div class="red"></div>
                      <div class="title">
                        {{ item.title }}
                      </div>
                    </div>
                    <div class="iteminfo">
                      {{ item.description }}
                    </div>
                  </div>
                </vue-seamless-scroll>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="list" v-if="tableData.length <= 5">
          <div
            class="list_item"
            v-for="item in tableData"
            @click="goinfo(item)"
            :key="item.sjbh"
          >
            <div class="itemtitle">
              <div class="red"></div>
              <div class="title">
                {{ item.title }}
              </div>
            </div>
            <div class="iteminfo">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wxshow" v-if="wxshow == true">
      <div class="wxtitle">加入我们</div>
      <img
        class="wximg"
        src="../../assets/icon/a5be8b41ac9c42d956a3e96a8dccb55.jpg"
        alt=""
      />
      <div class="wxtips">扫一扫关注公众号</div>
      <div class="wxtips">即可浏览公众号内容</div>
      <img
        class="iconimg"
        @click="closhow"
        src="../../assets/icon/guan_x.png"
        alt=""
      />
    </div>
    <div class="wxblock" v-if="wxshow == true"></div>
    <div class="seacrinp pcbig">
      <div class="leftinpt">
        <input
          class="inp"
          v-model="inptext"
          @keydown="seceinpt"
          placeholder="请输入您想搜索的内容…"
          type="search"
        />
        <div class="secaerimg" @click="clikseceinpt">
          <img class="img" src="../../assets/icon/sous_ssss.png" alt="" />
        </div>
      </div>
      <div class="rightinput" @click="openwx">
        <img class="increase pcbig" src="../../assets/icon/jair_t.png" alt="" />
        <p class="pcbig sixpcbig">现在加入我们，关注母基金新闻！</p>
        <p class="pcmin sixpcmin">加入我们</p>
      </div>
    </div>
    <div class="article_list">
      <div
        class="list_item"
        v-for="(item, index) in datalist"
        :key="index"
        @click="goinfo(item)"
      >
        <div class="item_left">
          <img class="img" :src="item.cover" alt="" />
        </div>
        <div class="item_right">
          <div class="item_title">{{ item.title }}</div>
          <div class="item_tearr">
            {{ item.description }}
          </div>
          <div class="item_time">
            <div class="red pcbig"></div>
            <div class="text">{{ item.name }}</div>
            <div class="shu pcbig">|</div>
            <div class="time">{{ item.created_at }}</div>
          </div>
        </div>
      </div>
      <div class="nolsitdata" v-if="moreshow == 3">
        <img class="nolsitdata_img" src="../../assets/img/nomore.png" alt="" />
        <div class="nolsitdata_text">暂无内容</div>
      </div>
    </div>
    <div class="more">
      <div class="more_cen" @click="page_turning" v-if="moreshow == 1">
        <div class="cen_text">加载更多…</div>
        <div class="cenicon">
          <img class="img" src="../../assets/icon/xiah_djt1.png" alt="" />
        </div>
      </div>
      <div class="more_text" v-if="moreshow == 2">已经到底了</div>
    </div>
    <upward></upward>
    <buton></buton>
  </div>
</template>
  
  <script>
import { getLast, imgcarousel, articlesgetList } from "@/api/index";
export default {
  data() {
    return {
      inptext: "",
      spde: 0.5,
      imgdata: [],
      tableData: [],
      wxshow: false,
      page: 1,
      datalist: [],
      loadingAdd: false,
      moreshow: 1,
    };
  },
  created() {
    const link = location.href;
    this.$wxShare.updateWxShareConfig({
      title: "FOFWEEKLY",
      desc: " ",
      link: link,
    });
  },
  //页面加载完成后调用
  activated() {
    // var _this = this;
    // _this.uuid = _this.$route.query.uuid;
    // var shareUrl = location.href
    // this.$wxShare.updateWxShareConfig({
    //   title: "1111111111",
    //   desc: "简22222222222222介",
    //   link: shareUrl,
    // });
  },
  methods: {
    seceinpt(e) {
      if (e.keyCode == 13) {
        this.$router.push({
          path: "search",
          query: { userId: "", sease: this.inptext },
        });
      }
    },
    clikseceinpt() {
      this.$router.push({
        path: "search",
        query: { userId: "", sease: this.inptext },
      });
    },
    openwx() {
      this.wxshow = true;
    },
    closhow() {
      this.wxshow = false;
    },
    goinfo(item) {
      this.$router.push({ path: "info", query: { id: item.id } });
    },
    gourl(item) {
      if (item.path) {
        window.open(item.path, "_blank");
      }
    },
    page_turning() {
      this.page++;
      this.init();
    },
    init() {
      if (this.moreshow == 2) {
        return 2;
      }
      articlesgetList({
        limit: "10",
        page: this.page,
      })
        .then((res) => {
          console.log(res);

          if (!!res.data.data.data.length) {
            this.datalist = this.datalist.concat(res.data.data.data);
          }
          if (res.data.data.current_page >= res.data.data.last_page) {
            this.moreshow = 2;
          }
          if (this.datalist.length == 0) {
            this.moreshow = 3;
          }
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
    },
    liat() {
      getLast()
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data.data;
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
      imgcarousel({
        id: "1",
      })
        .then((res) => {
          console.log(res);
          this.imgdata = res.data.data.images;
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
    },
  },
  mounted() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log("iPhone");
      this.spde = 0.2;
    } else if (/(Android)/i.test(navigator.userAgent)) {
      console.log("Android");
      this.spde = 0.2;
    } else {
      console.log("pc");
      this.spde = 0.5;
    }
    this.init();
    this.liat();
  },
  computed: {
    optionSetting() {
      return {
        step: this.spde, // 数值越大速度滚动越快
        limitMoveNum: this.tableData.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
};
</script>
  <style lang="scss" scoped>
.home {
  width: 100%;
  position: relative;
}
.pcbig {
  display: block;
}
.pcmin {
  display: none;
}
/deep/.el-carousel__container {
  height: auto !important;
}
.wxshow {
  /* prettier-ignore */
  width: 350pX;
  /* prettier-ignore */
  height: 350pX;
  background: #ffffff;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  /* prettier-ignore */
  margin-top: -175pX;
  /* prettier-ignore */
  margin-left: -175pX;
  z-index: 98;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .iconimg {
    /* prettier-ignore */
    width: 15pX;
    /* prettier-ignore */
    height: 14pX;
    position: absolute;
    /* prettier-ignore */
    right: 23pX;
    /* prettier-ignore */
    top: 23pX;
    cursor: pointer;
  }
  .wxtitle {
    /* prettier-ignore */
    font-size: 20pX;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }
  .wximg {
    /* prettier-ignore */
    width: 170pX;
    /* prettier-ignore */
    height: 170pX;
    margin: 38px 0px 29px;
  }
  .wxtips {
    /* prettier-ignore */
    font-size: 14pX;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }
}
.wxblock {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 97;
}
.more {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px 0;
  .more_cen {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .cen_text {
      font-size: 14px;
      margin-bottom: 6px;
    }
    .cenicon {
      .img {
        width: 14px;
        height: 10px;
      }
    }
  }
}
.nolsitdata {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .nolsitdata_img {
    width: 158px;
    height: 92px;
  }
  .nolsitdata_text {
    font-size: 14px;
    margin-top: 23px;
    color: #999999;
  }
}
.article_list {
  padding: 0 120px;
  box-sizing: border-box;
  margin-top: 28px;
  .list_item:first-child {
    padding-top: 0;
  }
  .list_item {
    cursor: pointer;
    display: flex;
    justify-content: start;
    padding: 27px 0;
    border-bottom: 1px solid #f6f6f6;
    .item_left {
      margin-right: 27px;
      .img {
        /* prettier-ignore */
        width: 266pX;
        /* prettier-ignore */
        height: 150pX;
      }
    }
    .item_right {
      .item_title {
        text-align: left;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 1;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 1; // 超出多少行
        -webkit-box-orient: vertical;
        /* prettier-ignore */
        margin-top: 10pX;
        /* prettier-ignore */
        font-size: 20pX;
      }
      .item_tearr {
        /* prettier-ignore */
        font-size: 14pX;
        text-align: left;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        /* prettier-ignore */
        margin-top: 13pX;
        /* prettier-ignore */
        margin-bottom: 38pX;
        /* prettier-ignore */
        height: 43pX;
        /* prettier-ignore */
        line-height: 20pX;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 2; // 超出多少行
        -webkit-box-orient: vertical;
      }
      .item_time {
        display: flex;
        justify-content: start;
        align-items: center;

        .text {
          /* prettier-ignore */
          font-size: 14pX;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
        }
        .red {
          width: 8px;
          height: 8px;
          background: #c32227;
          border-radius: 50px;
          margin-right: 10px;
        }
        .shu {
          padding: 0 20px;
          color: #999999;
          /* prettier-ignore */
          font-size: 14pX;
        }
        .time {
          /* prettier-ignore */
          font-size: 14pX;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
.seacrinp {
  padding: 0 120px;
  box-sizing: border-box;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  .leftinpt {
    width: 900px;
    height: 50px;
    background: #f6f6f6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    box-sizing: border-box;
    .inp {
      flex: 1;
      height: 50px;
      border: none;
      /* prettier-ignore */
      font-size: 16pX;
      background: #f6f6f6;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      outline: none;
    }
    .secaerimg {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        /* prettier-ignore */
        width: 21pX;
        /* prettier-ignore */
        height: 20pX;
        cursor: pointer;
      }
    }
  }
  .rightinput {
    width: 270px;
    height: 50px;
    background: #c32227;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    padding: 0 10px;
    /* prettier-ignore */
    font-size: 14pX;
    box-sizing: border-box;
    .increase {
      /* prettier-ignore */
      width: 22pX;
      /* prettier-ignore */
      height: 22pX;
      margin-right: 15px;
    }
  }
}
.toplsit {
  padding: 0 120px;
  box-sizing: border-box;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  .text {
    font-size: 18px;
  }
  .list::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  .list::-webkit-scrollbar-thumb {
    background-color: #c32227;
  }
  .right_lsit {
    text-align: left;
    /deep/.el-tabs__header {
      display: none !important;
    }
    .iconimg {
      width: 81px;
      height: 21px;
    }
  }

  .list {
    width: 270px;
    height: 300px;
    text-align: left;
    overflow: hidden;
    /deep/.el-tabs__nav-scroll {
      display: none !important;
    }
    /deep/.el-tabs__content {
      .item {
        padding: 0px !important;
      }
    }

    .list_item {
      width: 270px;
      /* prettier-ignore */
      height: 60pX;
      margin-bottom: 20px;
      cursor: pointer;
      .itemtitle {
        display: flex;
        justify-content: start;
        align-items: center;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        .title {
          width: 251px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          display: -webkit-box;
          -webkit-line-clamp: 1; // 超出多少行
          -webkit-box-orient: vertical;
          /* prettier-ignore */
          font-size: 16pX;
        }
        .red {
          width: 8px;
          height: 8px;
          background: #c32227;
          border-radius: 50px;
          margin-right: 10px;
        }
      }
      .iteminfo {
        width: 251px;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 2; // 超出多少行
        -webkit-box-orient: vertical;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        /* prettier-ignore */
        padding: 0 20pX;
        box-sizing: border-box;
        margin-top: 2px !important;
        /* prettier-ignore */
        font-size: 12pX;
      }
    }
  }
}
.swiper {
  width: 900px;
  height: 324px;
  margin-left: 0px !important;
  .swiperlist {
    width: 900px;
    height: 324px;

    .swiperitem {
      width: 900px;
      height: 324px;
      border-radius: 5px;
    }
  }
  .img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 1600px) {
  .sixpcbig {
    display: none !important;
  }
  .sixpcmin {
    display: block !important;
  }
}
@media screen and (max-width: 1280px) {
  .toplsit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .swiper {
      width: 100% !important;
      height: 420px;
      margin-left: 0px !important;

      .swiperlist {
        width: 100% !important;
        height: 420px;

        .swiperitem {
          width: 100% !important;
          height: 420px;
          border-radius: 20px !important;
        }
      }

      .img {
        width: 100%;
        height: 100%;
      }
    }

    .right_lsit {
      text-align: left;
      margin-top: 20px;
      /deep/.el-tabs__header {
        display: none !important;
      }

      .iconimg {
        width: 81px;
        height: 21px;
      }
      .list {
        width: 100%;
        overflow: hidden;
        .list_item {
          width: 100%;
          .iteminfo {
            width: 100%;
          }
        }
      }
    }
  }
  .nolsitdata {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .nolsitdata_img {
      width: 458px !important;
      height: 272px !important;
    }
    .nolsitdata_text {
      font-size: 50px !important;
      margin-top: 23px;
      color: #999999;
    }
  }
  .article_list {
    .list_item {
      padding: 40px 0 !important;
    }
    .item_tearr {
      /* prettier-ignore */
      margin-bottom: 21pX !important;
    }
    .item_time {
      flex-direction: column;
      align-items: start !important;
    }
  }
  .pcbig {
    display: none !important;
  }

  .pcmin {
    display: block !important;
  }
}

@media screen and (max-width: 1000px) {
  .pcbig {
    display: none !important;
  }

  .pcmin {
    display: block !important;
  }
  .wxshow {
    width: 1000px;
    height: 1000px;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    margin-top: -500px;
    margin-left: -500px;
    .iconimg {
      width: 51px;
      height: 49px;
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
    }
    .wxtitle {
      font-size: 60px !important;
    }
    .wximg {
      width: 500px !important;
      height: 500px !important;
    }
    .wxtips {
      font-size: 60px !important;
    }
  }
  .more {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px 0;
    .more_text {
      font-size: 50px !important;
    }
    .more_cen {
      .cen_text {
        font-size: 50px !important;
      }

      .cenicon {
        .img {
          width: 59px !important;
          height: 40px !important;
        }
      }
    }
  }
  .article_list {
    padding: 0 50px !important;
    box-sizing: border-box;
    margin-top: 28px;
    .list_item:first-child {
      padding-top: 0;
    }
    .list_item {
      cursor: pointer;
      display: flex;
      justify-content: start;
      padding: 20px 0;
      border-bottom: 1px solid #f6f6f6;
      .item_left {
        margin-right: 30px;
        .img {
          width: 530px !important;
          height: 380px !important;
          border-radius: 10px;
        }
      }
      .item_right {
        .item_title {
          font-size: 60px !important;
          height: 60px;
          margin-top: 20px !important;
        }
        .item_tearr {
          height: 100px;
          font-size: 44px !important;
          margin-top: 20px !important;
          margin-bottom: 0px !important;
          line-height: 50px !important;
        }
        .item_time {
          display: flex;
          justify-content: start;
          align-items: center;
          margin-top: 30px !important;

          .text {
            font-size: 44px !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
          }
          .red {
            width: 6px;
            height: 6px;
            background: #c32227;
            border-radius: 50px;
            margin-right: 10px;
          }
          .shu {
            padding: 0 20px;
            color: #999999;
            font-size: 38px !important;
          }
          .time {
            font-size: 44px !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            margin-top: 10px !important;
          }
        }
      }
    }
  }
  .seacrinp {
    padding: 0 0px !important;
    .leftinpt {
      width: 930px !important;
      height: 200px;
      background: #f6f6f6;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      box-sizing: border-box;
      .inp {
        flex: 1;
        height: 50px;
        border: none;
        font-size: 48px;
        background: #f6f6f6;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        outline: none;
      }
      .secaerimg {
        width: 87px;
        height: 87px;
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          width: 48px !important;
          height: 47px !important;
          cursor: pointer;
        }
      }
    }
    .rightinput {
      width: 400px;
      height: 198px;
      background: #c32227;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
      font-size: 60px;
      .increase {
        width: 29px;
        height: 29px;
        margin-right: 15px;
      }
    }
  }
  .toplsit {
    padding: 0 0px !important;
    flex-direction: column;
    margin-top: 0px !important;
    .swiper {
      width: 100% !important;
      height: 600px;
      margin-left: 0px !important;

      .swiperlist {
        width: 100% !important;
        height: 600px;

        .swiperitem {
          width: 100% !important;
          height: 600px;
          border-radius: 0px !important;
        }
      }

      .img {
        width: 100%;
        height: 100%;
      }
    }
    .list::-webkit-scrollbar {
      width: 15px;
      height: 10px;
    }

    .list::-webkit-scrollbar-thumb {
      background-color: #c32227;
    }
    .iconimg {
      width: 300px !important;
      height: 80px !important;
      margin-top: 45px;
      padding: 0 50px !important;
      box-sizing: border-box;
    }
    .list {
      width: 100% !important;
      height: 700px !important;
      text-align: left;
      margin-top: 40px;
      overflow-y: scroll;
      padding: 0 50px !important;
      box-sizing: border-box;

      .list_item {
        width: 100% !important;
        height: 200px !important;
        .itemtitle {
          .title {
            width: 100% !important;
            font-size: 60px !important;
          }
        }

        .iteminfo {
          width: 100% !important;
          font-size: 40px !important;
        }
      }
    }
  }
}
</style>
  