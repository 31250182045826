<template>
    <div>
      </mt-header>
      <div class="examples">
        <div class="bannerBox">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in banner"  :key="index">
                <img :src="item.url" alt="">
              </div>
            </div>
          </div>
        </div>
        
        <div class="bannerIndex" v-if="banner.length < 1" >
          <!-- <span>{{bannerIndex}}</span>/{{ banner.length }} -->
          <div v-for="(item,index) in banner" :key="index" class="baindex"  :class=" bannerIndex==index?'activebarindex':''" >

          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import { imgcarousel } from "@/api/index";
export default {
  data() {
    return {
      bannerIndex: 0,
      banner: [
        {
          picture_path:
            "https://lmg.jj20.com/up/allimg/1113/052420110515/200524110515-1-1200.jpg",
        },
        {
          picture_path:
            "https://lmg.jj20.com/up/allimg/tp05/1Z9291T012CB-0-lp.jpg",
        },
        {
          picture_path:
            "https://lmg.jj20.com/up/allimg/1113/042220121425/200422121425-3-1200.jpg",
        },
        {
          picture_path:
            "https://lmg.jj20.com/up/allimg/1113/050920095302/20050Z95302-18-1200.jpg",
        },
      ],
    };
  },
  mounted() {
    this.getimgdata()
    let that = this;
    /* eslint-disable */
    setTimeout(() => {
      let mySwiper = new Swiper(".swiper-container", {
        direction: "horizontal", //滑动方向，可设置水平(horizontal)或垂直(vertical)。
        loop: true, // 设置为true 则开启loop模式
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        }, // 自动切换时间
        slidesPerView: "auto", // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
        centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
        spaceBetween: 0, // 在slide之间设置距离（单位px）。
        loopAdditionaSlider: 0, // loop模式下会在slides前后复制若干个slide,，前后复制的个数不会大于原总个数。
        on: {
          slideChangeTransitionStart: function () {
            // alert(this.activeIndex); //切换结束时，告诉我现在是第几个slide
            console.log(this.realIndex);
            that.bannerIndex = this.realIndex;
          },
        },
      });
    }, 100);
  },
  methods:{
    getimgdata() {
      imgcarousel({
        id: "7",
      }).then((res) => {
          console.log(res);
          this.banner = res.data.data.images;
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
    },
  }
};
</script>
  
  <style lang="less" scoped>
.examples {
  position: absolute;
  top: 44px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  // background-color: #f5f0f0;
  font-size: 14px;
  overflow: hidden;
  height: 500px;
  .tips {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 46px;
    background-color: #b5654d;
    text-align: center;
    img {
      width: 12px;
      height: 14px;
      position: absolute;
      top: 7.5px;
      left: 12vw;
      color: #fff;
    }
    p {
      width: 240px;
      text-align: left;
      color: #fff;
      font-size: 12px;
      position: absolute;
      left: 18vw;
      top: 7px;
      line-height: 16px;
    }
  }
}
.bannerTxt {
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #666666;
  font-size: 15px;
}

@media screen and (max-width: 1000px) {
  .bannerIndex {
    display: none !important;
  }
}
.bannerIndex {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  .baindex {
    width: 30px;
    height: 2px;
    background: #333333;
    margin: 0 5px;
  }
  .activebarindex {
    width: 30px;
    height: 4px !important;
    background: #c32227 !important;
  }
  span {
    color: #b5654d;
  }
}
.bannerBox {
  position: relative;
  height: 460px;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-container .swiper-wrapper .swiper-slide {
  width: 1200px !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 0 35px;
}
.swiper-container .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: 460px;
  border-radius: 5px;
}
// .swiper-container .swiper-wrapper .swiper-slide-prev,
// .swiper-container .swiper-wrapper .swiper-slide-next {
//   height: 362px !important;
//   margin-top: 30px;
// }
// .swiper-container .swiper-wrapper .swiper-slide-prev img,
// .swiper-container .swiper-wrapper .swiper-slide-next img {
//   width: 100%;
//   height: 100%;
// }
</style>