<!--
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-07-19 18:11:49
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-23 14:53:39
 * @FilePath: \my-project\src\components\topnar.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="nar">
    <div class="toplog">
      <div>
        <div class="pcbig">机构投资者专业服务平台</div>
        <img
          class="logoimg pcmin"
          @click="goindex('首页', '首页')"
          src="../assets/img/hons_s_lo.png"
          alt=""
        />
      </div>
      <div class="logtext">
        <div class="logo_left" v-if="!userinfo.nick_name">
          <div class="log_secel">
            <!-- <div class="registeron" @click="goMask(2)">注册</div>
            <div>/</div> -->
            <div class="logon" @click="goMask(1)">登录</div>
          </div>
        </div>
        <div class="logo_left" v-if="userinfo.nick_name" @click="gouser()">
          {{ userinfo.nick_name }}
          <div class="log_exit" @click.stop="cleartoken">退出登录</div>
        </div>
        <el-button
          @click.stop="drawer = true"
          type="primary"
          style="margin-left: 16px"
          class="pcmin"
        >
          <img class="iconimg" src="../assets/icon/cd.png" alt="" />
        </el-button>
      </div>
    </div>
    <div
      class="posback"
      :class="drawer == true ? 'unleft' : 'pushleft'"
      @click.stop="leftclo"
      v-if="drawer == true"
    ></div>
    <div
      class="rightLoft"
      :class="drawer == true ? 'unleft' : 'pushleft'"
      v-if="drawer == true"
    >
      <div
        class="listnaritem"
        @click="goindex('首页', '首页')"
        :class="{ active: narnumber == '首页' }"
      >
        首页
      </div>
      <div
        class="listnaritem"
        v-for="(item, index) in nardata"
        :key="index"
        @click="goindex(item, item.name)"
        :class="{ active: narnumber == item.name }"
      >
        {{ item.name }}
      </div>
      <!-- <div
        class="listnaritem"
        :class="{ active: narnumber == 2 }"
        @click="gofof"
      >
        FOF观察
      </div>
      <div
        class="listnaritem"
        :class="{ active: narnumber == 3 }"
        @click="goitem('/findLp')"
      >
        发现LP
      </div>
      <div
        class="listnaritem"
        :class="{ active: narnumber == 4 }"
        @click="goitem('/firstLine')"
      >
        一线快讯
      </div>
      <div
        class="listnaritem"
        :class="{ active: narnumber == 5 }"
        @click="goitem('/observation')"
      >
        海外观察
      </div>
      <div
        class="listnaritem"
        :class="{ active: narnumber == 6 }"
        @click="goitem('/report')"
      >
        行业报告
      </div>
      <div
        class="listnaritem"
        :class="{ active: narnumber == 7 }"
        @click="gosummti"
      >
        峰会
      </div>
      <div
        class="listnaritem"
        :class="{ active: narnumber == 8 }"
        @click="goitem('/LIST')"
      >
        榜单
      </div> -->
    </div>
    <div class="topnar pcbig">
      <img
        class="topimg"
        @click="goindex('首页', '首页')"
        src="../assets/img/hons_s_lo.png"
        alt=""
      />
      <div class="narlist">
        <div
          class="naritem"
          @click="goindex('首页', '首页')"
          :class="{ active: narnumber == '首页' }"
        >
          首页
        </div>
        <div
          class="naritem"
          v-for="(item, index) in nardata"
          :key="index"
          @click="goindex(item, item.name)"
          :class="{ active: narnumber == item.name }"
        >
          {{ item.name }}
        </div>
        <!-- <div class="naritem" :class="{ active: narnumber == 2 }" @click="gofof">
          FOF观察
        </div>
        <div
          class="naritem"
          :class="{ active: narnumber == 3 }"
          @click="goitem('/findLp')"
        >
          发现LP
        </div>
        <div
          class="naritem"
          :class="{ active: narnumber == 4 }"
          @click="goitem('/firstLine')"
        >
          一线快讯
        </div>
        <div
          class="naritem"
          :class="{ active: narnumber == 5 }"
          @click="goitem('/observation')"
        >
          海外观察
        </div>
        <div
          class="naritem"
          :class="{ active: narnumber == 6 }"
          @click="goitem('/report')"
        >
          行业报告
        </div>
        <div
          class="naritem"
          :class="{ active: narnumber == 7 }"
          @click="gosummti"
        >
          峰会
        </div>
        <div
          class="naritem"
          :class="{ active: narnumber == 8 }"
          @click="goitem('/LIST')"
        >
          榜单
        </div> -->
      </div>
    </div>
    <div class="login" v-if="loginshow">
      <div class="loigin_masking">
        <img class="leftimg pcbig" src="../assets/img/dengl_p_t.png" alt="" />
        <div class="right_wif">
          <img
            class="close"
            @click="ckclose"
            src="../assets/icon/guan_x.png"
            alt=""
          />
          <div class="log">
            <div class="logtitle" v-if="loginslce == 1">登录</div>
            <div class="logtitle_tips">登录即代表注册FOFWEEKLY账号</div>
            <div class="register_title" v-if="loginslce == 2">
              <div class="title_one">注册账号</div>
              <div class="title_tow">您将注册FOFWEEKLY账号</div>
            </div>
            <div class="account_number" v-if="loginslce == 1">
              <img
                class="cionimg phoneimg"
                src="../assets/icon/shouji_jj.png"
                alt=""
              />
              <input
                class="inp"
                v-model="finddata.login_phone"
                placeholder="请输入手机号码"
                type="number"
              />
            </div>
            <div class="account_number lstimg" v-if="loginslce == 1">
              <img
                class="cionimg codeimg"
                src="../assets/icon/yanzm_mm.png"
                alt=""
              />
              <div class="inputbuton">
                <input
                  class="inp"
                  v-model="finddata.code"
                  placeholder="请输入验证码"
                  type="text"
                />
                <div class="obtaincode" @click="phonecode">
                  {{ secondre != 0 ? secondre : "获取验证码" }}
                </div>
              </div>
            </div>
            <div class="account_number" v-if="loginslce == 2">
              <input
                class="inp"
                placeholder="请输入昵称"
                v-model="slesdata.nick_name"
                type="text"
              />
            </div>
            <div class="account_number rigimg" v-if="loginslce == 2">
              <input
                class="inp"
                placeholder="请输入手机号码"
                v-model="slesdata.login_phone"
                type="number"
              />
            </div>
            <div class="account_number rigimg" v-if="loginslce == 2">
              <div class="inputbuton">
                <input
                  class="inp"
                  v-model="slesdata.code"
                  placeholder="请输入验证码"
                  type="text"
                />
                <div @click="getselecode" class="obtaincode">
                  {{ second != 0 ? second : "获取验证码" }}
                </div>
              </div>
            </div>
            <div class="reye">
              <img
                class="reimg"
                v-if="loginslce == 3"
                src="../assets/img/cheng_gtb.png"
                alt=""
              />
            </div>
            <div v-if="loginslce == 3" class="rightye">注册成功</div>
            <div class="sumint" @click="sumintlogo" v-if="loginslce == 1">
              登录
            </div>
            <div class="residuebut" v-if="loginslce == 1">
              <div class="re_password">
                <!-- 找回密码 -->
              </div>
              <!-- <div class="re_account" @click="gorigth">注册账号</div> -->
            </div>
            <div class="sumint" @click="reyego" v-if="loginslce == 2">注册</div>
            <div class="sumint" @click="relognin" v-if="loginslce == 3">
              去登录
            </div>
            <div class="have" v-if="loginslce == 2">
              已有账户？ <b class="cagolog" @click="gologin">请登录</b>
            </div>
            <div class="log_radio" v-if="loginslce != 3">
              <el-checkbox v-model="checked"></el-checkbox>
              <div class="radio_text" @click="celseckbox">
                <p v-if="loginslce == 1">登录即代表同意</p>
                <p v-if="loginslce == 2">阅读并同意</p>
                <b @click="goDatlit">母基金条款和条件</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="black"></div>
    </div>
  </div>
</template>

<script>
import { gotcode, getenroll, mobilePhoneLogin, usersinfo } from "@/api/logoin";
import { getTreeList } from "@/api/index";
export default {
  name: "topnar",
  props: {
    nar: String,
    logshow: Boolean,
  },
  data() {
    return {
      loginslce: 1,
      loginshow: false,
      drawer: false,
      narnumber: null,
      secondre: 0,
      second: 0,
      checked: false,
      finddata: {
        login_phone: "",
        code: "",
        sms_type: 1,
      },
      slesdata: {
        login_phone: "",
        code: "",
        nick_name: "",
      },
      userinfo: {},
      timereenomor: true,
      nardata: [],
    };
  },
  watch: {
    logshow() {
      //materielId为需要监听的data
      // this.loginshow = true;
      this.ckclose();
    },
  },
  mounted() {
    console.log(this.nar);
    this.userinfoun();
  },
  created() {
    console.log(window.location.href, "=====================");
    if (window.location.href == "https://www.fofweekly.com/pc/") {
      this.narnumber = "首页";
    } else {
      this.narnumber = this.$route.query.name;
    }
  },
  methods: {
    celseckbox() {
      if (this.checked == true) {
        this.checked = false;
      } else {
        this.checked = true;
      }
    },
    leftclo() {
      this.drawer = false;
    },
    cleartoken() {
      localStorage.removeItem("cc_token");
      let currentPath = window.location.pathname;
      console.log(currentPath);
      this.userinfoun();
      this.narnumber = "首页";
      if (currentPath != "/pc/") {
        this.$router.push({
          path: "/",
        });
      } else {
        return;
      }
    },
    userinfoun() {
      getTreeList({
        parent_id: "14",
      }).then((res) => {
        console.log(res, "栏目树级列表");
        this.nardata = res.data.data;
      });
      usersinfo({}).then((res) => {
        console.log(res.data.data);
        this.userinfo = res.data.data;
      });
    },
    goDatlit() {
      let currentPath = window.location.pathname;
      if (currentPath != "/fundTerms") {
        this.$router.push({
          path: "/fundTerms",
        });
      }
    },
    phonecode() {
      let myreg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      console.log(this.finddata.login_phone);
      if (this.finddata.login_phone && myreg.test(this.finddata.login_phone)) {
        console.log("正确的电话号码");
        console.log(this.finddata.login_phone);
        gotcode({
          mobile_phone: this.finddata.login_phone,
          sms_type: 1,
        })
          .then((res) => {
            console.log(res);
            if (this.secondre == 0) {
              this.timeree();
            }
          })
          .catch((err) => {
            this.$message({
              message: "网络请求超时",
              iconClass: "true",
              center: "true",
            });
            return;
          });
      } else {
        this.$message({
          message: "请填写正确的手机号",
          iconClass: "true",
          center: "true",
        });
        return;
      }
    },
    getselecode() {
      let myreg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (this.slesdata.login_phone && myreg.test(this.slesdata.login_phone)) {
        console.log("正确的电话号码");
        console.log(this.slesdata.login_phone);
        gotcode({
          mobile_phone: this.slesdata.login_phone,
        })
          .then((res) => {
            console.log(res);
            if (this.second == 0) {
              this.timere();
            }
          })
          .catch((err) => {
            this.$message({
              message: "网络请求超时",
              iconClass: "true",
              center: "true",
            });
            return;
          });
      } else {
        this.$message({
          message: "请填写正确的手机号",
          iconClass: "true",
          center: "true",
        });
        return;
      }
    },
    timeree() {
      this.secondre = 60;
      let _this = this;
      var timer = setInterval(function () {
        if (_this.secondre === 0) {
          clearInterval(timer);
        } else {
          _this.secondre--;
        }
      }, 1000);
    },
    timere() {
      this.second = 60;
      let _this = this;
      var timerr = setInterval(function () {
        if (_this.second === 0) {
          clearInterval(timerr);
        } else {
          _this.second--;
        }
      }, 1000);
    },
    gorigth() {
      this.loginslce = 2;
    },
    gologin() {
      this.loginslce = 1;
    },
    reyego() {
      if (this.checked == false) {
        this.$message({
          message: "请勾选同意条款",
          iconClass: "true",
          center: "true",
        });
        return;
      }
      let myreg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      console.log(this.slesdata.login_phone);
      if (this.slesdata.login_phone && myreg.test(this.slesdata.login_phone)) {
        console.log("正确的电话号码");
        console.log(this.slesdata.login_phone);
        this.loginslce = 3;
        getenroll(this.slesdata)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            this.$message({
              message: "网络请求超时",
              iconClass: "true",
              center: "true",
            });
            return;
          });
      } else {
        this.$message({
          message: "请填写正确的手机号",
          iconClass: "true",
          center: "true",
        });
        return;
      }
    },
    sumintlogo() {
      if (this.checked == false) {
        this.$message({
          message: "请勾选同意条款",
          iconClass: "true",
          center: "true",
        });
        return;
      }
      let myreg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      console.log(this.finddata.login_phone);
      if (this.finddata.login_phone && myreg.test(this.finddata.login_phone)) {
        console.log("正确的电话号码");
        console.log(this.finddata.login_phone);
        mobilePhoneLogin(this.finddata)
          .then((res) => {
            console.log(res);
            if (res.data.code == 10000) {
              localStorage.setItem("cc_token", res.data.data.token);
              this.userinfoun();
              console.log(localStorage.getItem("cc_token"));
              this.loginshow = false;
              console.log("11111111111111111111---------------");
              location.reload();
            } else if (res.data.code == 10200) {
              this.$message({
                message: "您还未注册",
                iconClass: "true",
                center: "true",
              });
              return;
            } else if (res.data.code == 10002) {
              // this.$message({
              //   message: res.data.message,
              //   iconClass: "true",
              //   center: "true",
              // });
              return;
            } else {
              this.$message({
                message: res.data.message.msg,
                iconClass: "true",
                center: "true",
              });
              return;
            }
          })
          .catch((err) => {
            this.$message({
              message: "网络请求超时",
              iconClass: "true",
              center: "true",
            });
            return;
          });
      } else {
        this.$message({
          message: "请填写正确的手机号",
          iconClass: "true",
          center: "true",
        });
        return;
      }
    },
    relognin() {
      this.loginslce = 1;
    },
    gosummti() {
      let currentPath = window.location.pathname;
      if (currentPath != "/pc/summit") {
        this.$router.push("/summit");
      }
    },
    goitem(val) {
      console.log("-----------------");
      let currentPath = window.location.pathname;
      if (currentPath != "/pc" + val) {
        this.$router.push(val);
      }
    },
    gofof() {
      let currentPath = window.location.pathname;
      if (currentPath != "/pc/fofobserve") {
        this.$router.push("/fofobserve");
      }
    },
    gouser() {
      let currentPath = window.location.pathname;
      if (currentPath != "/pc/admin") {
        this.$router.push("/admin");
      }
    },
    ckclose() {
      if (this.loginshow == true) {
        this.loginshow = false;
      } else {
        this.loginshow = true;
      }
    },
    goindex(item, value) {
      console.log(item);
      let currentPath = window.location.pathname;
      if (value == "首页") {
        if (currentPath != "/pc/") {
          this.$router.push({
            path: "/",
            // query: { name: item.name },
          });
          this.narnumber = value;
          this.drawer = false;
          return;
        }
        return;
      } else if (item.url == "/pc/summit") {
        this.$router.push({
          path: "/summit",
          query: { name: item.name, id: item.id },
        });
        this.narnumber = value;
        this.drawer = false;
        return;
      } else if (this.narnumber != value) {
        this.$router.push({
          path: "/generaLarticles",
          query: { name: item.name, id: item.id },
        });
        this.narnumber = value;
        // this.$router.go(0);
      }
      this.drawer = false;
    },
    goMask(val) {
      // this.$emit("change", 1);
      this.loginshow = true;
      this.loginslce = val;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/.el-checkbox__inner {
  border-radius: 50px;
}
/deep/.el-button {
  background: none !important;
  border: none !important;
  padding: 0px !important;
}
.pcbig {
  display: block;
}
.pcmin {
  display: none;
}

.toplog {
  width: 100%;
  /* prettier-ignore */
  height: 67Px;
  /* prettier-ignore */
  font-size: 14pX;
  background: #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  padding: 0 120px;
  box-sizing: border-box;

  .logtext {
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 1;
    /* prettier-ignore */
    font-size: 18pX;
    height: 100%;

    .iconimg {
      width: 100px;
      height: 100px;
    }
    .logo_left {
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      /* prettier-ignore */
      font-size: 16pX;
      .log_secel {
        display: flex;
        justify-content: center;
        align-items: center;
        .registeron:hover {
          color: #c32227;
        }
        .logon:hover {
          color: #c32227;
        }
      }
      .log_exit {
        /* prettier-ignore */
        width: 100pX;
        /* prettier-ignore */
        height: 40pX;
        /* prettier-ignore */
        line-height: 40pX;
        text-align: center;
        position: absolute;
        /* prettier-ignore */
        bottom: -40pX;
        left: 50%;
        /* prettier-ignore */
        margin-left: -50pX;
        background: #c32227;
        color: #fff;
        border-radius: 5px;
        z-index: 999;
        display: none;
      }
    }
    .logo_left:hover .log_exit {
      display: block;
    }
    .logo_left:hover {
      color: #c32227 !important;
    }
    // .logo_left:hover {
    //   color: #c32227;
    //   /* prettier-ignore */
    //   font-size: 16pX;
    // }
  }
  .logoimg {
    width: 195px;
    height: 51px;
    cursor: pointer;
  }
}
.login {
  .loigin_masking {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 98;
    left: 50%;
    /* prettier-ignore */
    margin-left: -500pX;
    top: 50%;
    /* prettier-ignore */
    margin-top: -250pX;
    .leftimg {
      /* prettier-ignore */
      width: 380pX;
      /* prettier-ignore */
      height: 500pX;
    }
    .right_wif {
      /* prettier-ignore */
      width: 620pX;
      /* prettier-ignore */
      height: 500pX;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .close {
        position: absolute;
        /* prettier-ignore */
        right: 23pX;
        /* prettier-ignore */
        top: 23pX;
        /* prettier-ignore */
        width: 15pX;
        /* prettier-ignore */
        height: 14pX;
        cursor: pointer;
      }
      .log {
        text-align: left;
        .logtitle {
          /* prettier-ignore */
          font-size: 20pX;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          /* prettier-ignore */
          margin-bottom: 3pX;
        }
        .logtitle_tips {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          /* prettier-ignore */
          margin-bottom: 30pX;
        }
        .register_title {
          /* prettier-ignore */
          margin-bottom: 27pX;
          .title_one {
            /* prettier-ignore */
            font-size: 20pX;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
          .title_tow {
            /* prettier-ignore */
            font-size: 14pX;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
        .lstimg {
          /* prettier-ignore */
          margin-top: 21pX;
        }
        .rigimg {
          /* prettier-ignore */
          margin-top: 10pX;
        }
        .account_number {
          /* prettier-ignore */
          width: 330pX;
          /* prettier-ignore */
          height: 50pX;
          background: #ffffff;
          border-radius: 5px;
          border: 2px solid #f4f4f4;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;
          .inputbuton {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .obtaincode {
              /* prettier-ignore */
              font-size: 14pX;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 600;
              color: #c32227;
              cursor: pointer;
            }
            .inp {
              width: 100%;
            }
          }

          .cionimg {
            /* prettier-ignore */
            width: 14pX;
            /* prettier-ignore */
            height: 16pX;
            margin-right: 10px;
          }
          .inp {
            flex: 1;
            height: 92%;
            border: none;
            outline: none;
            /* prettier-ignore */
            font-size: 14pX;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
        .log_radio {
          display: flex;
          justify-content: center;
          align-items: center;

          .radio_text {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            cursor: pointer;
            /* prettier-ignore */
            font-size: 12pX;
          }
        }
        .rightye {
          .reimg {
            /* prettier-ignore */
            width: 50pX;
            /* prettier-ignore */
            height: 50pX;
          }
        }
        .reye {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .rightye {
          text-align: center;
          /* prettier-ignore */
          font-size: 16pX;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          /* prettier-ignore */
          margin-top: 24pX;
        }
        .sumint {
          /* prettier-ignore */
          width: 330pX;
          /* prettier-ignore */
          height: 50pX;
          background: #c32227;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          /* prettier-ignore */
          font-size: 16pX;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          /* prettier-ignore */
          margin-top: 30pX;
          cursor: pointer;
        }
        .have {
          display: flex;
          justify-content: center;
          align-items: center;
          /* prettier-ignore */
          margin-top: 11pX;
          /* prettier-ignore */
          margin-bottom: 37pX;
          /* prettier-ignore */
          font-size: 14pX;
          .cagolog {
            color: #c32227;
            cursor: pointer;
          }
        }
        .residuebut {
          display: flex;
          justify-content: center;
          align-items: center;
          /* prettier-ignore */
          font-size: 14pX;
          /* prettier-ignore */
          margin-bottom: 70pX;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          // margin-top: 30px;
          .re_account {
            /* prettier-ignore */
            font-size: 14pX;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c32227;
            cursor: pointer;
          }
        }
      }
    }
  }
  .black {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 97;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
  }
}
.active {
  font-family: SourceHanSansCN-Heavy, SourceHanSansCN !important;
  font-weight: 800 !important;
  color: #c32227 !important;
}
.topnar {
  width: 100%;
  /* prettier-ignore */
  height: 112Px;
  background: #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 120px;
  box-sizing: border-box;
  .narlist {
    display: flex;
    justify-content: center;
    align-items: center;

    .naritem {
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
      margin-left: 26px;
      /* prettier-ignore */
      font-size: 16pX;
    }
  }
  .topimg {
    width: 146px;
    height: 38px;
    cursor: pointer;
  }
}
@media screen and (max-width: 1000px) {
  .pcbig {
    display: none !important;
  }

  .pcmin {
    display: block !important;
  }
  .posback {
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 998;
  }

  .rightLoft {
    position: fixed;
    right: 0px;
    top: 0px;
    background: #fff;
    height: 100%;
    border-left: 1px solid #999999;
    z-index: 999;
    // animation: fadeIn 0.4s 0.1s ease both;
  }
  .pushleft {
    animation: fadeIn 0.15s forwards;
  }
  .unleft {
    animation: fadaot 0.15s forwards;
  }
  /* 定义淡入动画 */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(400upx);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadaot {
    0% {
      opacity: 0;
      transform: translateY(400upx);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .listnaritem {
    font-size: 60px !important;
    padding: 60px 60px !important;
    border-bottom: 1px solid #999999;
    animation-name: ball;
    animation: fadeIn 0.4s 0.1s ease both;
  }
  .toplog {
    padding: 0 50px !important;
    font-size: 70px !important;

    .logoimg {
      /* prettier-ignore */
      width: 120pX !important;
      /* prettier-ignore */
      height: 30pX !important;
      cursor: pointer;
    }
  }

  .login {
    .loigin_masking {
      margin-left: -600px !important;
      margin-top: -800px;
      .right_wif {
        width: 1200px;
        height: 1600px;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .close {
          position: absolute;
          right: 60px;
          top: 60px;
          width: 50px;
          height: 50px;
          cursor: pointer;
        }
        .log {
          text-align: left;
          .logtitle {
            font-size: 90px;
            margin-bottom: 50px;
          }
          .logtitle_tips {
            font-size: 50px !important;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            /* prettier-ignore */
            margin-bottom: 30pX;
          }
          .rightye {
            font-size: 60px;
          }
          .have {
            font-size: 60px !important;
            margin-top: 60px !important;
            margin-bottom: 30px !important;
          }
          .register_title {
            margin-bottom: 30px;
            .title_one {
              font-size: 90px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
            .title_tow {
              font-size: 60px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
          .lstimg {
            margin-top: 28px;
          }
          .account_number {
            width: 1000px;
            height: 200px;
            background: #ffffff;
            border-radius: 15px;
            border: 6px solid #f4f4f4 !important;
            padding: 0 20px;
            .inputbuton {
              .obtaincode {
                font-size: 60px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 600;
                color: #c32227;
                cursor: pointer;
              }
              .inp {
                width: 100%;
              }
            }
            //   .phoneimg{
            //     width: 69px;
            //     height: 79px !important;
            //     margin-right: 10px;
            // }
            .cionimg {
              width: 66px;
              height: 73px;
              margin-right: 10px;
            }
            .inp {
              font-size: 50px;
            }
          }
          .log_radio {
            .radio_text {
              margin-left: 10px;
              font-size: 60px;
            }
          }
          .sumint {
            width: 1000px;
            height: 200px;
            border-radius: 20px !important;
            font-size: 80px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            margin-top: 60px;
            cursor: pointer;
          }
          .residuebut {
            font-size: 60px;
            margin-top: 40px;
            margin-bottom: 140px;
            .re_account {
              font-size: 60px;
            }
          }
        }
      }
    }
    .black {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 97;
      left: 0px;
      top: 0px;
      background: rgba(0, 0, 0, 0.2);
    }
  }
}
@media screen and (max-width: 1280px) {
  .pcbig {
    display: none !important;
  }

  .pcmin {
    display: block !important;
  }
  .listnaritem {
    font-size: 20px;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #999999;
  }
  .logtext {
    .iconimg {
      /* prettier-ignore */
      width: 30pX !important;
      /* prettier-ignore */
      height: 30pX !important;
    }
  }
  .loigin_masking {
    /* prettier-ignore */
    margin-left: -310pX !important;
  }
}
</style>
