<template>
  <div class="home">
    <topnar @change="handleChange($event)"></topnar>
    <div class="seacrinp">
      <div class="leftinpt">
        <input
          class="inp"
          v-model="inptext"
          @keydown="seceinpt"
          placeholder="请输入您想搜索的内容…"
          type="text"
        />
        <div class="secaerimg" @click="seceinptow">搜索</div>
      </div>
    </div>
    <div class="seacr_title">搜索结果</div>
    <div class="hui">
      <div class="xian"></div>
    </div>
    <div class="article_list">
      <div
        class="list_item"
        v-for="(item, index) in listdata"
        :key="index"
        @click="goinfo(item)"
      >
        <div class="item_left">
          <img class="img" :src="item.cover" alt="" />
        </div>
        <div class="item_right">
          <div class="item_title">{{ item.title }}</div>
          <div class="item_tearr">
            {{ item.description }}
          </div>
          <div class="item_time">
            <div class="red pcbig"></div>
            <div class="text">{{ item.name }}</div>
            <div class="shu pcbig">|</div>
            <div class="time">{{ item.created_at }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="more" v-if="moreshow">
      <div class="more_cen" @click="page_turning">
        <div class="cen_text">加载更多文章…</div>
        <div class="cenicon">
          <img class="img" src="../../assets/icon/xiah_djt1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="search_noyer" v-if="listdata.length == 0">
      <img class="img" src="../../assets/img/nodata.png" alt="" />
      <div class="noyer_text">暂无搜索结果</div>
    </div>
    <upward></upward>
    <buton></buton>
  </div>
</template>
    
    <script>
import { articlesgetIndex } from "@/api/search";
export default {
  data() {
    return {
      inptext: "",
      category_id: "",
      listdata: [],
      page: 1,
      moreshow: true,
    };
  },
  created() {
    window.scrollTo({
      top: 0,
    });
  },
  methods: {
    handleChange(value) {},
    seceinpt(e) {
      if (e.keyCode == 13) {
        this.init();
      }
    },
    seceinptow() {
      this.init();
    },
    goinfo(item) {
      this.$router.push({ path: "info", query: { id: item.id } });
    },
    page_turning() {
      this.page++;
      this.init();
    },
    init() {
      articlesgetIndex({
        title: this.inptext,
        category_id: this.category_id,
        limit: "8",
        page: this.page,
      })
        .then((res) => {
          console.log(res);
          if (this.page == 1) {
            this.listdata = res.data.data.data;
            if (this.listdata.length <= 6) {
              this.moreshow = false;
            }
          } else {
            this.listdata = this.listdata.concat(res.data.data.data);
          }
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
    },
  },
  created() {
    this.category_id = this.$route.query.userId;
    this.inptext = this.$route.query.sease;
    this.init();
  },
};
</script>
    <style lang="scss" scoped>
.home {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.pcbig {
  display: block;
}
.pcmin {
  display: none;
}
/deep/.el-carousel__container {
  height: auto !important;
}
.search_noyer {
  padding: 0 120px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 61px 0px 48px;
  .img {
    /* prettier-ignore */
    width: 158pX;
    /* prettier-ignore */
    height: 103pX;
  }
  .noyer_text {
    /* prettier-ignore */
    font-size: 14pX;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 23px;
  }
}
.more {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0 54px;
  .more_cen {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .cen_text {
      margin-bottom: 6px;
    }
    .cenicon {
      .img {
        width: 19px;
        height: 13px;
      }
    }
  }
}
.article_list {
  padding: 0 120px;
  box-sizing: border-box;
  .list_item:first-child {
    padding-top: 0;
  }
  .list_item {
    cursor: pointer;
    display: flex;
    justify-content: start;
    padding: 20px 0;
    border-bottom: 1px solid #f6f6f6;
    .item_left {
      margin-right: 20px;
      .img {
        /* prettier-ignore */
        width: 266pX;
        /* prettier-ignore */
        height: 150pX;
      }
    }
    .item_right {
      .item_title {
        text-align: left;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 1;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 1; // 超出多少行
        -webkit-box-orient: vertical;
        /* prettier-ignore */
        margin-top: 10pX;
        /* prettier-ignore */
        font-size: 20pX;
      }
      .item_tearr {
        text-align: left;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        margin-top: 6px;
        margin-bottom: 10px;
        /* prettier-ignore */
        height: 50pX;
        /* prettier-ignore */
        line-height: 25pX;
        overflow: hidden; //超出的文本隐藏
        display: -webkit-box;
        -webkit-line-clamp: 2; // 超出多少行
        -webkit-box-orient: vertical;
        /* prettier-ignore */
        font-size: 12pX;
      }
      .item_time {
        display: flex;
        justify-content: start;
        align-items: center;

        .text {
          /* prettier-ignore */
          font-size: 14pX;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
        }
        .red {
          width: 6px;
          height: 6px;
          background: #c32227;
          border-radius: 50px;
          margin-right: 10px;
        }
        .shu {
          padding: 0 20px;
          color: #999999;
          /* prettier-ignore */
          font-size: 14pX;
        }
        .time {
          /* prettier-ignore */
          font-size: 14pX;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
.hui {
  padding: 0 120px;
  box-sizing: border-box;
  .xian {
    width: 1200px;
    background: #f6f6f6;
    height: 1px;
    margin: 18px 0 23px;
  }
}
.seacr_title {
  padding: 0 120px;
  box-sizing: border-box;
  /* prettier-ignore */
  font-size: 16pX;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  text-align: left;
  margin-top: 40px;
}
.seacrinp {
  padding: 0 120px;
  box-sizing: border-box;
  margin-top: 40px;
  display: flex;
  .leftinpt {
    width: 590px;
    height: 50px;
    background: #f6f6f6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* prettier-ignore */
    padding-left: 20pX;
    box-sizing: border-box;
    box-sizing: border-box;
    .inp {
      flex: 1;
      /* prettier-ignore */
      font-size: 16pX;
      height: 50px;
      border: none;
      background: #f6f6f6;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      outline: none;
    }
    .secaerimg {
      width: 90px;
      height: 50px;
      background: #c32227;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      /* prettier-ignore */
      font-size: 16pX;
      color: #fff;
    }
  }
  .rightinput {
    width: 360px;
    height: 67px;
    background: #c32227;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    .increase {
      width: 29px;
      height: 29px;
      margin-right: 15px;
    }
  }
}
@media screen and (max-width: 1280px) {
  .article_list {
    .list_item {
      padding: 40px 0 !important;
    }
    .item_tearr {
      /* prettier-ignore */
      margin-bottom: 21pX !important;
    }
    .item_time {
      flex-direction: column;
      align-items: start !important;
    }
  }
  .pcbig {
    display: none !important;
  }
  .pcmin {
    display: block !important;
  }
}
@media screen and (max-width: 1000px) {
  .pcbig {
    display: none !important;
  }
  .pcmin {
    display: block !important;
  }
  .more {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px 0;
    .more_cen {
      .cen_text {
        font-size: 50px !important;
      }
      .cenicon {
        .img {
          width: 59px !important;
          height: 43px !important;
        }
      }
    }
  }
  .seacr_title {
    padding: 0 50px !important;
  }
  .article_list {
    padding: 0 50px !important;
    box-sizing: border-box;
    margin-top: 28px;
    .list_item:first-child {
      padding-top: 0;
    }
    .list_item {
      cursor: pointer;
      display: flex;
      justify-content: start;
      padding: 20px 0;
      border-bottom: 1px solid #f6f6f6;
      .item_left {
        margin-right: 30px;
        .img {
          width: 530px !important;
          height: 380px !important;
        }
      }
      .item_right {
        .item_title {
          font-size: 60px !important;
          height: 60px;
          margin-top: 20px !important;
        }
        .item_tearr {
          font-size: 48px !important;
          height: 100px !important;
          line-height: 50px !important;
          margin-top: 20px !important;
          margin-bottom: 0px !important;
        }
        .item_time {
          display: flex;
          justify-content: start;
          align-items: center;

          .text {
            font-size: 38px !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
          }
          .red {
            width: 6px;
            height: 6px;
            background: #c32227;
            border-radius: 50px;
            margin-right: 10px;
          }
          .shu {
            padding: 0 20px;
            color: #999999;
            font-size: 38px !important;
          }
          .time {
            font-size: 38px !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
  .seacrinp {
    padding: 0 50px !important;
    flex-direction: column;
    align-items: start !important;
    .leftinpt {
      width: 100%;
      height: 200px;
      border-radius: 4px;
      padding-left: 20px;
      margin-top: 40px;
      .inp {
        flex: 1;
        height: 200px;
        font-size: 60px;
      }
      .secaerimg {
        width: 370px;
        height: 210px;
        font-size: 70px !important;
        border-radius: 15px;
      }
    }
    .rightinput {
      font-size: 80px;
    }
  }
}
</style>
    