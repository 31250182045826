/*
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-05-11 16:23:50
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-17 15:19:08
 * @FilePath: \my-project\src\api\logoin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 引用封装的 request
import request from '@/js/request'

//峰会列表
export const meetingmeeting = (data) => {
    return request({
        url: "/api/meeting/getList",
        method: "POST",
        data, 
    });
};

//峰会详情
export const meetingdetail = (data) => {
    return request({
        url: "/api/meeting/detail",
        method: "POST",
        data, 
    });
};

//我的会议
export const meetingmy = (data) => {
    return request({
        url: "/api/meeting/my",
        method: "POST",
        data, 
    });
};

//提交会议
export const meetingsubmit = (data) => {
    return request({
        url: "/api/meeting/sign_up/submit",
        method: "POST",
        data, 
    });
};

//门票价格
export const meetingticket = (data) => {
    return request({
        url: "/api/meeting/ticket/getList",
        method: "POST",
        data, 
    });
};


//获取报名列表
export const sign_upgetList = (data) => {
    return request({
        url: "/api/meeting/sign_up/getList",
        method: "POST",
        data, 
    });
};

//获取报名信息
export const sign_upgetInfo = (data) => {
    return request({
        url: "/api/meeting/sign_up/getInfo",
        method: "POST",
        data, 
    });
};

//获取报名详情
export const sign_upgetdetail = (data) => {
    return request({
        url: "/api/meeting/sign_up/detail",
        method: "POST",
        data, 
    });
};
