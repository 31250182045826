<template>
  <div class="home">
    <topnar @change="handleChange($event)"></topnar>
    <!-- <div class="seacrinp">
      <div class="leftinpt">
        <input class="inp" placeholder="请输入您想搜索的内容…" type="text" />
        <div class="secaerimg">
          <img class="img" src="../../assets/icon/sous_ssss.png" alt="" />
        </div>
      </div>
    </div> -->
    <div class="artice">
      <div class="article_title">{{ data.title }}</div>
      <div class="article_info">
        {{ data.description }}
      </div>
      <div class="xian"></div>
      <div class="article_time">
        <div>{{ data.created_at }}</div>
        <div class="shu">|</div>
        <div>{{ data.name }}</div>
        <div class="shu">|</div>
        <div class="share" @click="openwx">
          分享到
          <img
            class="shareimg"
            src="../../assets/icon/weix_xxx1@2x.png"
            alt=""
          />
        </div>
      </div>
      <div class="arice_cent">
        <div @click="showImg($event)" v-html="data.content"></div>
      </div>
      <div class="xian"></div>
      <div class="recommend">
        <div class="recommend_title">每日｜荐读</div>
        <div class="recommend_list">
          <div class="item" v-for="(item, index) in readdata" :key="index">
            <div class="treetop">
              {{ item.type == 1 ? "榜单" : "" }}
              {{ item.type == 2 ? "峰会" : "" }}
              {{ item.type == 3 ? "热文" : "" }}
            </div>
            <div
              class="item_text"
              v-if="item.type != 2"
              @click="idcelse(item.id, item.type)"
            >
              {{ item.title }}
            </div>
            <div
              class="item_text"
              v-if="item.type == 2"
              @click="golastde(item.id)"
            >
              {{ item.title }}
            </div>
          </div>
          <!-- <div class="item">
            <div class="treetop">峰会</div>
            <div class="item_text" @click="idcelse(readdataonde.id)">
              {{ readdataonde.title }}
            </div>
          </div>
          <div class="item">
            <div class="treetop">热文</div>
            <div class="item_text" @click="idcelse(readdatatherr.id)">
              {{ readdatatherr.title }}
            </div>
          </div> -->
        </div>
      </div>
      <div class="showlsit">
        <div class="showtitle">推荐阅读</div>
        <div class="showlist">
          <div
            class="showitem"
            v-for="(item, index) in recommenddata"
            :key="index"
            @click="idcelse(item.id)"
          >
            <img class="img" :src="item.cover" alt="" />
            <div class="itemtitle">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wxshow" v-if="wxshow == true">
      <div class="wxtitle">分享到微信</div>
      <!-- <img  src="../../assets/img/erwm_mm.png" alt="" /> -->
      <div class="wximg" id="qrcode" ref="qrCodeDiv"></div>
      <div class="wxtips">打开微信-扫一扫</div>
      <div class="wxtips">即可将网页分享到微信号</div>
      <img
        class="iconimg"
        @click="closhow"
        src="../../assets/icon/guan_x.png"
        alt=""
      />
    </div>
    <div class="wxblock" v-if="wxshow == true"></div>
    <upward></upward>
    <buton></buton>
  </div>
</template>
    
    <script>
import {
  articlesgetDetail,
  articlesread,
  articlesgetRecommend,
} from "@/api/info";
import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      wxshow: false,
      id: "",
      data: {},
      recommenddata: {},
      readdata: {},
    };
  },
  mounted() {
    window.scrollTo({
      top: 0,
    });
    this.$nextTick(function () {
      //这里写方法
      this.qrcode();
    });
  },
  watch: {
    $route: "fetchData",
    wxshow: function () {
      //materielId为需要监听的data
      this.$nextTick(function () {
        if (this.wxshow) {
          console.log("111111111111111111111111111111111111");
          //这里写方法
          this.qrcode();
        }
      });
    },
  },
  methods: {
    openwx() {
      this.wxshow = true;
    },
    qrcode() {
      // let long = document.documentElement.clientWidth*0.39;
      // console.log(window.location,'---------------------')
      var width = window.innerWidth;
      if (width < 1000) {
        new QRCode("qrcode", {
          text: window.location.href, //需要转换为二维码的内容
          width: "130", //宽
          height: "130", //高
        });
      } else {
        new QRCode("qrcode", {
          text: window.location.href, //需要转换为二维码的内容
          width: "180", //宽
          height: "180", //高
        });
      }
    },
    closhow() {
      this.wxshow = false;
    },
    fetchData() {
      window.scrollTo({
        top: 0,
      });
      this.id = this.$route.query.id;
      this.init();
    },
    golastde(val) {
      this.$router.push({ path: "summitdeilt", query: { id: val } });
    },
    goinfo(item) {
      this.$router.push({ path: "info", query: { id: item.id } });
    },
    showImg(e) {
      console.log(e, "--------------");
      if (e.target.tagName == "IMG") {
        this.imgPreview.img = e.target.src;
        this.imgPreview.show = true;
      }
    },
    init() {
      articlesgetDetail({
        id: this.id,
      })
        .then((res) => {
          this.data = res.data.data;
          const link = location.href;
          this.$wxShare.updateWxShareConfig({
            title: this.data.title,
            desc: this.data.description,
            link: link,
          });
          this.data.content = this.data.content.replace(
            /(tabindex="(.*?)")/gi,
            "tabindex"
          );
          // this.data.content = this.data.content.replace(
          //   /\<p/gi,
          //   "<p style='overflow-wrap: break-word;'"
          // );
          // this.data.content = this.data.content.replace(
          //   /\<section/gi,
          //   "<p style='overflow-wrap: break-word;'"
          // );
          // this.data.content = this.data.content.replace(
          //   /\<a/gi,
          //   "<a style='overflow-wrap: break-word;'"
          // );
          this.data.content = res.data.data.content.replace(
            /\<img/gi,
            '<img style="max-width:100%;height:auto"  '
          );
          // this.data.content = res.data.data.content.replace(
          //   /\<span/gi,
          //   '<span style="white-space: pre-wrap;" '
          // );
          this.data.content = res.data.data.content.replace(/\<a/gi, "<b ");
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
      //推荐阅读
      articlesread().then((res) => {
        console.log(res);
        this.recommenddata = res.data.data;
      });

      //每日荐读
      articlesgetRecommend().then((res) => {
        this.readdata = res.data.data;
      });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.init();
  },
};
</script>
    <style lang="scss" scoped>
.home {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.pcbig {
  display: block;
}
.pcmin {
  display: none;
}
/deep/.el-carousel__container {
  height: auto !important;
}

.article_list {
  padding: 0 160px;
  box-sizing: border-box;
  margin-top: 28px;
  .list_item:first-child {
    padding-top: 0;
  }
  .list_item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    border-bottom: 1px solid #f6f6f6;
    .item_left {
      margin-right: 20px;
      .img {
        width: 355px;
        height: 200px;
      }
    }
    .item_right {
      .item_title {
        text-align: left;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 1;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 1; // 超出多少行
        -webkit-box-orient: vertical;
      }
      .item_tearr {
        text-align: left;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        margin-top: 13px;
        margin-bottom: 38px;
        line-height: 1;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 2; // 超出多少行
        -webkit-box-orient: vertical;
      }
      .item_time {
        display: flex;
        justify-content: start;
        align-items: center;

        .text {
          /* prettier-ignore */
          font-size: 14pX;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
        }
        .red {
          width: 6px;
          height: 6px;
          background: #c32227;
          border-radius: 50px;
          margin-right: 10px;
        }
        .shu {
          padding: 0 20px;
          color: #999999;
          font-size: 14px;
        }
        .time {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
.wxshow {
  /* prettier-ignore */
  width: 350pX;
  /* prettier-ignore */
  height: 350pX;
  background: #ffffff;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  /* prettier-ignore */
  margin-top: -175pX;
  /* prettier-ignore */
  margin-left: -175pX;
  z-index: 98;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .iconimg {
    /* prettier-ignore */
    width: 15pX;
    /* prettier-ignore */
    height: 14pX;
    position: absolute;
    /* prettier-ignore */
    right: 23pX;
    /* prettier-ignore */
    top: 23pX;
    cursor: pointer;
  }
  .wxtitle {
    /* prettier-ignore */
    font-size: 20pX;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }
  .wximg {
    /* prettier-ignore */
    width: 170pX;
    /* prettier-ignore */
    height: 170pX;
    margin: 38px 0px 29px;
  }
  .wxtips {
    /* prettier-ignore */
    font-size: 14pX;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
  }
}
.wxblock {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 97;
}
.artice {
  text-align: left;
  padding: 0 120px;
  box-sizing: border-box;
  margin-top: 44px;
  .article_title {
    /* prettier-ignore */
    font-size: 20pX;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
  }
  .article_info {
    margin-top: 17px;
    /* prettier-ignore */
    font-size: 14pX;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
  }
  .xian {
    background: #f6f6f6;
    height: 1px;
    margin-top: 35px;
  }
  .showlsit {
    .showtitle {
      /* prettier-ignore */
      font-size: 14pX;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #c32227;
      margin: 35px 0px 12px;
    }
    .showlist {
      display: flex;
      margin-bottom: 80px;
      .showitem {
        margin-right: 20px;
        cursor: pointer;
        .img {
          width: 289px;
          height: 163px;
        }
        .itemtitle {
          width: 289px;
          /* prettier-ignore */
          font-size: 14pX;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          display: -webkit-box;
          -webkit-line-clamp: 1; // 超出多少行
          -webkit-box-orient: vertical;
          margin-top: 7px;
        }
      }
    }
  }
  .recommend {
    margin-top: 30px;
    .recommend_title {
      /* prettier-ignore */
      font-size: 14pX;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #c32227;
    }
    .recommend_list {
      margin-top: 20px;
      .item {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 11px;
        cursor: pointer;
        .treetop {
          /* prettier-ignore */
          width: 38pX;
          /* prettier-ignore */
          height: 18pX;
          background: #c32227;
          /* prettier-ignore */
          border-radius: 3pX;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          /* prettier-ignore */
          font-size: 12pX;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          margin-right: 16px;
        }
        .item_text {
          /* prettier-ignore */
          font-size: 14pX;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
        }
        .item_text:hover {
          color: #c32227;
        }
      }
      .item:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .arice_cent {
    margin-top: 30px;
    /* prettier-ignore */
    font-size: 14pX;
  }
  .article_time {
    display: flex;
    justify-content: start;
    align-items: center;
    /* prettier-ignore */
    font-size: 14pX;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    margin-top: 20px;
    .shu {
      margin: 0 20px;
    }
    .share {
      display: flex;
      align-items: center;
      .shareimg {
        /* prettier-ignore */
        width: 22pX;
        /* prettier-ignore */
        height: 22pX;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
}
.seacrinp {
  padding: 0 120px;
  box-sizing: border-box;
  margin-top: 40px;
  .leftinpt {
    width: 590px;
    height: 50px;
    background: #f6f6f6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    box-sizing: border-box;
    .inp {
      flex: 1;
      height: 50px;
      border: none;
      /* prettier-ignore */
      font-size: 16pX;
      background: #f6f6f6;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      outline: none;
    }
    .secaerimg {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        /* prettier-ignore */
        width: 21pX;
        /* prettier-ignore */
        height: 20pX;
        cursor: pointer;
      }
    }
  }
  .rightinput {
    width: 360px;
    height: 67px;
    background: #c32227;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #ffffff;
    .increase {
      width: 29px;
      height: 29px;
      margin-right: 15px;
    }
  }
}
@media screen and (max-width: 1280px) {
  .pcbig {
    display: none !important;
  }
  .pcmin {
    display: block !important;
  }
}
@media screen and (max-width: 1000px) {
  .pcbig {
    display: none !important;
  }
  .pcmin {
    display: block !important;
  }
  .wxshow {
    width: 1000px;
    height: 1000px;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    margin-top: -500px;
    margin-left: -500px;
    .iconimg {
      width: 51px;
      height: 49px;
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
    }
    .wxtitle {
      font-size: 60px !important;
    }
    .wximg {
      width: 500px !important;
      height: 500px !important;
    }
    .wxtips {
      font-size: 60px !important;
    }
  }
  .showlsit {
    margin-top: 100px !important;
    .showtitle {
      font-size: 60px !important;
      margin-bottom: 20px !important;
    }
    .showlist {
      .showitem {
        .img {
          width: 315px !important;
          height: 187px !important;
          border-radius: 10px !important;
        }
        .itemtitle {
          width: 315px !important;
          font-size: 40px !important;
        }
      }
    }
  }
  .recommend {
    margin-top: 60px !important;
    .recommend_title {
      font-size: 60px !important;
    }
    .recommend_list {
      .item {
        margin-top: 40px !important;
        .treetop {
          width: 150px !important;
          height: 80px !important;
          border-radius: 3px !important;
          font-size: 50px !important;
        }
        .item_text {
          font-size: 50px !important;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          display: -webkit-box;
          -webkit-line-clamp: 1; // 超出多少行
          -webkit-box-orient: vertical;
          flex: 1;
        }
      }
    }
  }
  .artice {
    padding: 0 50px !important;
    margin-top: 100px !important;
    .article_title {
      font-size: 70px !important;
    }
    .article_info {
      font-size: 52px !important;
      margin-top: 40px !important;
    }
    .article_time {
      font-size: 50px !important;
      .share {
        .shareimg {
          width: 80px;
          height: 80px;
          cursor: pointer;
          margin-left: 13px;
        }
      }
    }
    .arice_cent {
      font-size: 48px !important;
      margin-top: 50px !important;
    }
  }
  .seacrinp {
    padding: 0 50px !important;
    margin-top: 60px !important;
    .leftinpt {
      width: 100%;
      height: 200px;
      .inp {
        font-size: 60px;
      }
      .secaerimg {
        width: 200px !important;
        height: 200px !important;
        .img {
          width: 80px !important;
          height: 80px !important;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
    