<template>
  <div class="summit_Report">
    <topnar nar="7"></topnar>
    <div class="Report_cent">
      <div class="success_form">
        <img
          class="sucicon"
          src="../../assets/img/zhifu_che_wa@2x.png"
          alt=""
        />
        <div class="success_text">支付成功</div>
        <div class="success_oneci">恭喜您，参会成功</div>
        <div class="success_towci">请在2023年11月06日参会</div>
        <div class="success_tips">{{ second }}S后回到峰会详情</div>
        <div class="success_but">回到峰会</div>
      </div>
    </div>
    <upward></upward>
    <buton></buton>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      second: 2,
    };
  },
  created() {
    this.phonetype = this.IsPc();
    this.timere();
    console.log('----------------')
  },
  methods: {
    timere() {
      this.second = 2;
      let _this = this;
      var timerr = setInterval(function () {
        if (_this.second === 0) {
          clearInterval(timerr);
          _this.goindex();
        } else {
          _this.second--;
        }
      }, 1000);
    },
    goindex() {
        this.$router.go(-2);
    },
    IsPc() {
      let userAgent = navigator.userAgent,
        Agents = [
          "Android",
          "iPhone",
          "SymbianOS",
          "Windows Phone",
          "iPad",
          "iPod",
        ];
      console.log("userAgent:", userAgent);
      return Agents.some((i) => {
        return userAgent.includes(i);
      });
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.summit_Report {
  width: 100%;
  min-height: 100vh;
  position: relative;
  .Report_cent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 110px;
    .success_form {
      width: 1200px;
      border-radius: 0px 0px 4px 4px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 98px;
      .sucicon {
        width: 51px;
        height: 51px;
      }
      .success_text {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin: 24px 0 17px;
      }
      .success_oneci {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 9px;
      }
      .success_towci {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 31px;
      }
      .success_tips {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .success_but {
        width: 200px;
        height: 32px;
        background: #c32227;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .payment_die {
    width: 100%;
    height: 120px;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .wx_pop {
    width: 400px;
    height: 400px;
    background: #ffffff;
    position: fixed;
    top: 50%;
    z-index: 99;
    margin-top: -200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: fade-in 0.2s ease-in-out;
    border-radius: 20px;
    .right_icon {
      width: 15px;
      height: 15px;
      right: 15px;
      top: 15px;
      position: absolute;
      cursor: pointer;
    }
    .opo_title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      margin-bottom: 30px;
      .wx_img {
        margin-right: 20px;
        width: 30px;
        height: 24px;
      }
    }
    .qrimg {
      width: 200px;
      height: 200px;
    }
  }
  .payment_subimt {
    width: 100%;
    height: 120px;
    background: #fbfbfb;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0px;

    .submit {
      width: 180px;
      height: 60px;
      background: #c32227;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 33px;
      margin-left: 57px;
      font-size: 20px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
    .payment_num {
      font-size: 16px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 20px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
      }
    }
  }
  .order_lsit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .oder_type {
      width: 1200px;
      height: 137px;
      background: #fbfbfb;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 23px 26px;
      box-sizing: border-box;
      .type_list {
        display: flex;
        justify-content: center;
        align-items: center;
        .type_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 235px;
          height: 58px;
          background: rgb(240, 240, 240);
          border-radius: 4px;
          font-size: 16px;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          padding: 0 16px;
          box-sizing: border-box;
          margin-right: 14px;
          cursor: pointer;
          .type_text {
            display: flex;
            justify-content: center;
            align-items: center;
            .wx_img {
              width: 30px;
              height: 24px;
              margin-right: 9px;
            }
            .zfb_img {
              width: 30px;
              height: 30px;
              margin-right: 9px;
            }
          }
          .drop {
            width: 20px;
            height: 20px;
            background: #aeaeae;
            border-radius: 50px;
            box-sizing: border-box;
          }
          .active_drop {
            background: #ffffff !important;
            border: 7px solid #c32227;
          }
        }
      }
      .type_title {
        font-size: 14px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 18px;
      }
    }
    .oder_meet {
      width: 1200px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 32px;
      font-size: 20px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      .meet_num {
        font-size: 20px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
        margin-left: 13px;
      }
    }
    .order_li {
      width: 1200px;
      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
    }
    .order_item:last-child .item_pad {
      border-bottom: none !important;
    }
    .order_item {
      width: 100%;
      background: #fbfbfb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 26px;
      box-sizing: border-box;
      .item_pad {
        height: 53px;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .head_left {
          display: flex;
          justify-content: center;
          align-items: center;
          .head_num {
            min-width: 180px;
            text-align: left !important;
          }
          .head_package {
            margin-left: 50px;
          }
        }
        .head_right {
          .amount_num {
            font-size: 14px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            color: #c32227;
          }
        }
      }
    }
    .order_head {
      width: 100%;
      background: #f0f0f0;
      height: 43px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 26px;
      box-sizing: border-box;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      .head_left {
        display: flex;
        justify-content: center;
        align-items: center;
        .head_num {
          min-width: 180px;
          text-align: left !important;
        }
        .head_package {
          margin-left: 50px;
        }
      }
    }
  }
  .payment {
    .payment_cent {
      width: 708px;
      height: 292px;
      background: #f9f9f9;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 91px;
      box-sizing: border-box;
      margin-top: 37px;
      .xian {
        width: 1px;
        height: 200px;
        background: #e4e4e4;
      }
      .paymentimg {
        width: 156px;
        height: 156px;
      }
      .payment_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .left_title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          margin-bottom: 32px;
        }
        .payment_wx {
          width: 30px;
          height: 24px;
          margin-right: 13px;
        }
      }
      .payment_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .right_title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          margin-bottom: 32px;
          .payment_zfb {
            width: 30px;
            height: 30px;
            margin-right: 13px;
          }
        }
      }
    }
  }
  .purchase_but {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 46px;
    .purchase_cent {
      width: 200px;
      height: 32px;
      background: #c32227;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .purchase_list {
    .purchase_active {
      background: #c32227 !important;
      .item_title {
        .title_left {
          color: #ffffff !important;
        }
        .title_right {
          background: #ffffff !important;
        }
      }
      .item_money {
        color: #ffffff !important;
      }
      .item_introduction {
        color: #ffffff !important;
      }
    }
    .purchase_item {
      width: 1200px;
      height: 164px;
      background: rgb(251, 251, 251);
      border-radius: 4px;
      padding: 24px;
      box-sizing: border-box;
      margin-bottom: 30px;
      cursor: pointer;
      .item_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #ffffff;
        .title_left {
          font-size: 20px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
        }
        .title_right {
          width: 20px;
          height: 20px;
          background: #e4e4e4;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          .rimg {
            width: 12px;
            height: 12px;
          }
        }
      }
      .item_money {
        margin-top: 16px;
        width: 100%;
        font-size: 20px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
        text-align: left;
      }
      .item_introduction {
        margin-top: 16px;
        width: 100%;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        text-align: left;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .payment {
    .payment_cent {
      width: 1200px !important;
      height: 800px !important;
      background: #f9f9f9;
      border-radius: 20px !important;
      display: flex;
      justify-content: space-between;
      padding: 0 91px;
      box-sizing: border-box;
      margin-top: 50px;
      .xian {
        width: 3px !important;
        height: 500px !important;
        background: #e4e4e4;
      }
      .paymentimg {
        width: 400px !important;
        height: 400px !important;
      }
      .payment_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .left_title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 60px !important;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          margin-bottom: 32px;
        }
        .payment_wx {
          width: 90px !important;
          height: 72px !important;
          margin-right: 13px;
        }
      }
      .payment_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .right_title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 60px !important;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          margin-bottom: 32px;
          .payment_zfb {
            width: 90px !important;
            height: 90px !important;
            margin-right: 13px;
          }
        }
      }
    }
  }
  .purchase_but {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 46px;
    .purchase_cent {
      width: 750px !important;
      height: 120px !important;
      background: #c32227;
      border-radius: 10px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 45px;
      font-size: 50px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .purchase_list {
    width: 100%;
    padding: 0 30px !important;
    box-sizing: border-box;
    .purchase_active {
      background: #c32227 !important;
      .item_title {
        .title_left {
          color: #ffffff !important;
        }
        .title_right {
          background: #ffffff !important;
        }
      }
      .item_money {
        color: #ffffff !important;
      }
      .item_introduction {
        color: #ffffff !important;
      }
    }
    .purchase_item {
      width: 100% !important;
      height: auto !important;
      background: rgb(251, 251, 251);
      border-radius: 20px !important;
      padding: 24px;
      box-sizing: border-box;
      margin-bottom: 30px;
      .item_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 40px !important;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #ffffff;
        .title_left {
          font-size: 50px !important;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
        }
        .title_right {
          width: 80px !important;
          height: 80px !important;
          background: #e4e4e4;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          .rimg {
            width: 40px !important;
            height: 40px !important;
          }
        }
      }
      .item_money {
        margin-top: 16px;
        width: 100%;
        font-size: 50px !important;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
        text-align: left;
      }
      .item_introduction {
        margin-top: 16px;
        width: 100%;
        font-size: 40px !important;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        text-align: left;
      }
    }
  }
  .payment_die {
    width: 100%;
    height: 200px !important;
  }
  .wx_pop {
    width: 800px !important;
    height: 800px !important;
    background: #ffffff;
    position: fixed;
    top: 50%;
    z-index: 99;
    margin-top: -400px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: fade-in 0.2s ease-in-out;
    border-radius: 20px;
    .right_icon {
      width: 45px !important;
      height: 45px !important;
      right: 25px !important;
      top: 25px !important;
      position: absolute;
      cursor: pointer;
    }
    .opo_title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 60px !important;
      margin-bottom: 50px !important;
      .wx_img {
        margin-right: 20px;
        width: 60px !important;
        height: 48px !important;
      }
    }
    .qrimg {
      width: 400px !important;
      height: 400px !important;
    }
  }
  .payment_subimt {
    width: 100%;
    height: 300px !important;
    background: #fbfbfb;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    position: absolute;
    padding: 0 80px;
    box-sizing: border-box;
    bottom: 0px;

    .submit {
      width: 700px !important;
      height: 180px !important;
      background: #c32227;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0 !important;
      margin-left: 57px;
      font-size: 60px !important;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
    }
    .payment_num {
      font-size: 40px !important;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 60px !important;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
      }
    }
  }
  .order_lsit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 30px;
    box-sizing: border-box;
    .oder_type {
      width: 100% !important;
      height: auto !important;
      background: #fbfbfb;
      border-radius: 4px;
      display: flex;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: column;
      padding: 140px 26px !important;
      box-sizing: border-box;
      .type_list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .type_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 1000px !important;
          height: 200px !important;
          background: #e6e6e6;
          border-radius: 20px !important;
          font-size: 50px !important;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          padding: 0 40px !important;
          box-sizing: border-box;
          margin-right: 30px !important;
          margin-bottom: 40px !important;
          .type_text {
            display: flex;
            justify-content: center;
            align-items: center;
            .wx_img {
              width: 90px !important;
              height: 72px !important;
              margin-right: 30px !important;
            }
            .zfb_img {
              width: 90px !important;
              height: 90px !important;
              margin-right: 30px !important;
            }
          }
          .drop {
            width: 50px !important;
            height: 50px !important;
            background: #aeaeae;
            border-radius: 50px;
            box-sizing: border-box;
          }
          .active_drop {
            background: #ffffff !important;
            border: 17px solid #c32227 !important;
          }
        }
      }
      .type_title {
        font-size: 60px !important;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 18px;
      }
    }
    .oder_meet {
      width: 100% !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 32px;
      font-size: 60px !important;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      .meet_num {
        font-size: 70px !important;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        color: #c32227;
        margin-left: 40px !important;
      }
    }
    .order_li {
      width: 100% !important;
      font-size: 42px !important;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
    }
    .order_item:last-child .item_pad {
      border-bottom: none !important;
    }
    .order_item {
      width: 100%;
      background: #fbfbfb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 26px;
      box-sizing: border-box;
      .item_pad {
        height: 153px !important;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .head_left {
          display: flex;
          justify-content: center;
          align-items: center;
          .head_num {
            min-width: 400px !important;
            text-align: left !important;
          }
          .head_package {
            margin-left: 50px;
          }
        }
        .head_right {
          .amount_num {
            font-size: 50px !important;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            color: #c32227;
          }
        }
      }
    }
    .order_head {
      width: 100%;
      background: #f0f0f0;
      height: 143px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 26px;
      box-sizing: border-box;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      .head_left {
        display: flex;
        justify-content: center;
        align-items: center;
        .head_num {
          min-width: 400px !important;
          text-align: left !important;
        }
        .head_package {
          margin-left: 50px;
        }
      }
    }
  }
  .Report_cent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 49px;
    width: 100% !important;
    .success_form {
      width: 100% !important;
      border-radius: 0px 0px 4px 4px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 198px !important;
      .sucicon {
        width: 180px !important;
        height: 180px !important;
      }
      .success_text {
        font-size: 80px !important;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin: 24px 0 17px;
      }
      .success_oneci {
        font-size: 60px !important;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 9px;
      }
      .success_towci {
        font-size: 60px !important;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 31px;
      }
      .success_tips {
        font-size: 50px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .success_but {
        width: 750px !important;
        height: 120px !important;
        background: #c32227;
        border-radius: 10px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 70px !important;
        font-size: 50px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>