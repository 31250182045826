<!--
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-07-20 16:39:03
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-23 09:45:14
 * @FilePath: \my-project\src\components\buttonlin.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="buton">
      <!-- <div class="linklist">
        <div class="lin_title">友情链接</div>
        <div class="inlist">
          <div
            class="in_item"
            @click="gowb(item.link_to)"
            v-for="(item, index) in dataliast"
            :key="index"
          >
            <div v-if="index != 0" style="padding: 0 10px; color: #999999">
              |
            </div>
            {{ item.title }} >
          </div>
        </div>
      </div>  -->
      <div class="buback">
        <img class="img" src="../assets/img/bai_dL_o.png" alt="" />
        <div class="company_Profile">
          FOFWEEKLY(北京中基辰域科技有限公司)自2017年创办以来，
          现已发展成为中国私募股权机构投资者专业服务平台，基于中国本土
          化优势，秉持独立、专业理念，以正心、卓越、创新、共赢为核心价
          值观，依托深度内容、精准社群、专业服务，推动机构投资者效率提
          升、为股权投资行业可持续发展赋能。
          FOFWEEKLY先后开展垂直专业内容、社群服务、专业咨询服务
          等多元化业务，以立足中国面向全球的布局，为行业搭建开放务实的
          业务交流网络，助力卓越投资机构全球发展。
        </div>
        <div class="code">
          京ICP备18015532号-1 © 版权所有:FOF周刊保留所有权利 | 条款
        </div>
      </div>
    </div>
    <div class="die"></div>
  </div>
</template>

<script>
import { cmsSiteLinks } from "@/api/bottom";
export default {
  data() {
    return {
      dataliast: [],
    };
  },
  methods: {
    gowb(hefr) {
      const w = window.open("about:blank");
      w.location.href = hefr;
    },
    init() {
      cmsSiteLinks({})
        .then((res) => {
          console.log(res);
          this.dataliast = res.data.data.data;
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped lang="scss">
.die {
  /* prettier-ignore */
  height: 336pX;
}
.pcbig {
  display: block;
}
.pcmin {
  display: none;
}
.buton {
  position: absolute;
  bottom: 0px;
  .buback {
    width: 100%;
    /* prettier-ignore */
    height: 336pX;
    padding: 0 120px;
    box-sizing: border-box;
    background: #343740;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    .company_Profile {
      text-align: left;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      margin-bottom: 14px;
    }
    .code {
      text-align: left;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #cdcdcd;
      font-size: 14px;
    }
    .img {
      width: 146px;
      height: 38px;
      margin-bottom: 18px;
    }
  }
  .linklist {
    width: 100%;
    /* prettier-ignore */
    height: 100pX;
    padding: 0 120px;
    box-sizing: border-box;
    background: #f5f6f9;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    .lin_title {
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      margin-bottom: 18px;
      /* prettier-ignore */
      font-size: 16pX;
    }
    .inlist {
      display: flex;
      justify-content: center;
      align-items: center;
      .in_item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        /* prettier-ignore */
        font-size: 14pX;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
      }
      .in_item:hover {
        color: #c32227;
        text-decoration: underline;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .die {
    height: 1300px !important;
  }
  .pcbig {
    display: none !important;
  }

  .pcmin {
    display: block !important;
  }
  .buback {
    padding: 0 50px !important;
    width: 100%;
    height: 1200px !important;

    .company_Profile {
      font-size: 50px;
      margin-bottom: 50px !important;
    }
    .code {
      font-size: 40px !important;
    }
    .img {
      width: 495px !important;
      height: 121px !important;
      margin-bottom: 45px !important;
    }
  }
  .linklist {
    padding: 0 50px !important;
    height: 400px !important;
    .lin_title {
      font-size: 60px !important;
    }
    .inlist {
      .in_item {
        font-size: 50px !important;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .pcbig {
    display: none !important;
  }

  .pcmin {
    display: block !important;
  }
}
</style>