<!--
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-07-20 16:39:03
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-23 09:45:14
 * @FilePath: \my-project\src\components\buttonlin.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="admin">
    <topnar ref="child"></topnar>
    <div class="admin_info">
      <div class="info_title" v-if="userinfo_type">个人中心</div>
      <div class="edit_title" v-if="edit_type">
        <div class="title_left">编辑个人资料</div>
        <div class="title_right" @click="backinfo">
          <img
            class="iconimg"
            src="../../assets/icon/fanh_k_ba@2x.png"
            alt=""
          />
          回到个人中心
        </div>
      </div>
      <div class="edit_cent" v-if="edit_type">
        <div class="edit_list">
          <div class="edit_item">
            <div class="item_text">昵称</div>
            <input
              class="inp"
              placeholder="请填写"
              v-model="userinfo.nick_name"
              type="text"
            />
          </div>
          <div class="edit_item">
            <div class="item_text">机构简称</div>
            <input
              class="inp"
              placeholder="请填写"
              v-model="userinfo.institution"
              type="text"
            />
          </div>
          <div class="edit_item">
            <div class="item_text">职位</div>
            <input
              class="inp"
              placeholder="请填写"
              v-model="userinfo.position"
              type="text"
              id=""
            />
          </div>
        </div>
        <div class="edit_but">
          <div class="but" @click="sumit_edit">保存修改</div>
        </div>
      </div>
      <div class="me_info" v-if="userinfo_type">
        <div class="me_title">
          <div class="me_left">我的资料</div>
          <div class="me_right" @click="ceedit">编辑</div>
          <!-- <div class="me_right">
            <img
              class="iconimg"
              src="../../assets/icon/fanh_k_ba@2x.png"
              alt=""
            />
            回到个人中心
          </div> -->
        </div>
        <div class="me_list">
          <div class="me_item">
            <img
              class="icon_img"
              src="../../assets/icon/ren_dIx_wy@2x.png"
              alt=""
            />
            {{ userinfo.nick_name }}
          </div>
          <div class="xian"></div>
          <div class="me_item">
            <img
              class="opicon"
              src="../../assets/icon/shouj_ha_m@2x.png"
              alt=""
            />
            {{ userinfo.login_phone }}
          </div>
          <div class="xian"></div>
          <div class="me_item">
            <img
              class="addicon"
              src="../../assets/icon/mouji_g_o@2x.png"
              alt=""
            />
            {{ userinfo.institution ? userinfo.institution : "未有机构" }}
          </div>
          <div class="xian"></div>
          <div class="me_item">
            <img
              class="zwicon"
              src="../../assets/icon/zhegess_L@2x.png"
              alt=""
            />
            职位： {{ userinfo.position ? userinfo.position : "未有职位" }}
          </div>
        </div>
      </div>
      <div class="head_list" v-if="userinfo_type">
        <div
          class="head_item"
          :class="headval == 1 ? 'oder' : ''"
          @click="selsehead(1)"
        >
          已报名会议
        </div>
        <div
          class="head_item"
          :class="headval == 2 ? 'oder' : ''"
          @click="selsehead(2)"
        >
          我的订单
        </div>
      </div>
      <block v-if="userinfo_type" style="width: 100%">
        <div class="tar_head" v-if="headval == 2">
          <div
            class="tar_item"
            @click="ceoder(0)"
            :class="oder_type == 0 ? 'tar_active' : ''"
          >
            未开票
          </div>
          <div class="xian"></div>
          <div
            class="tar_item"
            @click="ceoder(1)"
            :class="oder_type == 1 ? 'tar_active' : ''"
          >
            已开票
          </div>
        </div>
        <div class="tar_head" v-if="headval == 1">
          <div
            class="tar_item"
            @click="secemetindex(1)"
            :class="meetingindex == 1 ? 'tar_active' : ''"
          >
            未开始
          </div>
          <div class="xian"></div>
          <div
            class="tar_item"
            @click="secemetindex(2)"
            :class="meetingindex == 2 ? 'tar_active' : ''"
          >
            进行中
          </div>
          <div class="xian"></div>
          <div
            class="tar_item"
            @click="secemetindex(3)"
            :class="meetingindex == 3 ? 'tar_active' : ''"
          >
            已结束
          </div>
        </div>
      </block>
      <block v-if="userinfo_type" style="width: 100%">
        <div class="meeting_list" v-if="headval == 1">
          <div
            class="meeting_item"
            @click="gomelisttype(item)"
            v-for="(item, index) in meetinglistdata"
            :key="index"
          >
            <img class="meitem_left" :src="item.cover" alt="" />
            <div class="left_back">
              参会指南
              <img
                class="righticon"
                src="../../assets/icon/jaint_x@2x.png"
                alt=""
              />
            </div>
            <div class="meitem_right">
              <div class="right_title">{{ item.name }}</div>
              <div class="right_bot">
                <div class="right_tips">
                  <div class="right_address">
                    <img
                      class="iconimg"
                      src="../../assets/icon/weiz_hs_tt@2x.png"
                      alt=""
                    />
                    {{ item.address }}
                  </div>
                  <div class="right_time">
                    <img
                      class="iconimg"
                      src="../../assets/icon/shij_sa_dj@2x.png"
                      alt=""
                    />
                    {{ item.start_time_text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nolsitdata" v-if="moreshow == 3">
            <img
              class="nolsitdata_img"
              src="../../assets/img/nomore.png"
              alt=""
            />
            <div class="nolsitdata_text">暂无内容</div>
          </div>
        </div>
        <div class="num_list" v-if="headval == 2">
          <div
            class="num_item"
            v-for="(item, index) in oderlistdata"
            :key="index"
          >
            <div class="num_li num_title">
              订单编号
              <div class="num_cen">{{ item.orders_sn }}</div>
            </div>
            <div class="num_li">
              订单时间
              <div class="num_cen">{{ item.updated_at }}</div>
            </div>
            <div class="num_li">
              会议名称
              <div class="num_cen">{{ item.meeting_name }}</div>
            </div>
            <div class="num_li">
              订单金额
              <div class="num_cen">￥{{ item.orders_price }}</div>
            </div>
            <div class="widred">
              <div
                class="redbut"
                v-if="oder_type == 0"
                @click="Invoicing(item)"
              >
                去开票
              </div>
            </div>
          </div>
          <div class="nolsitdata" v-if="moreshow == 3">
            <img
              class="nolsitdata_img"
              src="../../assets/img/nomore.png"
              alt=""
            />
            <div class="nolsitdata_text">暂无内容</div>
          </div>
        </div>
      </block>
    </div>
    <div class="meetinguser" v-if="mettinguser_type">
      <div class="meetinguser_title">
        <div class="title_left">会议报名</div>
        <div class="title_right" @click="backinfo">
          <img
            class="iconimg"
            src="../../assets/icon/fanh_k_ba@2x.png"
            alt=""
          />
          回到个人中心
        </div>
      </div>
      <div class="num_list">
        <div
          class="num_item"
          v-for="(item, index) in meetinglistuser"
          @click="godelit(item)"
          :key="index"
          :style="
            item.sign_status == 1
              ? 'background: #FBEFEF; '
              : 'background: #FCFCFC;'
          "
        >
          <div class="num_li num_title">
            <div class="num_cen">{{ item.name }}</div>
          </div>
          <div class="num_li">
            <div class="hui"></div>
            手机号
            <div class="num_cen">{{ item.tel }}</div>
          </div>
          <div class="num_li">
            <div class="hui"></div>
            报名时间
            <div class="num_cen">{{ item.created_at }}</div>
          </div>
          <div class="num_li">
            <div class="hui"></div>
            报名状态
            <div class="num_cen">
              <div class="right_but None" v-if="item.sign_status == -1">
                暂无参会资格
              </div>
              <div class="right_but examine" v-if="item.sign_status == 0">
                正在审核中…
              </div>
              <div class="right_but purchase" v-if="item.sign_status == 1">
                需购票
              </div>
              <div class="right_but Participation" v-if="item.sign_status == 2">
                参会成功
              </div>
            </div>
          </div>
        </div>
        <div class="more_text" v-if="moreshow1 == 2 && mettinguser_type">
          已经到底了
        </div>
        <div
          class="nolsitdata"
          style="margin-top: 40px"
          v-if="moreshow1 == 3 && mettinguser_type"
        >
          <img
            class="nolsitdata_img"
            src="../../assets/img/nomore.png"
            alt=""
          />
          <div class="nolsitdata_text">暂无内容</div>
        </div>
      </div>
    </div>
    <div class="invoice" v-if="invoice_type">
      <div class="invoice_title">
        <div class="title_left">开发票</div>
        <div class="title_right" @click="backinfo">
          <img
            class="iconimg"
            src="../../assets/icon/fanh_k_ba@2x.png"
            alt=""
          />
          回到个人中心
        </div>
      </div>
      <div class="invoice_form" v-if="classification_type && !success_type">
        <div class="form_head">
          <div class="form_item foitem_active" @click="foemhead(1)">企业</div>
          <div class="form_item" @click="foemhead(2)">个人</div>
        </div>
        <div class="form_list">
          <div class="from_one">
            <div class="form_item">
              <div class="form_text">
                抬头
                <p class="red">*</p>
              </div>
              <input
                class="inp"
                v-model="invoicedata.title"
                type="text"
                placeholder="请填写"
              />
            </div>
            <div class="form_item">
              <div class="form_text">
                税号
                <p class="red">*</p>
              </div>
              <input
                class="inp"
                v-model="invoicedata.tax_number"
                type="text"
                placeholder="请填写"
              />
            </div>
          </div>
          <div class="from_one">
            <div class="form_item">
              <div class="form_text">地址</div>
              <input
                class="inp"
                type="text"
                v-model="invoicedata.address"
                placeholder="请填写"
              />
            </div>
            <div class="form_item">
              <div class="form_text">电话</div>
              <input
                class="inp"
                type="text"
                v-model="invoicedata.tel"
                placeholder="请填写"
              />
            </div>
            <div class="form_item">
              <div class="form_text">开户行</div>
              <input
                class="inp"
                type="text"
                v-model="invoicedata.bank_name"
                placeholder="请填写"
              />
            </div>
          </div>
          <div class="from_one">
            <div class="form_item">
              <div class="form_text">开户账号</div>
              <input
                class="inp"
                type="text"
                v-model="invoicedata.bank_number"
                placeholder="请填写"
              />
            </div>
            <div class="form_item">
              <div class="form_text">发票金额</div>
              {{ invoicedata.amount }}元
            </div>
          </div>
          <div class="xian"></div>
          <div class="from_one">
            <div class="form_item">
              <div class="form_text">
                邮寄人
                <p class="red">*</p>
              </div>
              <input
                class="inp"
                v-model="invoicedata.receiver_name"
                type="text"
                placeholder="请填写"
              />
            </div>
            <div class="form_item">
              <div class="form_text">
                邮寄电话
                <p class="red">*</p>
              </div>
              <input
                class="inp"
                v-model="invoicedata.receiver_tel"
                type="text"
                placeholder="请填写"
              />
            </div>
          </div>
          <div class="from_one">
            <div class="form_item">
              <div class="form_text">
                邮寄地址
                <p class="red">*</p>
              </div>
              <input
                class="inp"
                v-model="invoicedata.receiver_address"
                type="text"
                placeholder="请填写"
              />
            </div>
          </div>
          <div class="invoicing_but" @click="cesucc">
            <div class="but">开票</div>
          </div>
        </div>
      </div>
      <div
        class="invoice_form person"
        v-if="!classification_type && !success_type"
      >
        <div class="form_head">
          <div class="form_item" @click="foemhead(1)">企业</div>
          <div class="form_item foitem_active" @click="foemhead(2)">个人</div>
        </div>
        <div class="form_list">
          <div class="from_one">
            <div class="form_item">
              <div class="form_text">
                名称
                <p class="red">*</p>
              </div>
              <input
                class="inp"
                v-model="invoicedata.title"
                type="text"
                placeholder="请填写"
              />
            </div>
            <div class="form_item">
              <div class="form_text">发票金额</div>
              {{ invoicedata.amount }}元
            </div>
          </div>
          <div class="xian"></div>
          <div class="from_one">
            <div class="form_item">
              <div class="form_text">
                邮寄人
                <p class="red">*</p>
              </div>
              <input
                class="inp"
                v-model="invoicedata.receiver_name"
                type="text"
                placeholder="请填写"
              />
            </div>
            <div class="form_item">
              <div class="form_text">
                邮寄电话
                <p class="red">*</p>
              </div>
              <input
                class="inp"
                v-model="invoicedata.receiver_tel"
                type="text"
                placeholder="请填写"
              />
            </div>
          </div>
          <div class="from_one">
            <div class="form_item">
              <div class="form_text">
                邮寄地址
                <p class="red">*</p>
              </div>
              <input
                class="inp"
                v-model="invoicedata.receiver_address"
                type="text"
                placeholder="请填写"
              />
            </div>
          </div>
          <div class="invoicing_but" @click="cesucc">
            <div class="but">开票</div>
          </div>
        </div>
      </div>
      <div class="yeinvoice" v-if="success_type">
        <img class="yeicon" src="../../assets/img/zhifu_che_wa@2x.png" alt="" />
        <div class="yetext">开票成功</div>
      </div>
    </div>
    <div class="more_text" v-if="moreshow == 2 && userinfo_type">
      已经到底了
    </div>
    <el-pagination
      class="morye"
      background
      v-if="userinfo_type && headval == 1"
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :total="total_articles"
      :page-size="10"
    >
    </el-pagination>
    <el-pagination
      class="morye"
      background
      v-if="userinfo_type && headval == 2"
      layout="prev, pager, next"
      @current-change="handleCurrentChange1"
      :current-page.sync="current_page1"
      :total="total_articles1"
      :page-size="10"
    >
    </el-pagination>
    <el-pagination
      class="morye"
      background
      v-if="mettinguser_type"
      layout="prev, pager, next"
      @current-change="handleCurrentChange2"
      :current-page.sync="current_page2"
      :total="total_articles2"
      :page-size="10"
    >
    </el-pagination>
    <div class="more" v-if="userinfo_type && moreshow == 1">
      <div class="more_cen" @click="updatalist()">
        <div class="cen_text">加载更多…</div>
        <div class="cenicon">
          <img class="img" src="../../assets/icon/xiah_djt1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="more" v-if="mettinguser_type && moreshow1 == 1">
      <div class="more_cen" @click="userlist()">
        <div class="cen_text">加载更多…</div>
        <div class="cenicon">
          <img class="img" src="../../assets/icon/xiah_djt1.png" alt="" />
        </div>
      </div>
    </div>
    <upward></upward>
    <buton></buton>
  </div>
</template>
  
  <script>
import { usersinfo, usersupdate } from "../../api/logoin.js";
import { meetingmy, sign_upgetList } from "../../api/meeting.js";
import { ordersmy } from "../../api/orders.js";
import { invoicesubmit } from "../../api/invoice.js";
export default {
  data() {
    return {
      total_articles: 0,
      total_articles1: 0,
      total_articles2: 0,
      current_page: 1,
      current_page1: 1,
      current_page2: 1,
      headval: 1, //列表状态
      edit_type: false, //编辑状态
      classification_type: true, //企业个人状态
      success_type: false, //发票成功状态
      invoice_type: false, //开发票状态
      userinfo_type: true, //个人资料状态
      mettinguser_type: false, //用户报名列表
      oder_type: 0, //订单未开始状态
      userinfo: [],
      meetingindex: 1,
      page: 1,
      page1: 1,
      page2: 1,
      invoicedata: {
        title_type: 1,
        title: "",
        tax_number: "",
        address: "",
        tel: "",
        bank_name: "",
        bank_number: "",
        amount: "",
        receiver_name: "",
        receiver_address: "",
        receiver_tel: "",
        orders_id: "",
      },
      oderlistdata: [],
      editdata: {
        nick_name: "",
        position: "",
        institution: "",
      },
      meetinglistdata: [],
      meetinglistuser: [],
      applicationid: "",
      moreshow: 1,
      moreshow1: 1,
    };
  },
  watch: {
    // 监听路由变化，随时获取新的列表信息
    $route: "fetchData",
    $route: "fetchData1",
    $route: "fetchData2",
  },
  methods: {
    selsehead(val) {
      this.page = 1;
      this.headval = val;
      // this.updatalist();
      if (val == 1) {
        this.meetingmylist();
      } else {
        this.oderlist();
      }
    },
    fetchData() {
      console.log(this.$route.query, "0-0-------------------------");
      this.page = +this.$route.query.page || 1;
      this.pcmeetingmylist();
      // this.getpclist(this.page);
    },
    fetchData1() {
      console.log(this.$route.query, "0-0-------------------------");
      this.page1 = +this.$route.query.page || 1;
      // this.getpclist(this.page);
      this.pcoderlist();
    },
    fetchData2() {
      console.log(this.$route.query, "0-0-------------------------");
      this.page2 = +this.$route.query.page || 1;
      this.pcsign_metons();
      // this.getpclist(this.page);
    },
    godelit(item) {
      console.log(item.tel);
      this.$router.push({
        path: "summitdeilt",
        query: {
          id: item.meeting_id,
          name: item.name,
          oderid: item.id,
        },
      });
    },
    gomelisttype(item) {
      console.log(item, "-----------");
      this.sign_metons(item);
    },
    sign_metons(item) {
      if (item == undefined) {
        console.log("eee");
      } else {
        console.log("7777");
        this.applicationid = item.meeting_id;
      }
      sign_upgetList({
        meeting_id: this.applicationid,
        page: this.page2,
        limit: 10,
      }).then((res) => {
        console.log(res);
        this.meetinglistuser = res.data.data.data;
        if (this.page2 == 1) {
          this.meetinglistuser = res.data.data.data;
          this.moreshow1 = 1;
        } else {
          this.meetinglistuser = this.meetinglistuser.concat(
            res.data.data.data
          );
          this.moreshow1 = 1;
        }
        if (res.data.data.current_page >= res.data.data.last_page) {
          this.moreshow1 = 2;
        }
        if (this.meetinglistuser.length == 0) {
          this.moreshow1 = 3;
        }
        this.total_articles2 = res.data.data.total;
      });
      this.userinfo_type = false;
      this.mettinguser_type = true;
    },
    pcsign_metons() {
      if (item == undefined) {
        console.log("eee");
      } else {
        console.log("7777");
        this.applicationid = item.meeting_id;
      }
      sign_upgetList({
        meeting_id: this.applicationid,
        page: this.page2,
        limit: 10,
      }).then((res) => {
        console.log(res);

        this.meetinglistuser = res.data.data.data;

        this.total_articles2 = res.data.data.total;
      });
      this.userinfo_type = false;
      this.mettinguser_type = true;
    },
    secemetindex(val) {
      this.page = 1;
      this.meetingindex = val;
      this.meetingmylist();
    },
    foemhead(val) {
      this.invoicedata.title_type = val;
      if (val == 1) {
        this.classification_type = true;
      } else {
        this.classification_type = false;
      }
    },
    cesucc() {
      if (!this.invoicedata.title_type == 1) {
        if (!this.invoicedata.title) {
          this.$message({
            message: "请填写抬头",
            iconClass: "true",
            center: "true",
          });
          return;
        } else if (!this.invoicedata.tax_number) {
          this.$message({
            message: "请填写税号",
            iconClass: "true",
            center: "true",
          });
          return;
        }
      } else {
        if (!this.invoicedata.title) {
          this.$message({
            message: "请填写抬头",
            iconClass: "true",
            center: "true",
          });
          return;
        }
      }
      if (!this.invoicedata.receiver_name) {
        this.$message({
          message: "请填写接收人姓名",
          iconClass: "true",
          center: "true",
        });
        return;
      } else if (!this.invoicedata.receiver_address) {
        this.$message({
          message: "请填写接收人地址",
          iconClass: "true",
          center: "true",
        });
        return;
      } else if (!this.invoicedata.receiver_tel) {
        this.$message({
          message: "请填写接收人电话",
          iconClass: "true",
          center: "true",
        });
        return;
      }
      if (this.invoicedata.bank_number) {
        const reg = /^([1-9]{1})(\d{15}|\d{18})$/;
        if (reg.test(this.invoicedata.bank_number)) {
        } else {
          this.$message({
            message: "请填写正确的银行卡号",
            iconClass: "true",
            center: "true",
          });
          return;
        }
      }
      //开票---------------------------
      invoicesubmit(this.invoicedata).then((res) => {
        console.log(res);
      });
      this.success_type = true;
    },
    sumit_edit() {
      usersupdate(this.userinfo).then((res) => {
        console.log(res);
        this.userinfoun();
        this.backinfo();
        this.$refs.child.userinfoun();
      });
    },
    oderlist() {
      ordersmy({
        is_apply_invoice: this.oder_type,
        page: this.page1,
        limit: 10,
      }).then((res) => {
        // this.oderlistdata = res.data.data.data;
        if (this.page1 == 1) {
          this.oderlistdata = res.data.data.data;
          this.moreshow = 1;
        } else {
          this.oderlistdata = this.oderlistdata.concat(res.data.data.data);
          this.moreshow = 1;
        }
        if (res.data.data.current_page >= res.data.data.last_page) {
          this.moreshow = 2;
        }
        if (this.oderlistdata.length == 0) {
          this.moreshow = 3;
        }
        this.total_articles1 = res.data.data.total;
      });
    },
    pcoderlist() {
      ordersmy({
        is_apply_invoice: this.oder_type,
        page: this.page1,
        limit: 10,
      }).then((res) => {
        this.oderlistdata = res.data.data.data;

        this.total_articles1 = res.data.data.total;
      });
    },
    // handleCurrentChange2(e) {
    //   this.page2 = e;
    //   this.pcsign_metons();
    // },
    // handleCurrentChange1(e) {
    //   this.page1 = e;
    //   this.pcoderlist();
    // },
    // handleCurrentChange(e) {
    //   this.page = e;
    //   this.pcmeetingmylist();
    // },
    updatalist() {
      if (this.headval == 1) {
        this.page++;
        this.meetingmylist();
      } else if (this.headval == 2) {
        this.page1++;
        this.oderlist();
      }
    },
    userlist() {
      this.page2++;
      this.gomelisttype();
    },
    meetingmylist() {
      meetingmy({
        status: this.meetingindex,
        page: this.page,
        limit: 10,
      }).then((res) => {
        console.log(res.data.data, "报名的会议");
        if (this.page == 1) {
          this.meetinglistdata = res.data.data.data;
          this.moreshow = 1;
        } else {
          this.meetinglistdata = this.meetinglistdata.concat(
            res.data.data.data
          );
          this.moreshow = 1;
        }
        if (res.data.data.current_page >= res.data.data.last_page) {
          this.moreshow = 2;
        }
        if (this.meetinglistdata.length == 0) {
          this.moreshow = 3;
        }
        this.total_articles = res.data.data.last_page;
      });
    },
    pcmeetingmylist() {
      meetingmy({
        status: this.meetingindex,
        page: this.page,
        limit: 10,
      }).then((res) => {
        console.log(res.data.data, "报名的会议");

        this.meetinglistdata = res.data.data.data;

        this.total_articles = res.data.data.last_page;
      });
    },
    userinfoun() {
      usersinfo({})
        .then((res) => {
          console.log(res.data.data);
          this.userinfo = res.data.data;
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
    },
    ceedit() {
      this.edit_type = true;
      this.userinfo_type = false;
    },
    backinfo() {
      this.success_type = false;
      this.invoice_type = false;
      this.edit_type = false;
      this.userinfo_type = true;
      this.mettinguser_type = false;
    },
    Invoicing(item) {
      console.log(item);
      this.invoicedata.orders_id = item.id;
      this.invoicedata.amount = item.orders_price;
      this.invoice_type = true;
      this.userinfo_type = false;
    },
    ceoder(val) {
      this.page1 = 1;
      this.oder_type = val;
      this.oderlist();
    },
  },
  created() {
    this.$wxShare.updateWxShareConfig({
      appMessage: true,
    });
    this.userinfoun();
    this.meetingmylist();
    // this.oderlist();
  },
};
</script>
  
  <style scoped lang="scss">
.admin {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.morye {
  margin-bottom: 20px;
}
.more {
  display: none;
}
.more_text {
  display: none;
}
.nolsitdata {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  .nolsitdata_img {
    width: 158px;
    height: 92px;
  }
  .nolsitdata_text {
    font-size: 14px;
    margin-top: 23px;
    color: #999999;
  }
}
input:focus {
  outline: none;
  border: 1px solid #c32227 !important;
}
/deep/.el-pager {
  .active {
    background: #c32227 !important;
  }
  .number:hover {
    color: #c32227 !important;
  }
}
.pcbig {
  display: block;
}
.pcmin {
  display: none;
}
.invoice {
  padding: 0 120px;
  box-sizing: border-box;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 40px;
  .invoice_title {
    width: 100%;
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_right {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      cursor: pointer;

      .iconimg {
        width: 16px;
        height: 12px;
      }
    }
  }
  .yeinvoice {
    width: 1200px;
    height: 445px;
    background: #f9f9f9;
    border-radius: 0px 0px 4px 4px;
    margin-top: 21px;
    padding: 22px 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .yeicon {
      width: 51px;
      height: 51px;
    }
    .yetext {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-top: 24px;
    }
  }
  .person {
    height: 367px !important;
  }
  .invoice_form {
    width: 1200px;
    height: 475px;
    background: #f9f9f9;
    border-radius: 0px 0px 4px 4px;
    margin-top: 21px;
    padding: 22px 30px;
    box-sizing: border-box;

    .form_list {
      .from_tow {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        .form_item {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          margin-right: 50px;
          .inp {
            width: 240px;
            height: 32px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #dadada;
            padding: 0 10px;
            margin-left: 50px;
            font-size: 12px;
            box-sizing: border-box;
          }
          .red {
            color: #c32227;
          }
        }
      }
      .xian {
        width: 1152px;
        height: 1px;
        background: #dddddd;
        margin: 30px 0 8px;
      }
      .invoicing_but {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        .but {
          cursor: pointer;
          width: 200px;
          height: 32px;
          background: #c32227;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
        }
      }
      .from_one {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // margin-top: 20px;
        .form_item {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          margin-right: 50px;
          margin-top: 20px;
          .form_text {
            width: 78px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
          .inp {
            width: 240px;
            height: 32px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #dadada;
            padding: 0 10px;
            font-size: 12px;
            box-sizing: border-box;
          }
          .red {
            color: #c32227;
            margin: 0px;
          }
        }
      }
    }
    .form_head {
      display: flex;
      align-items: center;
      .form_item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 32px;
        border-radius: 4px;
        background: #e9e9e9;
        margin-right: 20px;
        font-size: 16px;
        cursor: pointer;
      }
      .foitem_active {
        background: #c32227;
        color: #ffffff;
      }
    }
  }
}
.meetinguser {
  padding: 0 120px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 40px;
  .num_list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 22px;
    .num_item:nth-child(3n) {
      margin-right: 0px;
    }
    .num_item {
      width: 386px;
      height: 160px;
      // background: #f9f9f9;
      border-radius: 4px;
      padding: 0 15px;
      box-sizing: border-box;
      margin-right: 18px;
      margin-bottom: 20px;
      cursor: pointer;
      .hui {
        width: 8px;
        height: 8px;
        border-radius: 50px;
        background: #999999;
        margin-right: 9px;
      }
      .widred {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
        font-size: 14px;

        .redbut {
          padding: 2px 20px;
          background: #c32227;
          border-radius: 4px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }

      .num_title {
        height: 51px;
        font-size: 14px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold !important;
        color: #333333 !important;
        margin-bottom: 14px;
        border-bottom: 1px solid #e9e9e9;
      }
      .num_li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 7px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        .num_cen {
          margin-left: 21px;
          flex: 1;
          text-align: left;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #333333;
          .None {
            color: #333333;
          }
          .examine {
            color: #333333;
          }
          .purchase {
            color: #c32227;
          }
          .Participation {
            color: #2254c3;
          }
        }
      }
    }
  }
  .meetinguser_title {
    width: 100%;
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_right {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      cursor: pointer;

      .iconimg {
        width: 16px;
        height: 12px;
      }
    }
  }
}
.admin_info {
  padding: 0 120px;
  box-sizing: border-box;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 40px;
  font-size: 20px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #c32227;
  .edit_cent {
    width: 1200px;
    height: 272px;
    background: #f9f9f9;
    border-radius: 0px 0px 4px 4px;
    margin-top: 21px;
    .edit_list {
      padding: 22px 30px;
      box-sizing: border-box;
      .edit_item {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        .item_text {
          width: 56px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          margin-right: 22px;
          text-align: left;
        }
        .inp {
          width: 200px;
          height: 32px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #dadada;
          padding: 0 10px;
          box-sizing: border-box;
        }
      }
    }
    .edit_but {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .but {
        width: 200px;
        height: 32px;
        background: #c32227;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .edit_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_left {
      font-size: 20px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
    }
    .title_right {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      cursor: pointer;

      .iconimg {
        width: 16px;
        height: 12px;
      }
    }
  }

  .num_list {
    display: flex;
    flex-wrap: wrap;
    .num_item:nth-child(4n) {
      margin-right: 0px;
    }
    .num_item {
      width: 285px;
      height: 144px;
      background: #f9f9f9;
      border-radius: 4px;
      padding: 0 15px;
      box-sizing: border-box;
      margin-right: 18px;
      margin-top: 22px;
      .widred {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
        font-size: 14px;

        .redbut {
          padding: 2px 20px;
          background: #c32227;
          border-radius: 4px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }

      .num_title {
        font-size: 14px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold !important;
        color: #333333;
        margin-bottom: 14px;
      }
      .num_li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 7px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        .num_cen {
          margin-left: 21px;
          flex: 1;
          text-align: left;
        }
      }
    }
  }
  .meeting_list {
    display: flex;
    width: 1200px;
    margin-top: 22px;
    flex-wrap: wrap;
    .meeting_item:nth-child(2n) {
      margin-right: 0px;
    }
    .meeting_item {
      display: flex;
      align-items: center;
      width: 580px;
      margin-right: 38px;
      margin-bottom: 25px;
      position: relative;
      cursor: pointer;

      .meitem_left {
        width: 200px;
        height: 113px;
      }
      .left_back {
        width: 76px;
        height: 24px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 2px 0px 2px 0px;
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        .righticon {
          width: 6px;
          height: 10px;
          margin-left: 5px;
        }
      }
      .meitem_right {
        width: 326px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 15px;

        .right_title {
          font-size: 16px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
        }
        .right_bot {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .right_tips {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            .right_address {
              margin-right: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              .iconimg {
                width: 12px;
                height: 14px;
                margin-right: 8px;
              }
            }
            .right_time {
              .iconimg {
                width: 14px;
                height: 14px;
                margin-right: 8px;
              }
            }
          }
          .None {
            color: #333333;
            border: 1px solid #d2d2d2;
          }
          .examine {
            color: #333333;
          }
          .purchase {
            background: #c32227;
            color: #ffffff;
          }
          .Participation {
            color: #c4262b;
            border: 1px solid #c32227;
          }
          .right_but {
            width: 118px;
            height: 32px;
            border-radius: 4px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .tar_head {
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #999999;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 26px;
    .tar_item {
      cursor: pointer;
    }
    .tar_active {
      color: #c32227;
    }
    .xian {
      width: 1px;
      height: 20px;
      background: #dadada;
      margin: 0 22px;
    }
  }
  .head_list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
    .head_item {
      width: 120px;
      height: 32px;
      background: #f5f6f9;
      border-radius: 4px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
    }
    .oder {
      background: #c32227;
      color: #ffffff;
    }
  }
  .me_info {
    width: 1200px;
    height: 119px;
    background: #f9f9f9;
    border-radius: 4px;
    margin-top: 22px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 24px;
    box-sizing: border-box;

    .me_list {
      width: 100%;
      height: 66px;
      display: flex;
      align-items: center;
      .xian {
        width: 1px;
        height: 20px;
        background: #dadada;
        margin: 0 22px;
      }
      .me_item {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon_img {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
        .opicon {
          width: 12px;
          height: 16px;
          margin-right: 6px;
        }
        .addicon {
          width: 18px;
          height: 16px;
          margin-right: 6px;
        }
        .zwicon {
          width: 7px;
          height: 16px;
          margin-right: 6px;
        }
      }
    }
    .me_title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 52px;
      border-bottom: 1px solid #dddddd;
      .me_left {
        font-size: 16px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #333333;
      }
      .me_right {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
        .iconimg {
          width: 16px;
          height: 12px;
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .pcbig {
    display: none !important;
  }

  .pcmin {
    display: block !important;
  }
  .morye {
    display: none;
  }
  .meetinguser {
    padding: 0 120px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 40px;
    .num_list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 22px;
      width: 100%;
      .num_item:nth-child(3n) {
        margin-right: 0px;
      }
      .num_item {
        width: 100% !important;
        height: 400px !important;
        // background: #f9f9f9;
        border-radius: 4px;
        padding: 0 30px !important;
        box-sizing: border-box;
        margin-right: 18px;
        margin-bottom: 40px !important;
        cursor: pointer;
        .hui {
          width: 30px;
          height: 30px;
          border-radius: 50px;
          background: #999999;
          margin-right: 20px !important;
        }
        .widred {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: 5px;
          font-size: 50px !important;

          .redbut {
            padding: 2px 20px;
            background: #c32227;
            border-radius: 4px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }

        .num_title {
          height: 100px !important;
          font-size: 50px !important;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold !important;
          color: #333333 !important;
          margin-bottom: 25px !important;
          border-bottom: 3px solid #e9e9e9 !important;
        }
        .num_li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 15px !important;
          font-size: 50px !important;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #999999;
          .num_cen {
            margin-left: 21px;
            flex: 1;
            text-align: left;
            font-size: 50px !important;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
            .None {
              color: #333333;
            }
            .examine {
              color: #333333;
            }
            .purchase {
              color: #c32227;
            }
            .Participation {
              color: #2254c3;
            }
          }
        }
      }
    }
    .meetinguser_title {
      .title_left {
        font-size: 60px !important;
      }
      .title_right {
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
        font-size: 40px !important;
        .iconimg {
          width: 64px !important;
          height: 48px !important;
        }
      }
    }
  }
  .more_text {
    width: 100%;
    display: block !important;
    font-size: 50px !important;
  }
  .nolsitdata {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .nolsitdata_img {
      width: 458px !important;
      height: 272px !important;
    }
    .nolsitdata_text {
      font-size: 50px !important;
      margin-top: 23px;
      color: #999999;
    }
  }
  .more {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 35px 0;
    display: flex !important;
    margin: 40px 0;
    .more_cen {
      .cen_text {
        font-size: 50px !important;
      }
      .cenicon {
        .img {
          width: 59px !important;
          height: 40px !important;
        }
      }
    }
  }
  .invoice {
    padding: 0 30px !important;
    margin-top: 40px;
    .invoice_title {
      font-size: 80px !important;
      .title_right {
        font-size: 48px !important;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        cursor: pointer;

        .iconimg {
          width: 64px;
          height: 48px;
        }
      }
    }
    .yeinvoice {
      border-radius: 0px 0px 10px 10px !important;
      padding: 22px 30px;
      width: 100% !important;
      height: 900px !important;
      .yeicon {
        width: 180px !important;
        height: 180px !important;
      }
      .yetext {
        font-size: 60px !important;
        margin-top: 50px !important;
      }
    }
    .person {
      height: 347px !important;
    }
    .invoice_form {
      width: 100% !important;
      border-radius: 0px 0px 10px 10px !important;
      margin-top: 21px;
      padding: 40px 30px;
      height: auto !important;
      .form_list {
        .xian {
          display: none !important;
        }
        .invoicing_but {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 60px;
          .but {
            width: 600px !important;
            height: 120px !important;
            background: #c32227;
            border-radius: 15px;
            font-size: 50px !important;
          }
        }
        .from_one {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          margin-top: 20px;
          .form_item {
            width: 100%;
            padding: 0 30px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start !important;
            align-items: center;
            font-size: 50px !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #333333;
            margin-right: 50px;
            margin-top: 40px;
            .form_text {
              width: 300px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            .inp {
              flex: 1;
              height: 150px !important;
              background: #ffffff;
              border-radius: 10px !important;
              border: 1px solid #dadada;
              padding: 0 20px;
              font-size: 39px;
              box-sizing: border-box;
              font-size: 50px !important;
              padding: 0 40px !important;
            }

            .red {
              color: #c32227;
              margin: 0px;
            }
          }
        }
      }
      .form_head {
        display: flex;
        align-items: center;
        .foitem_active {
          background: #c32227 !important;
          color: #ffffff;
        }
        .form_item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 400px !important;
          height: 120px !important;
          border-radius: 4px;
          background: #e9e9e9;
          margin-right: 20px;
          font-size: 50px !important;
        }
      }
    }
  }
  .admin_info {
    padding: 0 30px;
    .info_title {
      font-size: 68px;
    }
    .head_list {
      width: 100%;
      justify-content: center !important;
      margin-top: 60px !important;
      .head_item {
        width: 600px;
        height: 120px;
        border-radius: 20px;
        font-size: 50px;
        cursor: pointer;
      }
      .oder {
        background: #c32227;
        color: #ffffff;
      }
    }
    .edit_cent {
      width: 100% !important;
      height: 900px !important;
      background: #f9f9f9;
      border-radius: 0px 0px 4px 4px;
      margin-top: 21px;
      .edit_list {
        padding: 60px 30px;
        box-sizing: border-box;
        .edit_item {
          display: flex;
          align-items: center;
          margin-bottom: 40px !important;
          .item_text {
            width: 200px !important;
            font-size: 50px !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #333333;
            margin-right: 22px;
            text-align: left;
          }
          .inp {
            flex: 1;
            height: 150px !important;
            background: #ffffff;
            border-radius: 10px !important;
            padding: 0 20px !important;
          }
        }
      }
      .edit_but {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .but {
          width: 600px;
          height: 110px;
          background: #c32227;
          border-radius: 4px;
          font-size: 50px !important;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .edit_title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 42px;
      .title_left {
        font-size: 70px !important;
      }
      .title_right {
        font-size: 48px !important;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        .iconimg {
          width: 64px !important;
          height: 48px !important;
        }
      }
    }

    .num_list {
      display: flex;
      margin-top: 40px;
      width: 100%;
      flex-wrap: wrap;
      .num_item {
        width: 100% !important;
        height: 550px;
        background: #f9f9f9;
        border-radius: 30px !important;
        padding: 40px 60px 0px;
        box-sizing: border-box;
        margin-top: 40px;
        .widred {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: 5px;
          font-size: 50px;

          .redbut {
            padding: 5px 20px;
            background: #c32227;
            border-radius: 4px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .num_title {
          font-size: 50px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold !important;
          color: #333333;
          margin-bottom: 30px;
        }
        .num_li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 25px;
          font-size: 50px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          .num_cen {
            margin-left: 21px;
            flex: 1;
            text-align: left;
          }
        }
      }
    }
    .tar_head {
      width: 100% !important;
      font-size: 60px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 80px !important;
      .tar_active {
        color: #c32227;
      }
      .xian {
        width: 3px;
        height: 80px;
        background: #dadada;
        margin: 0 60px;
      }
    }
    .meeting_list {
      width: 100% !important;
      margin-top: 65px !important;
      flex-wrap: wrap;
      .meeting_item:nth-child(2n) {
        margin-right: 40px;
      }
      .meeting_item {
        display: flex;
        align-items: center;
        width: 100%;
        margin-right: 40px;
        margin-bottom: 25px;
        .meitem_left {
          width: 500px !important;
          height: 250px !important;
        }
        .left_back {
          width: 200px !important;
          height: 100px !important;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 2px 0px 2px 0px;
          position: absolute;
          top: 0px;
          left: 0px;
          font-size: 36px !important;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-right-radius: 30px !important;
          .righticon {
            width: 18px !important;
            height: 30px !important;
            margin-left: 15px !important;
          }
        }
        .meitem_right {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-direction: column;
          flex: 1;
          margin-left: 40px !important;

          .right_title {
            font-size: 45px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #333333;
          }
          .right_bot {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .right_tips {
              display: flex;
              justify-content: center;
              align-items: flex-start !important;
              font-size: 38px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #999999;
              flex-direction: column;

              .right_address {
                margin-right: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                .iconimg {
                  width: 24px;
                  height: 28px;
                  margin-right: 8px;
                }
              }
              .right_time {
                .iconimg {
                  width: 28px;
                  height: 28px;
                  margin-right: 8px;
                }
              }
            }
            .right_but {
              width: 300px;
              height: 90px;
              border-radius: 10px;
              font-size: 38px !important;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .me_info {
      width: 100%;
      height: auto !important;
      margin-top: 30px !important;
      .me_title {
        height: 150px !important;
        .me_left {
          font-size: 60px !important;
        }
        .me_right {
          font-size: 48px;
          .iconimg {
            width: 64px;
            height: 48px;
          }
        }
      }
      .me_list {
        flex-direction: column !important;
        align-items: flex-start !important;
        height: auto !important;
        padding-bottom: 50px;
        .me_item {
          font-size: 48px !important;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 50px;

          .icon_img {
            width: 60px;
            height: 60px;
            margin-right: 20px;
          }
          .opicon {
            width: 48px;
            height: 60px;
            margin-right: 20px;
          }
          .addicon {
            width: 60px;
            height: 60px;
            margin-right: 20px;
          }
          .zwicon {
            width: 30px;
            height: 60px;
            margin-right: 30px;
            margin-left: 15px;
          }
        }
        .xian {
          display: none !important;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .pcbig {
    display: none !important;
  }

  .pcmin {
    display: block !important;
  }
}
</style>